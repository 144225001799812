import { ButtonProps } from '@chakra-ui/react';
import { AppButtonType } from './app-button-types';
import { AppButtonColorScheme } from './app-button-color-scheme';

export const appButtonBaseStyles: ButtonProps = {
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '18px',
  whiteSpace: 'normal',
};

export const appButtonTypeStyles = (
  colorMode: 'light' | 'dark',
  isWidePadding?: boolean,
): Record<AppButtonType, ButtonProps> => ({
  [AppButtonType.MAIN]: {
    bg: '_brand.500',
    borderRadius: '22px',
    padding: isWidePadding ? '12px 64px' : '12px 32px',
    color: 'white',
    _hover: {
      bg: 'brand.400',
    },
    _active: {
      bg: 'brand.400',
    },
    _disabled: {
      bg: colorMode === 'dark' ? 'brand.200' : 'gray.400',
      opacity: '0.5',
    },
    gap: '16px',
    height: '42px',
  },
  [AppButtonType.PRIMARY_MAIN]: {
    bg: colorMode === 'dark' ? 'brand.200' : '_brand.500',
    borderRadius: '100px',
    padding: isWidePadding ? '10px 150px' : '10px 75px',
    color: 'white',
    _hover: {
      bg: 'brand.400',
    },
    _active: {
      bg: 'brand.400',
    },
    _disabled: {
      bg: colorMode === 'dark' ? 'brand.900' : 'gray.400',
      opacity: '0.5',
    },
    gap: '16px',
    fontSize: 'sm !important',
    height: '46px',
  },
  [AppButtonType.PRIMARY]: {
    bg: 'blue.500',
    borderRadius: '10px',
    padding: isWidePadding ? '11px 32px' : '11px 16px',
    color: 'white',
    _hover: {
      bg: 'brand.400',
    },
    _active: {
      bg: 'brand.400',
    },
    _disabled: {
      bg: 'rgba(203, 213, 224, 0.16)',
      color: 'gray.400',
    },
    gap: '4px',
    height: '40px',
  },
  [AppButtonType.SECONDARY]: {
    bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'white',
    borderRadius: '10px',
    padding: isWidePadding ? '11px 32px' : '11px 16px',
    color: 'brand.800',
    _hover: {
      bg: colorMode === 'dark' ? 'whiteAlpha.400' : 'white',
      color: '_brand.500',
    },
    _active: {
      bg: colorMode === 'dark' ? 'whiteAlpha.400' : 'white',
      color: 'blue.500',
    },
    _disabled: {
      bg: colorMode === 'dark' ? 'whiteAlpha.100' : 'brand.100',
      borderColor: 'secondaryGray.300',
      color: 'white',
    },
    gap: '4px',
    height: '40px',
  },
  [AppButtonType.SMALL]: {
    bg: 'blue.500',
    borderRadius: '8px',
    padding: isWidePadding ? '6px 32px 6px 16px' : '6px 16px 6px 8px',
    fontSize: '10px !important',
    lineHeight: '16px !important',
    color: 'white',
    _hover: {
      bg: 'brand.400',
    },
    _active: {
      bg: 'brand.400',
    },
    _disabled: {
      bg: 'gray.400',
    },
    gap: '8px',
    height: '28px',
  },
  [AppButtonType.XSMALL]: {
    bg: 'gray.360',
    borderRadius: '16px',
    padding: isWidePadding ? '4px 32px 4px 16px' : '4px 12px',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
    color: 'gray.700',
    border: '1px solid transparent',
    _hover: {
      bg: 'gray.360',
      borderColor: 'gray.700',
    },
    _active: {
      bg: 'gray.360',
      borderColor: 'gray.700',
    },
    _disabled: {
      bg: 'rgba(203, 213, 224, 0.16)',
      color: 'gray.400',
    },
    height: 'auto',
  },
  [AppButtonType.ICON]: {
    bg: 'transparent',
    padding: '0',
    size: 'sm',
    borderRadius: '50%',
    color: colorMode === 'dark' ? 'blue.500' : 'brand.800',
    border: '1px solid transparent',
    _hover: {
      bg: 'transparent',
      color: '_brand.500',
    },
    _active: {
      bg: 'transparent',
      color: 'blue.500',
    },
    _disabled: {
      bg: 'transparent',
      color: 'gray.400',
    },
  },
});

export const appButtonDisabledStyles: ButtonProps = {
  cursor: 'not-allowed',
  _hover: {},
  _active: {},
};

export const appButtonColorSchemeStyles = (colorMode: 'light' | 'dark'): Record<AppButtonColorScheme, ButtonProps> => ({
  [AppButtonColorScheme.DEFAULT]: {},
  [AppButtonColorScheme.DANGER]: {
    bg: colorMode === 'dark' ? 'navy.700' : 'secondaryGray.300',
    borderColor: 'red.400',
    color: 'red',
    _hover: {
      border: '1px solid',
      borderColor: 'red.400',
      bg: 'red',
      color: 'white',
    },
    _active: {
      border: '1px solid',
      borderColor: 'red.400',
      bg: 'red',
      color: 'white',
    },
  },
  [AppButtonColorScheme.TRANSPARENT]: {
    bg: 'transparent',
    variant: 'outline',
    color: colorMode === 'dark' ? 'whiteAlpha.900' : 'navy.750',
    _hover: {
      bg: colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.360',
    },
    _active: {
      bg: colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.360',
    },
  },
  [AppButtonColorScheme.WARNING]: {
    color: 'blue.900',
    bg: 'yellow.200',
    fontWeight: '700',
    _hover: {
      bg: 'yellow.300',
      borderColor: 'blue.900',
    },
    _active: {
      bg: '_gray.200',
    },
  },
  [AppButtonColorScheme.SECONDARY]: {
    bg: 'blue.900',
    _focus: {
      outline: 'none',
    },
  },
  [AppButtonColorScheme.INFO]: {
    bg: 'blue.50',
    color: 'brand.800',
    _hover: {
      bg: 'secondaryGray.400',
    },
    _active: {
      bg: '_gray.200',
    },
  },
  [AppButtonColorScheme.SUCCESS]: {
    bg: 'green.200',
    color: 'gray.700',
    _hover: {
      bg: 'green.300',
    },
  },
  [AppButtonColorScheme.DISABLED]: {
    bg: colorMode === 'dark' ? 'navy.700' : 'secondaryGray.300',
    color: colorMode === 'dark' ? 'whiteAlpha.900' : 'navy.750',
    _hover: {
      bg: colorMode === 'dark' ? 'whiteAlpha.300' : 'secondaryGray.400',
    },
    _active: {
      bg: colorMode === 'dark' ? 'white' : 'brand.400',
    },
    _disabled: {
      bg: 'gray.400',
    },
  },
  [AppButtonColorScheme.DELETE]: {
    bg: 'red',
    color: 'white',
    _hover: {
      bg: 'white',
      color: 'red',
    },
    _active: {
      bg: 'white',
      color: 'red',
    },
  },
});
