import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoPlus } from 'react-icons/go';
import { useTypedSelector } from 'store';
import { selectJobDocuments } from 'store/job';
import { DocumentType, JobDocument } from 'api';
import useDocumentPresence from '../useDocumentType';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

export interface CadernetaValidationData {
  issuingNIF: string;
  validationCode: string;
}

interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  callback: (data: CadernetaValidationData) => void;
  jobId: string;
  uploadedDocs: JobDocument[];
}

export const ModalCadernetaValidation: React.FC<Props> = ({
  callback,
  isLoading,
  isOpen,
  onOpen,
  onClose,
  jobId,
  uploadedDocs,
}) => {
  const { t } = useTranslation();

  const { onClose: onCloseHandler } = useDisclosure({
    isOpen,
    onOpen,
    onClose: () => {
      handleReset();
      onClose();
    },
  });

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, jobId));

  const isRustica = !jobDocuments.some(document => document.type === DocumentType.CADERNETA_PREDIAL_URBANA);

  const { isRusticaDocumentPresent, isUrbanaDocumentPresent } = useDocumentPresence(jobDocuments, uploadedDocs);

  const [issuingNIF, setIssuingNIF] = useState<string>('');
  const [validationCode, setValidationCode] = useState<string>('');
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const initialRef = React.useRef<any>();
  const finalRef = React.useRef<any>();

  const handleReset = () => {
    setValidationCode('');
    setIssuingNIF('');
    setIsRequired(false);
  };

  const isValid = () => {
    return Boolean(issuingNIF && validationCode);
  };

  const submitHandler = async () => {
    if (!isValid()) {
      setIsRequired(true);
      return;
    }

    callback({ issuingNIF: issuingNIF.trim(), validationCode: validationCode.trim() });
    handleReset();
    onCloseHandler();
  };

  return (
    <>
      <AppButton
        buttonType={AppButtonType.XSMALL}
        title={t('pages.details.addCadernetaWithAccessCode')}
        isLoading={isLoading}
        onClick={onOpen}
        colorSchemes={[
          (isRustica ? isRusticaDocumentPresent : isUrbanaDocumentPresent)
            ? AppButtonColorScheme.DISABLED
            : AppButtonColorScheme.DANGER,
        ]}
        leftIcon={<GoPlus />}
      />

      <Modal size="lg" initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseHandler}>
        <ModalOverlay />
        <ModalContent p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('pages.details.cadernetaValidation')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 0">
            <Flex direction="column" gap="24px">
              <FormControl isRequired={isRequired}>
                <FormLabel mb="0">{t('pages.details.issuingNif')}</FormLabel>
                <FormLabel color={'gray.500'} fontSize="xs">
                  {t('pages.details.issuingNifExplain')}
                </FormLabel>
                <Input
                  ref={initialRef}
                  placeholder={t('NIF')}
                  value={issuingNIF}
                  onChange={event => setIssuingNIF(event.target.value)}
                />
              </FormControl>

              <FormControl isRequired={isRequired}>
                <FormLabel mb="0">{t('pages.details.validationCode')}</FormLabel>
                <FormLabel color={'gray.500'} fontSize="xs">
                  {t('pages.details.validationCodeExplain')}
                </FormLabel>
                <Input
                  placeholder={t('code')}
                  value={validationCode}
                  onChange={event => setValidationCode(event.target.value)}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton buttonType={AppButtonType.MAIN} onClick={submitHandler} title={t('pages.details.submit')} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
