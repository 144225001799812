import { UserRole } from '../../../api';

export const userRoleTKeyMap: Record<UserRole, string> = {
  [UserRole.MASTER_USER]: 'userRole.masterUser',
  [UserRole.REAL_ESTATE_AGENT]: 'userRole.realEstateAgent',
  [UserRole.REAL_ESTATE_AGENT_JUNIOR]: 'userRole.realEstateAgentJunior',
  [UserRole.REAL_ESTATE_AGENT_ASSISTANT]: 'userRole.realEstateAgentAssistant',
  [UserRole.ADMINISTRATIVE]: 'userRole.administrative',
  [UserRole.LEGAL]: 'userRole.legal',
  [UserRole.LEGAL_JUNIOR]: 'userRole.legalJunior',
  [UserRole.WORK_MANAGER]: 'userRole.workManager',
};
