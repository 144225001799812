import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useCheckboxGroup,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { Cancel, Magnifier, RecycleBing } from 'libs/ui/atoms/src';

import {
  RelatedParty,
  RelatedPartyCreateDto,
  RelatedPartyEntityType,
  RelatedPartyRelationType,
  RelatedPartyUpdateDto,
} from 'api/related-parties';
import { RelatedPartySelectItemType, SelectItem } from './SelectItem';
import { useCallback, useEffect, useState } from 'react';
import { CheckboxActionsWidget } from './CheckboxActionsWidget';
import { ResetToDefaultConfirmModal } from './ResetToDefaultConfirmModal';
import { EditRelatedPartyModal } from './mutateRelatedParty/EditRelatedPartyButton';
import { SelectAccordionItem } from './SelectAccordionItem';
import { CreateRelatedPartyButton } from './mutateRelatedParty/CreateRelatedPartyButton';
import { RelatedPartyTooltip } from './RelatedPartyTooltip';
import { PartyExtractWidget } from './partyExtract/PartyExtractWidget';
import { SubmissionRelatedPartyStatus } from '../../../../../api';
import { DropdownButton } from 'libs/ui/atoms/src/lib/button/DropdownButton';
import { MdUpload } from 'react-icons/md';
import { AppButton, AppButtonType, AppButtonColorScheme } from '../../../../../libs/ui/atoms/src/lib/appButton';
import { VCSearch } from '../../../../../libs/ui/atoms/src/lib/search/Search';

export interface RelatedPartiesProps {
  jobId: string;
  title: string;
  entityType: RelatedPartyEntityType;
  items: RelatedParty[];
  isShowCreateButton?: boolean;
  onSearch: (search: string) => void;

  onDeleteItems: (ids: string[]) => void;
  onResetToDefault: (entityType: RelatedPartyEntityType) => Promise<void>;
  onUpdate: (data: RelatedPartyUpdateDto) => Promise<void>;
  onCreate: (data: RelatedPartyCreateDto) => Promise<void>;
  onLoad: () => void;
}

export function RelatedPartiesWidget({
  jobId,
  title,
  entityType,
  items,
  isShowCreateButton,
  onSearch,
  onDeleteItems,
  onResetToDefault,
  onUpdate,
  onCreate,
  onLoad,
}: RelatedPartiesProps) {
  const { t } = useTranslation();
  const toast = useToast();

  const cardBackgroundColor = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('brand.800', 'white');
  const pillColor = useColorModeValue('gray.700', 'white');
  const buttonBackground = useColorModeValue('gray.360', 'whiteAlpha.200');
  const borderColor = useColorModeValue('red.500', 'red.500');

  const { value, onChange, setValue } = useCheckboxGroup({});
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [isDropzoneActivated, setIsDropzoneActivated] = useState(false);
  const [isDropzoneActivatedManually, setIsDropzoneActivatedManually] = useState(false);
  const [importToParent, setImportToParent] = useState<RelatedParty>();
  const [importParty, setImportParty] = useState<RelatedParty>();

  const [isResetToDefaultModalOpen, setIsResetToDefaultModalOpen] = useState(false);
  const [selectedRelatedPartyForEdit, setSelectedRelatedPartyForEdit] = useState<RelatedParty>(items[0]);
  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  const onInvertCheckboxes = useCallback(() => {
    setValue(
      items
        .map(i => i.id)
        .reduce((acc, i) => (value.includes(i) ? acc.filter(j => i !== j) : [...acc, i]), [] as string[]),
    );
  }, [items, value, setValue]);

  useEffect(() => {
    setSelectedRelatedPartyForEdit(items[0]);
  }, [items]);

  useEffect(() => {
    onSearch(search);
  }, [search, onSearch]);

  const handleResetToDefault = useCallback(async () => {
    try {
      await onResetToDefault(entityType);
      setIsResetToDefaultModalOpen(false);
      setValue([]);
    } catch (error) {
      toast({
        title: t('relatedParties.unableToResetToDefault'),
        description: ((error as any).data as Error)?.message,
        status: 'error',
        duration: 5000,
      });
    }
  }, [onResetToDefault, entityType, setValue, toast, t]);

  const handleUpdate = useCallback(
    async (data: RelatedPartyUpdateDto) => {
      await onUpdate(data);
    },
    [onUpdate],
  );

  const onItemClick = useCallback(
    (relatedParty: RelatedParty) => {
      if (relatedParty.submission && relatedParty.submission?.status !== SubmissionRelatedPartyStatus.COMPLETED) {
        return;
      }
      setSelectedRelatedPartyForEdit(relatedParty);
      setIsShowEditModal(true);
    },
    [setSelectedRelatedPartyForEdit],
  );

  const onPartyExtractSubmitted = () => {
    setIsDropzoneActivated(false);
    setIsDropzoneActivatedManually(false);
    setImportParty(undefined);
    onLoad();
  };

  const onPartyExtractCanceled = () => {
    setIsDropzoneActivated(false);
    setIsDropzoneActivatedManually(false);
    setImportToParent(undefined);
    setImportParty(undefined);
  };

  const onImportToParentClick = (party: RelatedParty) => {
    setImportToParent(party);
    setIsDropzoneActivated(true);
  };

  const onUpdateImportClick = (party: RelatedParty) => {
    setImportParty(party);
    setIsDropzoneActivated(true);
  };

  return (
    <>
      <EditRelatedPartyModal
        isOpen={isShowEditModal}
        relatedParty={selectedRelatedPartyForEdit}
        parentParty={items.find(i => i.id === selectedRelatedPartyForEdit?.relatedPartyId)}
        onSubmit={handleUpdate}
        onClose={() => {
          setIsShowEditModal(false);
          setSelectedRelatedPartyForEdit({ ...selectedRelatedPartyForEdit });
        }}
      />

      <Card
        minH={isDropzoneActivated ? '425px' : undefined}
        flexDirection="column"
        w="100%"
        p={0}
        bg={cardBackgroundColor}
        overflowX={{ sm: 'auto', lg: 'hidden' }}
        position="relative"
        pb={'16px'}
        maxH={'max(calc(100vh - 450px), 450px)'}
        onDragOver={() => setIsDropzoneActivated(true)}
        onDragLeave={() => !isDropzoneActivatedManually && setIsDropzoneActivated(false)}
      >
        <Flex
          visibility={isDropzoneActivated ? 'hidden' : 'visible'}
          opacity={isDropzoneActivated ? 0 : 1}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap="16px"
          p={'24px 24px 16px 24px'}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {isSearchFocused ? (
            <></>
          ) : (
            <Text color={textColor} fontSize={20} fontWeight={'bold'}>
              {title}
            </Text>
          )}

          <Flex flex={1} flexDirection="row-reverse" justifyContent={'flex-start'} alignItems={'center'} gap="12px">
            <ResetToDefaultConfirmModal
              entityType={entityType}
              isOpen={isResetToDefaultModalOpen}
              onClose={() => setIsResetToDefaultModalOpen(false)}
              onConfirm={handleResetToDefault}
            />

            {isShowCreateButton && (
              <CreateRelatedPartyButton
                jobId={jobId}
                entityType={entityType}
                onSubmit={onCreate}
                isModalOpen={isOpenCreateModal}
                onModalClose={setIsOpenCreateModal}
                onCodeSubmit={onLoad}
                onOpenDropzone={party => {
                  setIsDropzoneActivated(true);
                  setImportToParent(party);
                }}
              />
            )}
            {isShowCreateButton && (
              <DropdownButton
                actions={[
                  { title: t('add'), clickOnSelect: false, onClick: () => setIsOpenCreateModal(true) },
                  {
                    title: t('relatedParties.resetToDefault'),
                    clickOnSelect: true,
                    onClick: () => setIsResetToDefaultModalOpen(true),
                  },
                ]}
              ></DropdownButton>
            )}

            <VCSearch search={search} onSearch={setSearch} onSearchFocusChange={setIsSearchFocused} />
          </Flex>
        </Flex>

        <PartyExtractWidget
          isDropzoneActivated={isDropzoneActivated}
          jobId={jobId}
          entityType={entityType}
          parentRelatedParty={importToParent}
          relatedPartyToUpdate={importParty}
          onCancel={onPartyExtractCanceled}
          onSubmitted={onPartyExtractSubmitted}
          onFilesDropped={() => !isDropzoneActivated && setIsDropzoneActivated(true)}
        />

        <CheckboxActionsWidget
          isActive={!!value.length}
          onDelete={() => {
            onDeleteItems(value as string[]);
            setValue([]);
          }}
          onDeleteUnselected={() => {
            onDeleteItems(items.filter(i => !value.includes(i.id)).map(i => i.id));
            setValue([]);
          }}
          onInvertCheckboxes={onInvertCheckboxes}
          onReset={() => setValue([])}
        />

        <Box
          overflow={'auto'}
          style={{
            scrollbarTrackColor: 'white',
          }}
          p="0px 8px 8px 8px"
        >
          <Flex flexDirection={'column'} p={'0 16px'}>
            {items.map(i =>
              (i.relationType === RelatedPartyRelationType.COMPANY &&
                (!i.submission || i.submission?.status === SubmissionRelatedPartyStatus.COMPLETED)) ||
              i.relationType === RelatedPartyRelationType.HEIR ? (
                <SelectAccordionItem
                  key={i.id}
                  isChecked={value.includes(i.id)}
                  onCheck={() => onChange(i.id)}
                  relatedParty={i}
                  items={i.nestedRelatedParties ?? []}
                  onCLick={e => onItemClick(e)}
                  onCreate={onCreate}
                  onDelete={id => onDeleteItems([id])}
                  onImportChild={() => onImportToParentClick(i)}
                  onImportUpdate={party => onUpdateImportClick(party)}
                  onLoad={onLoad}
                  onOpenDropzone={party => {
                    setIsDropzoneActivated(true);
                    setImportToParent(party);
                  }}
                />
              ) : (
                <SelectItem
                  key={i.id}
                  type={RelatedPartySelectItemType.PRIMARY}
                  relatedParty={i}
                  isChecked={value.includes(i.id)}
                  onCheck={() => onChange(i.id)}
                  rightActionChildren={
                    !i.submission || i.submission?.status === SubmissionRelatedPartyStatus.COMPLETED ? (
                      <Flex pe={'44px'} gap="8px">
                        <AppButton
                          leftIcon={<Icon as={MdUpload} w="12px" h="12px" color={'gray.700'} />}
                          onClick={e => {
                            e.stopPropagation();
                            onUpdateImportClick(i);
                          }}
                          title={t('uploadDetails')}
                          buttonType={AppButtonType.XSMALL}
                          colorSchemes={[AppButtonColorScheme.SUCCESS]}
                        />

                        <AppButton
                          buttonType={AppButtonType.XSMALL}
                          pr="8px"
                          onClick={() => onItemClick(i)}
                          title={t('edit')}
                        />

                        <RelatedPartyTooltip relatedParty={i} />
                      </Flex>
                    ) : i.submission?.status === SubmissionRelatedPartyStatus.ERROR ? (
                      <Flex pe={'8px'}>
                        <AppButton
                          buttonType={AppButtonType.ICON}
                          onClick={e => {
                            e.stopPropagation();
                            onDeleteItems([i.id]);
                          }}
                          colorSchemes={[AppButtonColorScheme.DANGER]}
                          children={<RecycleBing boxSize={5} />}
                        />
                      </Flex>
                    ) : undefined
                  }
                  onCLick={() => onItemClick(i)}
                />
              ),
            )}
          </Flex>
        </Box>
      </Card>
    </>
  );
}
