const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const apiUrl = process.env.REACT_APP_API_URL;

const stage = process.env.REACT_APP_STAGE;

export const environment = {
  production: process.env.NODE_ENVIRONMENT === 'production',
  stage: (stage ?? 'local') as 'local' | 'dev' | 'prod',
  apiUrl: apiUrl ?? 'https://api.dev.vericasa.com',
  domain: domain || 'vericasa-dev.eu.auth0.com',
  clientId: clientId || '',
  audience: audience || '*',
  scope: 'openid profile email',
  contactEmail: process.env.REACT_APP_CONTACT_EMAIL || '',
  veriKnowsUrl: 'https://veriknows-pt.pages.dev',
  filePollingIntervalSeconds: process.env.REACT_APP_FILE_POLLING_INTERVAL_SECONDS
    ? parseInt(process.env.REACT_APP_FILE_POLLING_INTERVAL_SECONDS, 10)
    : 3600,
  /**
   * 10MB default
   */
  fileSizeLimit: parseInt(process.env.REACT_APP_LIMIT_FILE_SIZE_BYTES || '10485760', 10),
};
