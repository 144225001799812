import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ListPageType {
  JOB_LIST = 'JOB_LIST',
  SIGNING_LIST = 'SIGNING_LIST',
}

export interface ListPageParamsState {
  search: Record<ListPageType, string>;
}

const initialState: ListPageParamsState = {
  search: {
    JOB_LIST: '',
    SIGNING_LIST: '',
  },
};

const listParamsSlice = createSlice({
  name: 'listParams',
  initialState: initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<{ pageType: ListPageType; query: string }>) => {
      const { pageType, query } = action.payload;
      state.search[pageType] = query;
      // state.search = { ...state.search, pageType: query };
    },
  },
});

export const { setSearch } = listParamsSlice.actions;

export const { reducer: listParamsReducer, reducerPath: listParamsReducerPath } = listParamsSlice;
