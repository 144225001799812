import { environment } from '../environments/environment';

export const isFileSizeValid = (file: File): boolean => {
  return file.size <= environment.fileSizeLimit;
};

export const isEachFileSizeValid = (files: File[]): boolean => {
  return files.every(file => isFileSizeValid(file));
};

export const getInvalidSizedFiles = (files: File[]): File[] => {
  return files.filter(file => !isFileSizeValid(file));
};
