import { Flex, Text, useColorModeValue, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DocumentType, Job, JobDocument } from 'api';
import { useTypedSelector } from 'store';
import { selectJobDocuments } from 'store/job';
import { MdCheck, MdClose } from 'react-icons/md';
import useDocumentPresence from './JobDocumentsWidget/useDocumentType';

interface JobUploadedDocsProps {
  job: Job;
  uploadedDocs: JobDocument[];
}

export function JobUploadedDocs({ job, uploadedDocs }: JobUploadedDocsProps) {
  const { t } = useTranslation();

  const jobStep: number = useTypedSelector(state => state.wizard.jobWizard.step);

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, job.id));

  const textColor = useColorModeValue('gray.600', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const isJobStep = (step: number) => {
    return jobStep === step;
  };

  const isRustica = !jobDocuments.some(document => document.type === DocumentType.CADERNETA_PREDIAL_URBANA);

  const { isCertidaoPresent, isRusticaDocumentPresent, isUrbanaDocumentPresent } = useDocumentPresence(
    jobDocuments,
    uploadedDocs,
  );

  return (
    <>
      {!isJobStep(1) && (
        <Flex
          direction="column"
          gap="24px"
          p={'24px'}
          w="100%"
          h="fit-content"
          borderRadius={'20px'}
          bg={widgetBackgroundColor}
        >
          <Text color={textColor} fontWeight="700" fontSize={'18px'}>
            {t('pages.wizard.uploadedDocs')}
          </Text>
          <Flex direction="column">
            <Flex>
              <Icon
                as={(isRustica ? isRusticaDocumentPresent : isUrbanaDocumentPresent) ? MdCheck : MdClose}
                color={(isRustica ? isRusticaDocumentPresent : isUrbanaDocumentPresent) ? 'green.400' : 'red.500'}
                w="24px"
                h="24px"
              />
              <Text>{t('pages.wizard.cadernetaPred')}</Text>
            </Flex>
            <Flex>
              <Icon
                as={isCertidaoPresent ? MdCheck : MdClose}
                color={isCertidaoPresent ? 'green.400' : 'red.500'}
                w="24px"
                h="24px"
              />
              <Text>{t('pages.wizard.certidaoPerm')}</Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}
