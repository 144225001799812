import { DocumentType, JobDocument } from 'api';
import { useMemo } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';

const useDocumentPresence = (jobDocuments: JobDocument[], uploadedDocs: JobDocument[]) => {
  const isCertidaoPresent = useMemo(
    () =>
      jobDocuments.some((doc: JobDocument) => doc.type === DocumentType.CERTIDAO_PERMANENTE) ||
      uploadedDocs.some((doc: JobDocument) => doc.type === DocumentType.CERTIDAO_PERMANENTE),
    [jobDocuments, uploadedDocs],
  );

  const isRusticaDocumentPresent = useMemo(
    () =>
      jobDocuments.some((doc: JobDocument) => doc.type === DocumentType.CADERNETA_PREDIAL_RUSTICA) ||
      uploadedDocs.some((doc: JobDocument) => doc.type === DocumentType.CADERNETA_PREDIAL_RUSTICA),
    [jobDocuments, uploadedDocs],
  );

  const isUrbanaDocumentPresent = useMemo(
    () =>
      jobDocuments.some((doc: JobDocument) => doc.type === DocumentType.CADERNETA_PREDIAL_URBANA) ||
      uploadedDocs.some((doc: JobDocument) => doc.type === DocumentType.CADERNETA_PREDIAL_URBANA),
    [jobDocuments, uploadedDocs],
  );

  return { isCertidaoPresent, isRusticaDocumentPresent, isUrbanaDocumentPresent };
};

export default useDocumentPresence;
