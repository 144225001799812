import { Trans, useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import {
  RightOfFirstRefusalCurrency,
  RightOfFirstRefusalDensity,
  RightOfFirstRefusalDto,
  RightOfFirstRefusalItemsMeasure,
  RightOfFirstRefusalRelatedParty,
  RightOfFirstRefusalTypeOfBusiness,
  useLazyGetRightOfFirstRefusalDataQuery,
  useRequestRightOfFirstRefusalMutation,
  JobMetadata,
  JobMetadataId,
  JobMetadataItem,
  JobMetadataR03X01,
} from 'api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useRightOfFirstRefusalModalForm } from './useRightOfFirstRefusalModalForm';
import { RightOfFirstRefusalSuccess } from './RightOfFirstRefusalModalSuccess';
import { environment } from 'environments/environment';
import { SelectInputInputField } from 'libs/ui/atoms/src/lib/input-field/selectField';
import {
  tByRightOfFirstRefusalCurrency,
  tByRightOfFirstRefusalDensity,
  tByRightOfFirstRefusalItemsMeasure,
  tByRightOfFirstRefusalTypeOfBusiness,
} from './utils';
import { formatDateToYYYYMMDD } from 'utils/date';
import { useRightOfFirstRefusalRelatedParties } from './useRelatedPartiesRefusalForm';
import { RightOfFirstRefusalNoParties } from './RightOfFirstRefusalModalNoParties';
import { Link } from 'react-router-dom';
import { RadioButton } from '../../libs/ui/atoms/src/lib/radiobutton/RadioButton';
import { VCDatePicker } from 'libs/ui/atoms/src/lib/datePicker/DatePicker';
import { RightOfFirstRefusalModalNoNif } from './RightOfFirstRefusalModalNoNif';
import { SwitchInputField } from '../../libs/ui/atoms/src/lib/switch/SwitchInputField';
import { FormInputField } from '../../libs/ui/atoms/src/lib/input-field/form-input-field';
import DecimalOrIntegerInput from '../../libs/ui/atoms/src/lib/input-field/DecimalOrIntegerInput';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';

interface MatrixInfo {
  firstId: string;
  otherIds: string[];
}
function isJobMetadataR03X01(item: JobMetadataItem): item is JobMetadataR03X01 {
  return item.id === JobMetadataId.R03X01;
}

function extractMatrixIds(jobMetadata: JobMetadata) {
  const R03X01Elements = jobMetadata.metadata.filter((item): item is JobMetadataR03X01 => isJobMetadataR03X01(item));
  const nonRusticaElements = R03X01Elements[0].metadata.filter(item => item.type !== 'Rústica');
  const idArr: MatrixInfo = {
    firstId: '',
    otherIds: [],
  };

  if (nonRusticaElements.length) {
    idArr.firstId = nonRusticaElements[0].id;

    if (nonRusticaElements.length > 1) {
      nonRusticaElements.forEach(item => {
        if (item.id !== idArr.firstId) {
          idArr.otherIds.push(item.id);
        }
      });
    }
  }

  return idArr;
}

export interface RightOfFirstRefusalModalProps {
  jobId: string;
  jobMetadata: JobMetadata;
}
export const RightOfFirstRefusalModal = ({ jobId, jobMetadata }: RightOfFirstRefusalModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isNoPartiesOpen, setNoPartiesOpen] = useState(false);
  const [isPartiesNoNifOpen, setIsPartiesNoNifOpen] = useState(false);
  const [isSimulateRequest, setIsSimulateRequest] = useState(false);

  interface InitialOrgData {
    name: string;
    nif?: string;
    email?: string;
    address?: string;
    phone?: '';
  }

  interface Seller {
    name: string;
    nif?: string;
    email?: string;
    phone?: string;
  }

  const [initialOrgData, setInitialOrgData] = useState<InitialOrgData>({ name: '' });

  const [getRightOfFirstRefusalData, { isLoading: isLoadingData }] = useLazyGetRightOfFirstRefusalDataQuery({});
  const [requestRightOfFirstRefusal, { isLoading }] = useRequestRightOfFirstRefusalMutation({});
  const { form, dispatch, validate } = useRightOfFirstRefusalModalForm();
  const { buyers, sellers, heirs, heranças, load, onNifChange } = useRightOfFirstRefusalRelatedParties(jobId);

  const [isHerdeirosInsteadOfHerança, setIsHerdeirosInsteadOfHerança] = useState<boolean>(true);

  const open = useCallback(async () => {
    try {
      const [_, data] = await Promise.all([load(), getRightOfFirstRefusalData({ jobId })]);
      if (data.isError || !data.data) {
        toast({
          title: t('rightOfFirstRefusal.unableToGetData'),
          description: ((data.error as FetchBaseQueryError).data as Error)?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // We must have at least 1 buyer and 1 seller (as seller, or as a herança)
      if (!buyers.length || (!sellers.length && !heranças.length)) {
        setNoPartiesOpen(true);
        return;
      }

      // All parties must have NIFs
      if ([...buyers, ...sellers, ...heirs, ...heranças].find(party => !party.nif)) {
        setIsPartiesNoNifOpen(true);
        return;
      }

      const orgData = {
        name: data.data.name,
        nif: data.data.nif,
        email: data.data.email,
        address: data.data.address,
      };

      setInitialOrgData(orgData);

      const metadata = extractMatrixIds(jobMetadata);

      if (metadata?.firstId) {
        dispatch({ type: 'setMatrixArticle', value: metadata.firstId });
      }

      if (metadata?.otherIds?.length) {
        const formattedComments = t('rightOfFirstRefusal.form.otherMatrixArticles') + metadata.otherIds.join(', ');

        dispatch({ type: 'setComments', value: formattedComments });
      }

      dispatch({
        type: 'setInitialState',
        value: {
          ...data.data,
        },
      });
      onOpen();
    } catch (error) {
      toast({
        title: t('rightOfFirstRefusal.unableRequestRightOfFirstRefusal'),
        description: (error as Error).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [load, getRightOfFirstRefusalData, jobId, buyers, buyers.length, sellers, dispatch, onOpen, toast, t]);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', value: {} });
    onClose();
  }, [onClose, dispatch]);

  const onSubmit = useCallback(async () => {
    const validationMessages = validate();
    if (validationMessages.length > 0) {
      toast({
        title: t('validationError'),
        description: validationMessages.map(i => t(i)).join(', \n'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    let finalSellers: RightOfFirstRefusalRelatedParty[] = [];
    finalSellers = [
      ...(sellers.map(i => ({ name: i.name, nif: i.nif })) as RightOfFirstRefusalRelatedParty[]),
      ...((isHerdeirosInsteadOfHerança ? heirs : heranças).map(i => ({
        name: i.name,
        nif: i.nif,
      })) as RightOfFirstRefusalRelatedParty[]),
    ];

    if (finalSellers.length > 11 || buyers.length > 11) {
      toast({
        title: t('rightOfFirstRefusal.tooManySellers'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const result = await requestRightOfFirstRefusal({
        ...(form as RightOfFirstRefusalDto),
        jobId,
        buyers: buyers.map(i => ({ name: i.name, nif: i.nif })) as RightOfFirstRefusalRelatedParty[],
        sellers: finalSellers,
        simulateRequest: isSimulateRequest,
      }).unwrap();
      if (!result.success) {
        return;
      }
      close();
      setIsSuccessOpen(true);
    } catch (error) {
      console.log(error);
      toast({
        title: t('rightOfFirstRefusal.unableToRequestRightOfFirstRefusal'),
        description: ((error as FetchBaseQueryError).data as Error)?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    validate,
    sellers,
    isHerdeirosInsteadOfHerança,
    heirs,
    heranças,
    buyers,
    toast,
    t,
    requestRightOfFirstRefusal,
    form,
    jobId,
    isSimulateRequest,
    close,
  ]);

  const onSuccessClose = useCallback(() => {
    setIsSuccessOpen(false);
  }, [setIsSuccessOpen]);

  const buttonTextColor = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('navy.750', 'white');
  const menuItemBg = useColorModeValue('gray.50', 'whiteAlpha.200');
  const menuItemHoveBg = useColorModeValue('gray.150', 'whiteAlpha.300');
  const secondaryBg = useColorModeValue('gray.50', undefined);
  const sectionSubtitleColor = useColorModeValue('gray.500', 'whiteAlpha.600');

  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState(0);

  const handleCopyOrganizationDetails = () => {
    if (initialOrgData) {
      dispatch({ type: 'setName', value: initialOrgData.name ?? '' });
      dispatch({ type: 'setNifNips', value: initialOrgData.nif ?? '' });
      dispatch({ type: 'setEmail', value: initialOrgData.email ?? '' });
      dispatch({ type: 'setAddress', value: initialOrgData.address ?? '' });
      dispatch({ type: 'setPhone', value: '' });
    }
  };

  const handleCopyFirstSellerDetails = () => {
    const firstSeller = sellers[0];
    dispatch({ type: 'setName', value: firstSeller.name });
    dispatch({ type: 'setNifNips', value: firstSeller.nif ?? '' });
    dispatch({ type: 'setEmail', value: firstSeller.email ?? '' });
    dispatch({ type: 'setPhone', value: firstSeller.phone ?? '' });
    dispatch({ type: 'setAddress', value: firstSeller.address ?? '' });
  };

  // firstSeller may be incomplete while documents are uploading/processing
  const getSellerInfoText = (sellers: Seller[], t: (key: string) => string) => {
    if (sellers.length) {
      const firstSeller = sellers[0];
      if (firstSeller.name) {
        return `${t('rightOfFirstRefusal.copySellerInfo')} (${firstSeller.name.split(' ')[0]} ${firstSeller.name
          .split(' ')
          .slice(-1)}) `;
      }
    }
    return t('rightOfFirstRefusal.copySellerInfo');
  };

  const getOrganizationInfoText = (initialOrgData: InitialOrgData, t: (key: string) => string) => {
    if (initialOrgData.name) {
      const firstName = initialOrgData.name.split(' ')[0];
      const lastName = initialOrgData.name.split(' ').slice(-1);
      return `${t('rightOfFirstRefusal.copyOrgDetails')} (${firstName} ${lastName})`;
    }
    return t('rightOfFirstRefusal.copyOrgDetails');
  };

  return (
    <>
      <Box
        cursor={'pointer'}
        borderRadius={'8px'}
        _hover={{ bg: menuItemHoveBg }}
        bg={menuItemBg}
        color={textColor}
        fontSize="14px"
        lineHeight={'14px'}
        fontWeight={500}
        p="12px"
        onClick={open}
      >
        {t('rightOfFirstRefusal.itemName')}
      </Box>

      <RightOfFirstRefusalSuccess isOpen={isSuccessOpen} onClose={onSuccessClose} />
      <RightOfFirstRefusalNoParties isOpen={isNoPartiesOpen} onClose={() => setNoPartiesOpen(false)} />
      <RightOfFirstRefusalModalNoNif isOpen={isPartiesNoNifOpen} onClose={() => setIsPartiesNoNifOpen(false)} />

      <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside" id="right-or-refusal-modal">
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('rightOfFirstRefusal.form.title')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 3px">
            <Accordion index={expandedAccordionIndex} allowMultiple={false} allowToggle>
              <AccordionItem color={textColor} bg={expandedAccordionIndex === 0 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(0)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('rightOfFirstRefusal.form.detailsOfApplicant')}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  <hr />
                  <Grid
                    py={'12px'}
                    columnGap={'24px'}
                    rowGap={'16px'}
                    gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}
                  >
                    <AppButton
                      mt={4}
                      width="100%"
                      onClick={handleCopyFirstSellerDetails}
                      buttonType={AppButtonType.XSMALL}
                      title={getSellerInfoText(sellers, t)}
                      colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                    />
                    <AppButton
                      mt={4}
                      width="100%"
                      onClick={handleCopyOrganizationDetails}
                      buttonType={AppButtonType.XSMALL}
                      title={getOrganizationInfoText(initialOrgData, t)}
                      colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
                    />
                    <FormInputField
                      label={t('rightOfFirstRefusal.form.name')}
                      value={form.name}
                      onChange={value => dispatch({ type: 'setName', value })}
                    />
                    <FormInputField
                      label={t('rightOfFirstRefusal.form.email')}
                      value={form.email}
                      onChange={value => dispatch({ type: 'setEmail', value })}
                    />
                    <FormInputField
                      label={t('rightOfFirstRefusal.form.nifNips')}
                      value={form.nif}
                      onChange={value => dispatch({ type: 'setNifNips', value })}
                    />
                    <FormInputField
                      label={t('rightOfFirstRefusal.form.address')}
                      value={form.address}
                      onChange={value => dispatch({ type: 'setAddress', value })}
                    />
                    <FormInputField.Phone
                      label={t('rightOfFirstRefusal.form.phone')}
                      value={form.phone}
                      onChange={value => dispatch({ type: 'setPhone', value })}
                    />
                  </Grid>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 1 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(1)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Flex direction={'column'} alignItems="flex-start">
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('rightOfFirstRefusal.form.buyers')}
                      </Text>

                      <Text fontSize={'14px'} color={sectionSubtitleColor}>
                        <Trans
                          i18nKey="rightOfFirstRefusal.toEditThePartiesBelowPleaseGoToTheContractsPartiesPage"
                          components={{
                            linkToPage: (
                              <Link
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                to={`/jobs/${jobId}/contracts-and-parties`}
                                onClick={close}
                              ></Link>
                            ),
                          }}
                        ></Trans>
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  <hr />
                  <Flex py={'12px'} direction={'column'} gap="16px" maxH={'400px'} overflowY="auto">
                    {buyers.map(i => (
                      <Flex key={i.id} alignItems={'center'} gap={'24px'}>
                        <FormInputField
                          label={t('rightOfFirstRefusal.form.name')}
                          value={i.name}
                          onChange={() => ({})}
                          isReadonly={true}
                        />
                        <FormInputField
                          label={t('rightOfFirstRefusal.form.nifNips')}
                          onChange={value =>
                            onNifChange(i.entityType, {
                              id: i.id,
                              nif: value,
                            })
                          }
                          value={i.nif}
                          isReadonly={true}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 2 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(2)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Flex direction={'column'} alignItems="flex-start">
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('rightOfFirstRefusal.form.sellers')}
                      </Text>

                      <Text fontSize={'14px'} color={sectionSubtitleColor}>
                        <Trans
                          i18nKey="rightOfFirstRefusal.toEditThePartiesBelowPleaseGoToTheContractsPartiesPage"
                          components={{
                            linkToPage: (
                              <Link
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                to={`/jobs/${jobId}/contracts-and-parties`}
                                onClick={close}
                              ></Link>
                            ),
                          }}
                        ></Trans>
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  {!!heirs.length && (
                    <>
                      <hr />
                      <Box py={'12px'} display={'flex'} justifyContent={'space-between'}>
                        <FormLabel mb="4px">{t('rightOfFirstRefusal.form.useHerdeirosAsSellers')}?</FormLabel>
                        <Box display={'flex'}>
                          <SwitchInputField
                            isChecked={isHerdeirosInsteadOfHerança}
                            onCheck={() => {
                              setIsHerdeirosInsteadOfHerança(!isHerdeirosInsteadOfHerança);
                            }}
                          />
                        </Box>
                      </Box>
                      <hr />
                    </>
                  )}
                  <Flex py="12px" direction="column" gap="16px" maxH="400px" overflowY="auto">
                    <>
                      {(isHerdeirosInsteadOfHerança && heirs.length ? heirs : heranças).map(i => (
                        <Flex key={i.id} alignItems="center" gap="24px">
                          <FormInputField
                            label={t('rightOfFirstRefusal.form.name')}
                            value={i.name}
                            isReadonly={true}
                            onChange={() => ({})}
                          />
                          <FormInputField
                            label={t('rightOfFirstRefusal.form.nifNips')}
                            value={i.nif}
                            isReadonly={true}
                            onChange={value =>
                              onNifChange(i.entityType, {
                                id: i.id,
                                nif: value,
                              })
                            }
                          />
                        </Flex>
                      ))}
                      {sellers.map(i => (
                        <Flex key={i.id} alignItems="center" gap="24px">
                          <FormInputField
                            label={t('rightOfFirstRefusal.form.name')}
                            value={i.name}
                            isReadonly={true}
                            onChange={() => ({})}
                          />
                          <FormInputField
                            label={t('rightOfFirstRefusal.form.nifNips')}
                            value={i.nif}
                            isReadonly={true}
                            onChange={value =>
                              onNifChange(i.entityType, {
                                id: i.id,
                                nif: value,
                              })
                            }
                          />
                        </Flex>
                      ))}
                    </>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 3 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(3)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('rightOfFirstRefusal.form.propertyIdentification')}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  <hr />
                  <Box py={'12px'}>
                    <Flex direction={'column'} gap="16px">
                      <FormInputField
                        label={t('rightOfFirstRefusal.form.propertyAddress')}
                        value={form.propertyLocation}
                        onChange={value => dispatch({ type: 'setPropertyLocation', value })}
                      />

                      <Grid columnGap={'24px'} rowGap={'16px'} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
                        <FormControl>
                          <FormLabel
                            title={t('rightOfFirstRefusal.form.privateGrossArea')}
                            overflow="hidden"
                            maxWidth="375px"
                            textOverflow="ellipsis"
                            noOfLines={1}
                            mb="4px"
                          >
                            {t('rightOfFirstRefusal.form.privateGrossArea')}
                          </FormLabel>
                          <DecimalOrIntegerInput
                            color={textColor}
                            type="decimal"
                            label={t('rightOfFirstRefusal.form.privateGrossArea')}
                            placeholder={t('rightOfFirstRefusal.form.privateGrossArea')}
                            value={form.privateGrossArea}
                            onChange={e =>
                              dispatch({
                                type: 'setPrivateGrossArea',
                                value: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel mb="4px">{t('rightOfFirstRefusal.form.itemsMeasure')}</FormLabel>
                          <SelectInputInputField<RightOfFirstRefusalItemsMeasure>
                            items={Object.values(RightOfFirstRefusalItemsMeasure).map(i => ({
                              value: i,
                              label: t(tByRightOfFirstRefusalItemsMeasure[i]),
                            }))}
                            value={form.privateGrossAreaItemsMeasure}
                            placeholder={t('rightOfFirstRefusal.form.itemsMeasure')}
                            onChange={e =>
                              dispatch({
                                type: 'setPrivateGrossAreaItemsMeasure',
                                value: e,
                              })
                            }
                          />
                        </FormControl>

                        <FormInputField
                          type="number"
                          label={t('rightOfFirstRefusal.form.totalArea')}
                          value={form.totalArea}
                          onChange={value => dispatch({ type: 'setTotalArea', value })}
                        />

                        <FormControl>
                          <FormLabel mb="4px">{t('rightOfFirstRefusal.form.itemsMeasure')}</FormLabel>
                          <SelectInputInputField<RightOfFirstRefusalItemsMeasure>
                            items={Object.values(RightOfFirstRefusalItemsMeasure).map(i => ({
                              value: i,
                              label: t(tByRightOfFirstRefusalItemsMeasure[i]),
                            }))}
                            value={form.totalAreaItemsMeasure}
                            placeholder={t('rightOfFirstRefusal.form.itemsMeasure')}
                            onChange={e =>
                              dispatch({
                                type: 'setTotalAreaItemsMeasure',
                                value: e,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel mb="4px">{t('rightOfFirstRefusal.form.destiny')}</FormLabel>
                          <SelectInputInputField<RightOfFirstRefusalDensity>
                            items={Object.values(RightOfFirstRefusalDensity).map(i => ({
                              value: i,
                              label: t(tByRightOfFirstRefusalDensity[i]),
                            }))}
                            value={form.destiny}
                            placeholder={t('rightOfFirstRefusal.form.destiny')}
                            onChange={e => dispatch({ type: 'setDestiny', value: e })}
                          />
                        </FormControl>

                        <FormInputField
                          label={t('rightOfFirstRefusal.form.shareShare')}
                          value={form.shareShare}
                          onChange={value => dispatch({ type: 'setShareShare', value })}
                        />

                        <FormInputField
                          label={t('rightOfFirstRefusal.form.matrixArticle')}
                          value={form.matrixArticle}
                          onChange={value => dispatch({ type: 'setMatrixArticle', value })}
                        />

                        <FormInputField
                          isReadonly
                          label={t('rightOfFirstRefusal.form.descriptionOfSheet')}
                          value={form.descriptionOfSheet}
                        />
                        <FormInputField
                          isReadonly
                          label={t('rightOfFirstRefusal.form.autonomousFraction')}
                          value={form.autonomousFraction}
                        />
                        <FormInputField
                          isReadonly
                          label={t('rightOfFirstRefusal.form.identifierParish')}
                          value={form.identifier_parish}
                        />
                        <FormInputField
                          isReadonly
                          label={t('rightOfFirstRefusal.form.identifierConservatoria')}
                          value={form.identifier_conservatória}
                        />

                        <FormControl display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
                          <FormLabel mb="4px">{t('rightOfFirstRefusal.form.isLeased')}</FormLabel>
                          <RadioButton
                            defaultValue={'false'}
                            items={[
                              {
                                value: 'false',
                                label: t('relatedParties.isLeased.noIsNotRented'),
                              },
                              {
                                value: 'true',
                                label: t('relatedParties.isLeased.yesIsRented'),
                              },
                            ]}
                            onChange={e =>
                              dispatch({
                                type: 'setIsLeased',
                                value: e === 'false' ? false : true,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Flex>
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 4 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  onClick={() => setExpandedAccordionIndex(4)}
                  p={'16px 24px'}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('rightOfFirstRefusal.form.transmissionData')}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  <hr />
                  <Grid
                    py={'12px'}
                    columnGap={'24px'}
                    rowGap={'16px'}
                    gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}
                  >
                    <FormControl>
                      <FormLabel mb="4px">{t('rightOfFirstRefusal.form.typeOfBusiness')}</FormLabel>
                      <SelectInputInputField<RightOfFirstRefusalTypeOfBusiness>
                        items={Object.values(RightOfFirstRefusalTypeOfBusiness).map(i => ({
                          value: i,
                          label: t(tByRightOfFirstRefusalTypeOfBusiness[i]),
                        }))}
                        value={form.typeOfBusiness}
                        placeholder={t('rightOfFirstRefusal.form.typeOfBusiness')}
                        onChange={e => dispatch({ type: 'setTypeOfBusiness', value: e })}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('rightOfFirstRefusal.form.expectedDealDate')}</FormLabel>
                      <VCDatePicker
                        placeholder={t('rightOfFirstRefusal.form.expectedDealDate')}
                        selectedValue={form.expectedDealDate ? new Date(form.expectedDealDate) : undefined}
                        onChange={e => {
                          if (e) {
                            dispatch({
                              type: 'setExpectedDealDate',
                              value: formatDateToYYYYMMDD(e),
                            });
                          }
                        }}
                        filterDate={date => {
                          const day = date.getDay();
                          return day !== 0 && day !== 6;
                        }}
                        portalId={'chakra-modal-impic-modal'}
                        minDate={new Date()}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('rightOfFirstRefusal.form.price')}</FormLabel>
                      <DecimalOrIntegerInput
                        color={textColor}
                        type="decimal"
                        label={t('rightOfFirstRefusal.form.price')}
                        placeholder={t('rightOfFirstRefusal.form.price')}
                        value={form.price}
                        onChange={e =>
                          dispatch({
                            type: 'setPrice',
                            value: e.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('rightOfFirstRefusal.form.currency')}</FormLabel>
                      <SelectInputInputField<RightOfFirstRefusalCurrency>
                        items={Object.values(RightOfFirstRefusalCurrency).map(i => ({
                          value: i,
                          label: t(tByRightOfFirstRefusalCurrency[i]),
                        }))}
                        value={RightOfFirstRefusalCurrency.EUROS}
                        placeholder={t('rightOfFirstRefusal.form.currency')}
                        isDisabled={true}
                        onChange={e => dispatch({ type: 'setCurrency', value: e })}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('rightOfFirstRefusal.form.comments')}</FormLabel>
                      <Textarea
                        maxLength={100}
                        value={form.comments}
                        onChange={e => dispatch({ type: 'setComments', value: e.target.value })}
                        placeholder={t('rightOfFirstRefusal.form.comments')}
                        size="sm"
                      />
                    </FormControl>

                    {environment.stage !== 'prod' && (
                      <Checkbox isChecked={isSimulateRequest} onChange={e => setIsSimulateRequest(!isSimulateRequest)}>
                        Simulate request
                      </Checkbox>
                    )}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton
              isLoading={isLoading}
              onClick={onSubmit}
              title={t('submit')}
              buttonType={AppButtonType.PRIMARY_MAIN}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
