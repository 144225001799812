import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndContext, useSensor, useSensors, PointerSensor, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { UniversalSortableItem } from './universal-sortable-item';
import { Flex } from '@chakra-ui/react';

export interface UniversalSortableListProps<T extends { id: string }> {
  isDisabled?: boolean;
  items: T[];
  onMove: (ids: string[]) => Promise<string[]>;
  renderItem: (item: T, idx: number) => React.ReactElement;
  extraItem?: React.ReactNode;
}

export const UniversalSortableList = <T extends { id: string }>({
  items: propItems,
  onMove,
  isDisabled,
  renderItem,
  extraItem,
}: UniversalSortableListProps<T>) => {
  const [items, setItems] = useState<T[]>(propItems);
  const isDraggingRef = useRef(false);

  useEffect(() => {
    if (!isDraggingRef.current) {
      setItems(propItems);
    }
  }, [propItems]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragStart = useCallback(() => {
    isDraggingRef.current = true;
  }, []);

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over || active.id === over.id) {
        isDraggingRef.current = false;
        return;
      }

      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);

      setItems(newItems);
      isDraggingRef.current = false;

      await onMove(newItems.map(item => item.id));
    },
    [items, onMove],
  );

  return (
    <Flex flexDir={'column'} gap="2px">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          disabled={isDisabled}
          items={items.map(item => item.id)}
          strategy={verticalListSortingStrategy}
        >
          {items.map((item, idx) => (
            <UniversalSortableItem key={item.id} id={item.id}>
              {renderItem(item, idx)}
            </UniversalSortableItem>
          ))}
        </SortableContext>
      </DndContext>
      {extraItem}
    </Flex>
  );
};
