import { api } from './api';

export interface Template {
  id: string;
  url: string;
  exampleUrl: string;
  name: string;
  description: string;
  changeHistory: string;
}

const templatesApi = api.injectEndpoints({
  endpoints: build => ({
    getTemplates: build.query<Template[], unknown>({ query: () => `document-template` }),
  }),
});

export const { useGetTemplatesQuery, useLazyGetTemplatesQuery, endpoints: TemplateEndpoints } = templatesApi;
