import React from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { Job, JobStatus } from 'api';
import { ApproveTick } from 'libs/ui/atoms/src';
import { Link, useMatch } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { iconByJobStatus, iconColorByJobStatus, jobStatusTranslateByJobStatus } from '../../variables/job-status';
import { useTranslation } from 'react-i18next';
import { DocumentOutFilledIcon } from '../../../../../components/icons/Icons';
import { AppButton, AppButtonType } from '../../../../../libs/ui/atoms/src/lib/appButton';
import { useTypedSelector } from 'store';

interface JobDetailsExtraDataProps {
  job: Job;
}
export function JobDetailsExtraData({ job }: JobDetailsExtraDataProps) {
  const { t } = useTranslation();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const dateTextColor = useColorModeValue('navy.750', 'white');

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const isJobPage = useMatch('/jobs/:jobId');

  return (
    <Flex direction="column" gap="8px">
      <Flex justify="space-between" gap="24px">
        {job.status && (
          <Flex justify="space-between" align="center" wrap="wrap" gap="10px">
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={iconColorByJobStatus[job.status]}
              as={iconByJobStatus[job.status]}
            />

            <Text color={textColor} fontSize="sm" fontWeight="700">
              {t(jobStatusTranslateByJobStatus[job.status])}
            </Text>
          </Flex>
        )}
        {(job.updatedAt || job.createdAt) && (
          <Text color={dateTextColor}>{formatDate(job.updatedAt || job.createdAt)}</Text>
        )}
      </Flex>
      <Flex justify={isJobWizardChecked ? 'space-between' : 'flex-end'} wrap="wrap" gap="10px">
        {isJobPage && (
          <Link to={`/jobs/${job.id}/contracts-and-parties`}>
            <AppButton
              buttonType={AppButtonType.PRIMARY}
              leftIcon={<DocumentOutFilledIcon w="24px" h="24px" />}
              isDisabled={job.status !== JobStatus['Job is completed']}
              title={t('routes.contracts&parties')}
            />
          </Link>
        )}

        <Link to={`/job-result/${job.id}`}>
          <AppButton
            buttonType={AppButtonType.PRIMARY}
            leftIcon={<ApproveTick w="24px" h="24px" />}
            isDisabled={job.status !== JobStatus['Job is completed']}
            title={t('result')}
          />
        </Link>
      </Flex>
    </Flex>
  );
}
