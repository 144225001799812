import { Flex, Icon, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { SwitchInputField } from 'libs/ui/atoms/src/lib/switch/SwitchInputField';
import { useTranslation } from 'react-i18next';
import { DocumentCheckStatus } from '../JobDetailsWidget/JobDetailsWidget';
import { useTypedSelector } from 'store';
import { selectJobDocuments } from 'store/job';
import { Job, JobDocument } from 'api';
import { MdCheck } from 'react-icons/md';

interface ReviewSettingsProps {
  job: Job;
  isGenerateReportPT: boolean;
  onGenerateReportPTToggle: (isGenerateReportPT: boolean) => void;
  isGenerateReportEN: boolean;
  onGenerateReportENToggle: (isGenerateReportEN: boolean) => void;
  checkRequiredDocumentsProvided: (documents: JobDocument[]) => DocumentCheckStatus;
}

export function ReviewSettings({
  job,
  isGenerateReportPT,
  onGenerateReportPTToggle,
  isGenerateReportEN,
  onGenerateReportENToggle,
  checkRequiredDocumentsProvided,
}: ReviewSettingsProps) {
  const { t } = useTranslation();

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const textColor = useColorModeValue('gray.600', 'white');
  const grayTextColor = useColorModeValue('gray.500', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, job.id));

  return (
    <Flex direction="column" gap="24px" bg={widgetBackgroundColor}>
      {isJobWizardChecked && (
        <Flex direction={'column'}>
          <Flex direction={'row'} justify={'space-between'}>
            <Text color={textColor} fontWeight="700" fontSize={'18px'} width="fit-content">
              {t('pages.wizard.step4.reviewSettings')}
            </Text>
          </Flex>

          <Flex width="fit-content">
            <Text color={grayTextColor} fontWeight="400" fontSize={'12px'}>
              {t('pages.wizard.step4.step4Description')}
            </Text>
          </Flex>
        </Flex>
      )}

      <Flex direction="column" gap="20px">
        {isJobWizardChecked && (
          <Flex direction="column">
            <Text color={textColor} fontWeight="700" fontSize={'18px'}>
              {t('pages.wizard.step4.finalCheck')}
            </Text>
            {checkRequiredDocumentsProvided(jobDocuments) === DocumentCheckStatus.OK && (
              <Flex direction="row" align="center" gap="18px">
                <Icon as={MdCheck} color={'green.500'} w="20px" h="20px" />
                <Text color={'green.500'} fontWeight="400" fontSize={'14px'}>
                  {t('pages.wizard.step4.reqFilesOk')}
                </Text>
              </Flex>
            )}
          </Flex>
        )}

        <Flex gap="24px" className={'hidden'} ml={isJobWizardChecked ? '' : '24px'}>
          <Text mb={'1'}>{t('pages.details.reportLanguages')}:</Text>
          <Flex gap="24px" justifyContent="start" direction={{ base: 'column', md: 'row' }}>
            <SwitchInputField
              isChecked={isGenerateReportPT}
              isDisabled={true}
              label={'PT'}
              onCheck={() => onGenerateReportPTToggle(!isGenerateReportPT)}
            />
            <SwitchInputField
              isChecked={isGenerateReportEN}
              isDisabled={false}
              label={'EN'}
              onCheck={() => onGenerateReportENToggle(!isGenerateReportEN)}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
