import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { JobProblemItem } from './JobProblemItem';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { ResolvableErrorId, useJobProblems } from './useJobsProblems';
import { CertificateRenewalAction } from 'components/certificateRenewal/CertificateRenewalModal';
import { Job } from 'api';
import { ProblemsNavigationModal } from 'views/admin/job/widgets/JobDetailsWidget/modals/ProblemsNavigationModal';
import { JobDetailsExtraData } from 'views/admin/job/widgets/JobDetailsWidget/JobDetailsExtraData';
import { useTypedSelector } from 'store';

export interface JobProblemsAccordionProps {
  job: Job;
  index?: number;
  jobId: string;
  onIndexChange: () => void;
  isAccordionExpanded: boolean;
  onAccordionExpanded: (isAccordionExpanded: boolean) => void;
  isProblemsNavigationModalToggle: boolean;
  onProblemsNavigationModalToggle: (isProblemsNavigationModalToggle: boolean) => void;
  hideExtraData: boolean;
}

export const JobProblemsAccordion = ({
  job,
  index,
  jobId,
  onIndexChange,
  isAccordionExpanded,
  onAccordionExpanded,
  isProblemsNavigationModalToggle,
  onProblemsNavigationModalToggle,
  hideExtraData,
}: JobProblemsAccordionProps) => {
  const { t } = useTranslation();

  const { problems } = useJobProblems({ jobId });

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const textColor = useColorModeValue('navy.750', 'white');
  const borderColor = useColorModeValue('red.500', 'red.500');
  const bgColor = useColorModeValue('white', 'navy.800');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  return problems.length ? (
    <Flex direction="column" gap="24px" p={'20px'} bg={widgetBackgroundColor} borderRadius={'20px'}>
      {isJobWizardChecked && (
        <Text color={textColor} fontWeight="700" fontSize={'18px'}>
          {t('pages.wizard.step5.problemRes')}
        </Text>
      )}

      <Flex direction="column" gap="20px">
        <Text fontSize={'16px'} fontWeight="bold">
          {t('pages.details.problemsThatNeedToBeSolved')}
        </Text>
        <Accordion
          index={index}
          allowMultiple
          allowToggle
          border={'1px solid'}
          borderColor={borderColor}
          borderRadius={'16px'}
          bg={bgColor}
        >
          <AccordionItem color={textColor} border={'none'}>
            <AccordionButton
              onClick={onIndexChange}
              p={'16px 24px 16px 24px'}
              _focus={{ boxShadow: 'none' }}
              _hover={{}}
            >
              <Flex flex="1" gap={'16px'} height={'50px'} alignItems={'center'}>
                <Icon w="24px" h="24px" me="5px" color={'red.500'} as={MdOutlineErrorOutline} />
                <Flex alignItems={'start'} flexDirection={'column'}>
                  <Text fontSize={'13px'} opacity={0.6}>
                    {t('pages.details.YouCanStillViewThePreviousReportByClickingTheResultButtonAbove')}
                  </Text>
                </Flex>
              </Flex>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p="0 24px 16px 24px">
              <Flex flexDir={'column'} gap={'16px'}>
                {problems.map(p => (
                  <JobProblemItem {...p} key={p.data.id}>
                    {p.data.errorId === ResolvableErrorId.R98P01 && (
                      <CertificateRenewalAction jobId={jobId} problemId={p.data.id} />
                    )}
                  </JobProblemItem>
                ))}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        {isJobWizardChecked && !hideExtraData && (
          <Box order={{ base: 0, md: 0 }}>
            <JobDetailsExtraData job={job} />
          </Box>
        )}
      </Flex>

      <ProblemsNavigationModal
        jobId={job.id}
        isOpen={isProblemsNavigationModalToggle}
        onClose={() => onProblemsNavigationModalToggle(false)}
        onResolveProblems={() => {
          onProblemsNavigationModalToggle(false);
          onAccordionExpanded(true);
        }}
      />
    </Flex>
  ) : (
    <></>
  );
};
