import { useCallback, useEffect } from 'react';
import {
  SignerCreateDto,
  SignerUpdateDto,
  useCreateSignerMutation,
  useDeleteSignerMutation,
  useLazyGetSignersQuery,
  useReorderSignersMutation,
  useUpdateSignerMutation,
} from '../../../../api/signer';

export interface UseSignerProps {
  signingId: string;
}

export const useSigners = (props: UseSignerProps) => {
  const { signingId } = props;

  const [getSigners, { data: signersBackend, isLoading }] = useLazyGetSignersQuery();
  const [createSigner] = useCreateSignerMutation();
  const [updateSigner] = useUpdateSignerMutation();
  const [deleteSigner] = useDeleteSignerMutation();
  const [reorderSigners] = useReorderSignersMutation();

  const sortedSigners = signersBackend?.slice().sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0));

  const load = useCallback(async () => {
    await getSigners(signingId);
  }, [getSigners, signingId]);

  const onCreate = useCallback(
    async (dto: Omit<SignerCreateDto, 'signingId'>) => {
      await createSigner({ ...dto, signingId }).unwrap();
      load();
    },
    [createSigner, load, signingId],
  );

  const onUpdate = useCallback(
    async (dto: SignerUpdateDto) => {
      await updateSigner(dto).unwrap();
      load();
    },
    [load, updateSigner],
  );

  const onDelete = useCallback(
    async (signerId: string) => {
      await deleteSigner(signerId).unwrap();
      load();
    },
    [deleteSigner, load],
  );

  const onReorder = useCallback(
    async (orderedIds: string[]) => {
      await reorderSigners({ signingId, orderedIds }).unwrap();
      load();
    },
    [reorderSigners, load, signingId],
  );

  useEffect(() => {
    load();
  }, [load]);

  return {
    isLoading,
    signers: sortedSigners,

    load,
    onCreate,
    onUpdate,
    onDelete,
    onReorder,
  };
};
