import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { DocumentStatus, DocumentType, Job, JobDocument, JobStatus } from 'api';
import { useJobForm } from './useJobForm';
import { useDebounce } from 'hooks/useDebounce';
import { WaitPleasePopup } from 'components/waitPleasePopup/WaitPleasePopup';
import { useTypedSelector } from 'store';
import { selectJobDocuments } from 'store/job';
import {
  DocumentsValidationErrorTypeEnum,
  ModalDocumentsValidationError,
} from '../JobDocumentsWidget/DocumentValidationModals';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { useNavigate } from 'react-router-dom';
import { JobErrorModal } from './modals/JobErrorModal';
import { ErrorModal } from '../../../../../libs/ui/atoms/src/lib/modal/ErrorModal';
import { JobWizard } from '../../job-wizard';
import { JobDefaultMode } from '../../job-default-mode';
import { JobProgress } from '../JobProgress';
import { JobUploadedDocs } from '../JobUploadedDocs';
import { JobProblemsAccordion } from 'components/jobProblems/JobProblemsAccordion';

type RequirementType = '1' | '1+' | '0' | '0+';

type RequiredDocuments = {
  [key in DocumentType]?: RequirementType;
};

const RequiredDocumentsByTypeRustica: RequiredDocuments = {
  [DocumentType.CERTIDAO_PERMANENTE]: '1',
  [DocumentType.CADERNETA_PREDIAL_RUSTICA]: '1+',
  [DocumentType.CADERNETA_PREDIAL_URBANA]: '0',
};

const RequiredDocumentsByTypeUrbana: RequiredDocuments = {
  [DocumentType.CERTIDAO_PERMANENTE]: '1',
  [DocumentType.CADERNETA_PREDIAL_URBANA]: '1',
  [DocumentType.CADERNETA_PREDIAL_RUSTICA]: '0+',
};

export enum DocumentCheckStatus {
  OK = 'OK',
  TOO_FEW_DOCUMENTS = 'TOO_FEW_DOCUMENTS',
  TOO_MANY_DOCUMENTS = 'TOO_MANY_DOCUMENTS',
}

const checkCertidaoIsValid = (jobDocuments: JobDocument[]) =>
  !jobDocuments.find(
    item => item.status === DocumentStatus.UNACCEPTABLE || item.status === DocumentStatus.PROPERTY_ID_MISMATCH,
  );

function checkRequiredDocumentsProvided(jobDocuments: JobDocument[]): DocumentCheckStatus {
  const isRustica = !jobDocuments.some(document => document.type === DocumentType.CADERNETA_PREDIAL_URBANA);
  const RequiredDocumentsByType = isRustica ? RequiredDocumentsByTypeRustica : RequiredDocumentsByTypeUrbana;

  for (const [doc, requirement] of Object.entries(RequiredDocumentsByType) as [DocumentType, RequirementType][]) {
    const count = jobDocuments.filter(jobDoc => jobDoc.type === doc).length;

    switch (requirement) {
      case '1':
        if (count < 1) return DocumentCheckStatus.TOO_FEW_DOCUMENTS;
        if (count > 1) return DocumentCheckStatus.TOO_MANY_DOCUMENTS;
        break;
      case '1+':
        if (count < 1) return DocumentCheckStatus.TOO_FEW_DOCUMENTS;
        break;
      case '0':
        if (count > 0) return DocumentCheckStatus.TOO_MANY_DOCUMENTS;
        break;
      case '0+':
        // No validation needed
        break;
    }
  }
  return DocumentCheckStatus.OK;
}
interface JobDetailsWidgetProps {
  job: Job;
  isLoadingSubmit: boolean;
  hideExtraData?: boolean;
  onUpdate: (name: string) => Promise<void>;
  onSubmit: (isGenerateReportPT: boolean, isGenerateReportEN: boolean) => Promise<void>;
  isErrorModalOpen?: boolean;
  onErrorModalClose?: () => void;
  errorMessage?: string;
  uploadedDocs: JobDocument[];
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void;
}

export function JobDetailsWidget({
  job,
  isLoadingSubmit,
  hideExtraData = false,
  onUpdate,
  onSubmit,
  isErrorModalOpen,
  onErrorModalClose,
  errorMessage,
  uploadedDocs,
  onUploadedDocs,
}: JobDetailsWidgetProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const cardBackgroundColor = useColorModeValue('_gray.200', 'navy.900');

  const [isGenerateReportEN, setGenerateReportENToggle] = useState(false);
  const [isGenerateReportPT, setGenerateReportPTToggle] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, job.id));
  const [documentModalError, setDocumentModalError] = React.useState<
    | DocumentsValidationErrorTypeEnum.RequiredDocumentsMissing
    | DocumentsValidationErrorTypeEnum.TooManyDocuments
    | DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable
    | null
  >(null);

  const { jobName, onJobNameChanged } = useJobForm(job.name);

  const debouncedName = useDebounce(jobName, { delay: 300 });

  useEffect(() => {
    const initialName = job.name ?? '';
    if (initialName === debouncedName.value) {
      return;
    }

    onUpdate(debouncedName.value);
  }, [debouncedName]);

  useEffect(() => {
    onUpdate(jobName.value);
  }, []);

  const validateSubmittedDocuments = async () => {
    if (!checkCertidaoIsValid(jobDocuments)) {
      setDocumentModalError(DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable);
      return false;
    }

    const requiredDocsValidityResult = checkRequiredDocumentsProvided(jobDocuments);

    if (requiredDocsValidityResult === DocumentCheckStatus.TOO_FEW_DOCUMENTS) {
      setDocumentModalError(DocumentsValidationErrorTypeEnum.RequiredDocumentsMissing);
      return false;
    } else if (requiredDocsValidityResult === DocumentCheckStatus.TOO_MANY_DOCUMENTS) {
      setDocumentModalError(DocumentsValidationErrorTypeEnum.TooManyDocuments);
      return false;
    }

    return true;
  };

  const onDocsSubmit = async () => {
    await onSubmit(isGenerateReportPT, isGenerateReportEN);
    return true;
  };

  useDidUpdateEffect(() => {
    if (job.status === JobStatus['Job is completed'] && !job.problems.length && job.statusMetadata?.status === 'ok') {
      navigate(`/job-result/${job.id}`);
    }
  }, [job.status]);

  const [isJobErrorModalOpen, setIsJobErrorModalOpen] = useState(false);
  const [isProblemsNavigationModalToggle, onProblemsNavigationModalToggle] = useState(false);
  const [isAccordionExpanded, onAccordionExpanded] = useState(false);

  const [prevJobStatus, setPrevJobStatus] = useState(job.status);
  useDidUpdateEffect(() => {
    if (prevJobStatus === JobStatus.Submitted && job.status !== JobStatus.Submitted) {
      if (job.problems?.length) {
        containerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        onProblemsNavigationModalToggle(true);
      }

      if (job.statusMetadata?.status !== 'ok') {
        setIsJobErrorModalOpen(true);
      }
    }

    setPrevJobStatus(job.status);
  }, [job, prevJobStatus, setPrevJobStatus, containerRef, setIsJobErrorModalOpen]);

  const ticketType = useTypedSelector(state => state.helpTools.selectedHelpTicketType);
  const isCollapsed = useTypedSelector(state => state.helpTools.isHelpTicketPanelCollapsed);
  const isOpen = !!ticketType;

  const JobProps = {
    job: job,
    hideExtraData: hideExtraData,
    jobName: jobName,
    onJobNameChanged: onJobNameChanged,
    isGenerateReportPT: isGenerateReportPT,
    onGenerateReportPTToggle: setGenerateReportPTToggle,
    isGenerateReportEN: isGenerateReportEN,
    onGenerateReportENToggle: setGenerateReportENToggle,
    validateSubmittedDocuments: validateSubmittedDocuments,
    isLoadingSubmit: isLoadingSubmit,
    isAccordionExpanded: isAccordionExpanded,
    onAccordionExpanded: onAccordionExpanded,
    isProblemsNavigationModalToggle: isProblemsNavigationModalToggle,
    onProblemsNavigationModalToggle: onProblemsNavigationModalToggle,
    uploadedDocs: uploadedDocs,
    onUploadedDocs: onUploadedDocs,
    onDocsSubmit: onDocsSubmit,
    checkRequiredDocumentsProvided: checkRequiredDocumentsProvided,
  };

  return (
    <Flex direction={{ base: 'column', xl: 'row' }} gap="24px">
      {isJobWizardChecked && (
        <Flex
          flexDirection="column"
          w="100%"
          minW="420px"
          p={0}
          bg={cardBackgroundColor}
          overflowX={'hidden'}
          position="static"
          gap="20px"
          flex="1 1 30%"
        >
          <Flex direction={{ base: 'row', xl: 'column' }} gap="20px">
            <JobProgress job={job} />

            <JobUploadedDocs job={job} uploadedDocs={uploadedDocs} />
          </Flex>

          <JobProblemsAccordion
            job={job}
            index={isAccordionExpanded ? 0 : undefined}
            jobId={job.id}
            onIndexChange={() => onAccordionExpanded(false)}
            isAccordionExpanded={isAccordionExpanded}
            onAccordionExpanded={onAccordionExpanded}
            isProblemsNavigationModalToggle={isProblemsNavigationModalToggle}
            onProblemsNavigationModalToggle={onProblemsNavigationModalToggle}
            hideExtraData={hideExtraData}
          />
        </Flex>
      )}

      <Flex ref={containerRef} direction="column" gap="24px" flex="1 1 70%">
        <Card
          flexDirection="column"
          w="100%"
          p={0}
          bg={cardBackgroundColor}
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
          position="static"
        >
          <WaitPleasePopup job={job} />

          {isJobWizardChecked ? <JobWizard {...JobProps} /> : <JobDefaultMode {...JobProps} />}
        </Card>
      </Flex>

      <ModalDocumentsValidationError
        jobId={job?.id}
        errorType={documentModalError}
        onClose={() => setDocumentModalError(null)}
      />

      {job.statusMetadata?.traceId && (
        <JobErrorModal
          isOpen={isJobErrorModalOpen}
          onClose={() => setIsJobErrorModalOpen(false)}
          traceId={job.statusMetadata?.traceId}
        />
      )}

      {isErrorModalOpen && onErrorModalClose && errorMessage && (
        <ErrorModal isOpen={isErrorModalOpen} onClose={onErrorModalClose} error={errorMessage} />
      )}
    </Flex>
  );
}
