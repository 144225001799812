import { Trans, useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import {
  createCompleteXmlObject,
  Job,
  JobMetadata,
  JobMetadataId,
  JobMetadataItem,
  JobMetadataR01X03,
  JobMetadataR01X03Item,
  RelatedParty,
  RelatedPartyRelationType,
  RightOfFirstRefusalRelatedParty,
} from 'api';
import { useImpicModalForm } from './useImpicModalForm';
import { ImpicContractPeriod, ImpicQualidadeDeclaranteNT, ImpicTransactionType } from 'api';
import { FormInputField } from '../../libs/ui/atoms/src/lib/input-field/form-input-field';
import { AppButton, AppButtonType } from '../../libs/ui/atoms/src/lib/appButton';
import { VCDatePicker } from 'libs/ui/atoms/src/lib/datePicker/DatePicker';
import { formatDateToYYYYMMDD } from 'utils/date';
import { SelectInputInputField } from 'libs/ui/atoms/src/lib/input-field/selectField';
import { tByContractPeriod, tByQualidadeDeclarante, tByTransactionType } from './utils';
import { XMLBuilder } from 'fast-xml-parser';
import { useRightOfFirstRefusalRelatedParties } from 'components/rightOfFirstRefusalModal/useRelatedPartiesRefusalForm';
import { Link } from 'react-router-dom';
import { useTypedSelector } from 'store';
import { useAppToast } from 'libs/ui/hooks';

function isJobMetadataR01X03(item: JobMetadataItem): item is JobMetadataR01X03 {
  return item.id === JobMetadataId.R01X03;
}

function extractPropertyInfo(jobMetadata: JobMetadata) {
  const R01X03Elements = jobMetadata.metadata.find((item): item is JobMetadataR01X03 => isJobMetadataR01X03(item));

  const R01X03Metadata = R01X03Elements?.metadata;

  return R01X03Metadata;
}

export interface ImpicModalProps {
  job: Job;
  isLoadingInitJobParties: boolean;
}
export const IMPICModal = ({ job, isLoadingInitJobParties }: ImpicModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { form, dispatch, validate } = useImpicModalForm();

  const { showError, showSuccess } = useAppToast();

  const { buyers, sellers, heirs, heranças, onNifChange } = useRightOfFirstRefusalRelatedParties(job.id);
  const [isHerdeirosInsteadOfHerança, setIsHerdeirosInsteadOfHerança] = useState<boolean>(true);

  const [metadata, setMetadata] = useState<JobMetadataR01X03Item>();

  const auth = useTypedSelector(state => state.auth);

  const open = useCallback(async () => {
    try {
      const propertyInfo = extractPropertyInfo(job.jobMetadata);

      const todaysDate = new Date();

      //Prefill contractYear
      const currentYear = todaysDate.getFullYear().toString();
      dispatch({ type: 'setContractYear', value: currentYear });

      //Prefill contractPeriod
      const currentMonth = todaysDate.getMonth() + 1; //month is zero based
      switch (true) {
        case [1, 2, 3].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_1 });
          break;
        case [4, 5, 6].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_2 });
          break;
        case [7, 8, 9].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_3 });
          break;
        case [10, 11, 12].includes(currentMonth):
          dispatch({ type: 'setContractPeriod', value: ImpicContractPeriod.TRIMESTER_4 });
          break;
        default:
          console.log('Invalid month');
      }

      if (propertyInfo) {
        setMetadata(propertyInfo);
      }

      dispatch({ type: 'setCodPostal1', value: propertyInfo?.codPostal1 ?? '' });
      dispatch({ type: 'setCodPostal2', value: propertyInfo?.codPostal2 ?? '' });
      dispatch({ type: 'setLocalidade', value: propertyInfo?.localidade ?? '' });

      onOpen();
    } catch (error) {
      showError(t('impic.unableToRequestImpic'));
    }
  }, [job.id, buyers, buyers.length, sellers, onOpen, t]);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', value: {} });
    onClose();
  }, [onClose, dispatch]);

  const fieldsMissing = (array: RelatedParty[], entityType: string) => {
    const hasMissingFields = array.some(relParty => {
      if (relParty.relationType === RelatedPartyRelationType.PERSON) {
        //independent buyers/sellers have missing fields
        const missingFields = [];
        if (!relParty.nacionalidade) {
          missingFields.push(t('impic.nacionalidade'));
        }
        if (!relParty.idDocumentType) {
          missingFields.push(t('impic.idDocumentType'));
        }
        if (!relParty.idDocumentNumber) {
          missingFields.push(t('impic.idDocumentNumber'));
        }
        if (!relParty.idDocumentExpiryDate) {
          missingFields.push(t('impic.idDocumentExpiryDate'));
        }

        if (missingFields.length > 0) {
          const missingFieldsMessage = missingFields.join(', ');

          showError(`${t('impic.missingFieldsTitle')} ${entityType} ${relParty.name} - ${missingFieldsMessage}`);
          return true;
        }
        return false;
      } else if (relParty.relationType === RelatedPartyRelationType.COMPANY) {
        //company doesn't have representantes
        if (!relParty.nestedRelatedParties?.length) {
          showError(`${relParty.name}: ${t('impic.missingRepresentantes')}`);
        } else if (relParty.nestedRelatedParties?.length) {
          //company's representantes have missing fields
          const repHasMissingFields = relParty.nestedRelatedParties.some(rep => {
            const missingFields = [];
            if (!rep.nacionalidade) {
              missingFields.push(t('impic.nacionalidade'));
            }
            if (!rep.idDocumentType) {
              missingFields.push(t('impic.idDocumentType'));
            }
            if (!rep.idDocumentNumber) {
              missingFields.push(t('impic.idDocumentNumber'));
            }
            if (!rep.idDocumentExpiryDate) {
              missingFields.push(t('impic.idDocumentExpiryDate'));
            }

            if (missingFields.length > 0) {
              const missingFieldsMessage = missingFields.join(', ');

              showError(
                `${t('impic.form.company')} ${relParty.name}: ${t('impic.missingFieldsTitle')} 
                ${entityType} (${rep.name}) - ${missingFieldsMessage}`,
              );
              return true;
            }
            return false;
          });
          return repHasMissingFields;
        }
      } else if (relParty.relationType === RelatedPartyRelationType.HEIR) {
        //herança doesn't have heirs
        if (!relParty.nestedRelatedParties?.length) {
          showError(`${relParty.name}: ${t('impic.missingHerdeiros')}`);
        } else if (relParty.nestedRelatedParties?.length) {
          //herança's heirs have missing fields
          const repHasMissingFields = relParty.nestedRelatedParties.some(rep => {
            const missingFields = [];
            if (!rep.nacionalidade) {
              missingFields.push(t('impic.nacionalidade'));
            }
            if (!rep.idDocumentType) {
              missingFields.push(t('impic.idDocumentType'));
            }
            if (!rep.idDocumentNumber) {
              missingFields.push(t('impic.idDocumentNumber'));
            }
            if (!rep.idDocumentExpiryDate) {
              missingFields.push(t('impic.idDocumentExpiryDate'));
            }

            if (missingFields.length > 0) {
              const missingFieldsMessage = missingFields.join(', ');

              showError(
                `${t('impic.form.inheritance')} ${relParty.name}: ${t('impic.missingFieldsTitle')} 
                ${entityType} (${rep.name}) - ${missingFieldsMessage}`,
              );
              return true;
            }
            return false;
          });
          return repHasMissingFields;
        }
      }
    });
    return hasMissingFields;
  };

  const fieldsMissingCPU = () => {
    const missingFields = [];
    if (!metadata?.codPostal1?.length && !form?.codPostal1) {
      missingFields.push(t('impic.form.codPostal1'));
    }
    if (!metadata?.codPostal2?.length && !form?.codPostal2) {
      missingFields.push(t('impic.form.codPostal2'));
    }
    if (!metadata?.localidade?.length && !form?.localidade) {
      missingFields.push(t('impic.form.localidade'));
    }

    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields.join(', ');

      showError(` ${t('impic.form.missingFieldsCPU')} - ${missingFieldsMessage}`);
      return true;
    }
    return false;
  };

  const onSubmit = useCallback(async () => {
    const validationMessages = validate();

    if (
      fieldsMissing(buyers, t('impic.form.buyers')) ||
      fieldsMissing(sellers, t('impic.form.sellers')) ||
      fieldsMissing(heranças, t('impic.form.heirs'))
    ) {
      return;
    }

    if (fieldsMissingCPU()) {
      return;
    }

    if (validationMessages.length > 0) {
      showError(validationMessages.map(i => t(i)).join(', \n'));
      return;
    }
    let finalSellers: RightOfFirstRefusalRelatedParty[] = [];
    finalSellers = [
      ...(sellers.map(i => ({ name: i.name, nif: i.nif })) as RightOfFirstRefusalRelatedParty[]),
      ...((isHerdeirosInsteadOfHerança ? heirs : heranças).map(i => ({
        name: i.name,
        nif: i.nif,
      })) as RightOfFirstRefusalRelatedParty[]),
    ];

    if (finalSellers.length > 11 || buyers.length > 11) {
      showError(t('rightOfFirstRefusal.tooManySellers'));
      return;
    }

    try {
      const xmlData = metadata ? createCompleteXmlObject(form, buyers, sellers, heirs, metadata, auth) : null;

      const builder = new XMLBuilder({
        format: true,
        suppressEmptyNode: false,
      });
      const xml = builder.build(xmlData);

      const blob = new Blob([xml], { type: 'application/xml' });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      const filename = `IMPIC${job.name}.xml`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);

      showSuccess(`${t('impic.xmlGenerated')} (${filename})`);
    } catch (error) {
      showError((error as Error).message);
    }
  }, [form, buyers, sellers, job.id, t, close, isHerdeirosInsteadOfHerança, metadata]);

  const textColor = useColorModeValue('navy.750', 'white');
  const menuItemBg = useColorModeValue('gray.50', 'whiteAlpha.200');
  const menuItemHoveBg = useColorModeValue('gray.150', 'whiteAlpha.300');
  const secondaryBg = useColorModeValue('gray.50', undefined);
  const sectionSubtitleColor = useColorModeValue('gray.500', 'whiteAlpha.600');

  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState(0);

  return (
    <>
      <Box
        cursor={'pointer'}
        borderRadius={'8px'}
        _hover={{ bg: menuItemHoveBg }}
        bg={menuItemBg}
        color={textColor}
        fontSize="14px"
        lineHeight={'14px'}
        fontWeight={500}
        p="12px"
        onClick={open}
      >
        {t('impic.itemName')}
      </Box>

      <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside" id="right-or-refusal-modal">
        <ModalOverlay />
        <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
          <ModalHeader p="0" fontSize="24px">
            {t('impic.form.title')}
          </ModalHeader>
          <ModalCloseButton right="24px" top="none" />
          <ModalBody p="24px 3px">
            <Accordion index={expandedAccordionIndex} allowMultiple={false} allowToggle>
              <AccordionItem color={textColor} bg={expandedAccordionIndex === 0 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(0)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Flex direction={'column'} alignItems="flex-start">
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('impic.form.buyers')}
                      </Text>

                      <Text fontSize={'14px'} color={sectionSubtitleColor}>
                        <Trans
                          i18nKey="impic.toEditThePartiesBelowPleaseGoToTheContractsPartiesPage"
                          components={{
                            linkToPage: (
                              <Link
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                to={`/jobs/${job.id}/contracts-and-parties`}
                                onClick={close}
                              ></Link>
                            ),
                          }}
                        ></Trans>
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  <hr />
                  <Flex py={'12px'} direction={'column'} gap="16px" maxH={'400px'} overflowY="auto">
                    {buyers.map(i => (
                      <Flex key={i.id} alignItems={'center'} gap={'24px'}>
                        <FormInputField
                          label={t('impic.form.name')}
                          value={i.name}
                          onChange={() => ({})}
                          isReadonly={true}
                        />
                        <FormInputField
                          label={t('impic.form.nifNips')}
                          onChange={value =>
                            onNifChange(i.entityType, {
                              id: i.id,
                              nif: value,
                            })
                          }
                          value={i.nif}
                          isReadonly={true}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 1 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(1)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Flex direction={'column'} alignItems="flex-start">
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('impic.form.sellers')}
                      </Text>

                      <Text fontSize={'14px'} color={sectionSubtitleColor}>
                        <Trans
                          i18nKey="impic.toEditThePartiesBelowPleaseGoToTheContractsPartiesPage"
                          components={{
                            linkToPage: (
                              <Link
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                to={`/jobs/${job.id}/contracts-and-parties`}
                                onClick={close}
                              ></Link>
                            ),
                          }}
                        ></Trans>
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  {/* {!!heirs.length && (
                    <>
                      <hr />
                      <Box py={'12px'} display={'flex'} justifyContent={'space-between'}>
                        <FormLabel mb="4px">{t('impic.form.useHerdeirosAsSellers')}?</FormLabel>
                        <Box display={'flex'}>
                          <SwitchInputField
                            isChecked={isHerdeirosInsteadOfHerança}
                            onCheck={() => {
                              setIsHerdeirosInsteadOfHerança(!isHerdeirosInsteadOfHerança);
                            }}
                          />
                        </Box>
                      </Box>
                      <hr />
                    </>
                  )} */}
                  <Flex py="12px" direction="column" gap="16px" maxH="400px" overflowY="auto">
                    <>
                      {(isHerdeirosInsteadOfHerança && heirs.length ? heirs : heranças).map(i => (
                        <Flex key={i.id} alignItems="center" gap="24px">
                          <FormInputField
                            label={t('impic.form.name')}
                            value={i.name}
                            isReadonly={true}
                            onChange={() => ({})}
                          />
                          <FormInputField
                            label={t('impic.form.nifNips')}
                            value={i.nif}
                            isReadonly={true}
                            onChange={value =>
                              onNifChange(i.entityType, {
                                id: i.id,
                                nif: value,
                              })
                            }
                          />
                        </Flex>
                      ))}
                      {sellers.map(i => (
                        <Flex key={i.id} alignItems="center" gap="24px">
                          <FormInputField
                            label={t('impic.form.name')}
                            value={i.name}
                            isReadonly={true}
                            onChange={() => ({})}
                          />
                          <FormInputField
                            label={t('impic.form.nifNips')}
                            value={i.nif}
                            isReadonly={true}
                            onChange={value =>
                              onNifChange(i.entityType, {
                                id: i.id,
                                nif: value,
                              })
                            }
                          />
                        </Flex>
                      ))}
                    </>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem color={textColor} bg={expandedAccordionIndex === 2 ? secondaryBg : undefined}>
                <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  p={'16px 24px'}
                  onClick={() => setExpandedAccordionIndex(2)}
                >
                  <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                    <Text fontSize={'18px'} fontWeight="bold">
                      {t('impic.form.transactionElements')}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel p="8px 24px 16px 24px">
                  <hr />
                  <Grid
                    py={'12px'}
                    columnGap={'24px'}
                    rowGap={'16px'}
                    gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}
                  >
                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.qualidadeNaTransacao')}</FormLabel>
                      <SelectInputInputField<ImpicQualidadeDeclaranteNT>
                        items={Object.values(ImpicQualidadeDeclaranteNT).map(i => ({
                          value: i,
                          label: t(tByQualidadeDeclarante[i]),
                        }))}
                        value={form.qualidadeDeclarante}
                        placeholder={t('impic.form.qualidadeNaTransacao')}
                        onChange={e => dispatch({ type: 'setQualidadeNaTransacao', value: e })}
                      />
                    </FormControl>

                    <FormInputField
                      label={t('impic.form.contractYear')}
                      value={form.contractYear}
                      onChange={value => dispatch({ type: 'setContractYear', value })}
                    />

                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.contractPeriod')}</FormLabel>
                      <SelectInputInputField<ImpicContractPeriod>
                        items={Object.values(ImpicContractPeriod).map(i => ({
                          value: i,
                          label: t(tByContractPeriod[i]),
                        }))}
                        value={form.contractPeriod}
                        placeholder={t('impic.form.contractPeriod')}
                        onChange={e => dispatch({ type: 'setContractPeriod', value: e })}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.transactionType')}</FormLabel>
                      <SelectInputInputField<ImpicTransactionType>
                        items={Object.values(ImpicTransactionType).map(i => ({
                          value: i,
                          label: t(tByTransactionType[i]),
                        }))}
                        value={form.transactionType}
                        placeholder={t('impic.form.transactionType')}
                        onChange={e => dispatch({ type: 'setTransactionType', value: e })}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel mb="4px">{t('impic.form.transactionDate')}</FormLabel>
                      <VCDatePicker
                        placeholder={t('impic.form.transactionDate')}
                        selectedValue={form.transactionDate ? new Date(form.transactionDate) : undefined}
                        onChange={e => {
                          if (e) {
                            dispatch({
                              type: 'setTransactionDate',
                              value: formatDateToYYYYMMDD(e),
                            });
                          }
                        }}
                        portalId={'chakra-modal-right-or-refusal-modal'}
                        maxDate={new Date()}
                      />
                    </FormControl>

                    <FormControl>
                      <FormInputField
                        type="number"
                        label={t('impic.form.property.value') + ' €'}
                        value={form.propertyValue}
                        onChange={value => dispatch({ type: 'setPropertyValue', value })}
                      />
                    </FormControl>
                  </Grid>
                </AccordionPanel>
              </AccordionItem>

              {(!metadata?.codPostal1.length || !metadata?.codPostal2.length || !metadata?.localidade.length) && (
                <AccordionItem color={textColor} bg={expandedAccordionIndex === 3 ? secondaryBg : undefined}>
                  <AccordionButton
                    _focus={{ boxShadow: 'none' }}
                    p={'16px 24px'}
                    onClick={() => setExpandedAccordionIndex(3)}
                  >
                    <Flex flex="1" gap={'16px'} height={'32px'} alignItems={'center'}>
                      <Text fontSize={'18px'} fontWeight="bold">
                        {t('impic.form.missingFieldsCPU')}
                      </Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel p="8px 24px 16px 24px">
                    <hr />
                    <Grid
                      py={'12px'}
                      columnGap={'24px'}
                      rowGap={'16px'}
                      gridTemplateColumns={{ sm: '1fr', md: '1fr 0.01fr 1fr 2fr' }}
                      alignItems={'center'}
                    >
                      <FormControl>
                        <FormInputField
                          type="number"
                          label={t('impic.form.codPostal1')}
                          value={form.codPostal1}
                          onChange={value => dispatch({ type: 'setCodPostal1', value })}
                          maxLength={4}
                        />
                      </FormControl>
                      <Text fontWeight="bold" fontSize="xl" mt="16px">
                        -
                      </Text>
                      <FormControl>
                        <FormInputField
                          type="number"
                          label={t('impic.form.codPostal2')}
                          value={form.codPostal2}
                          onChange={value => dispatch({ type: 'setCodPostal2', value })}
                          maxLength={3}
                        />
                      </FormControl>
                      <FormControl>
                        <FormInputField
                          label={t('impic.form.localidade')}
                          value={form.localidade}
                          onChange={value => dispatch({ type: 'setLocalidade', value })}
                        />
                      </FormControl>
                    </Grid>
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </ModalBody>

          <ModalFooter justifyContent="center" gap="24px" p="0">
            <AppButton
              isLoading={isLoadingInitJobParties}
              onClick={onSubmit}
              title={t('submit')}
              buttonType={AppButtonType.PRIMARY_MAIN}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
