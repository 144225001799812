import React, { useMemo } from 'react';
import { Box, Button, Flex, Stack, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { Collapse, Mortarboard, Plus } from 'libs/ui/atoms/src';
import { environment } from '../../../environments/environment';
import { AppButton, AppButtonType } from '../../../libs/ui/atoms/src/lib/appButton';
import { jobWizardStepChanged } from 'store/jobWizard';
import { useAppDispatch } from 'store';

interface SidebarContentProps {
  routes: RoutesType[];
  isCollapsed: boolean;
  isCollapsable: boolean;
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
  onCollapseClick: () => void;
}

export function SidebarContent(props: SidebarContentProps) {
  const { routes, onClickButton } = props;
  const dispatch = useAppDispatch();
  const routesFiltered = useMemo(() => {
    return routes.filter(r => !r.hideInSideMenu && !r.isHidden);
  }, [routes]);

  const { t } = useTranslation();

  const collapseColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  const collapseBg = useColorModeValue('white', 'navy.800');

  const handleClick = () => {
    dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: 1 }));
  };

  // SIDEBAR
  return (
    <Flex direction="column" height="100%" borderRadius="30px">
      <Brand isCollapsed={props.isCollapsed} />

      <Flex p="32px 0px 20px 16px" direction="column" alignItems={'center'} w={'calc(100% - 16px)'}>
        <AppButton
          borderRadius="100px"
          buttonType={AppButtonType.MAIN}
          p={props.isCollapsed ? '0' : '10px 75px'}
          pl={props.isCollapsed ? '16px' : undefined}
          pr={props.isCollapsed ? '10px' : undefined}
          onClick={onClickButton}
          leftIcon={props.isCollapsed ? <Plus boxSize={4} /> : undefined}
          title={!props.isCollapsed ? t('sidebar.createJob') : undefined}
        />
      </Flex>

      <Stack flex={1} direction="column">
        <Box p="0 32px 32px 32px" pe={{ lg: '16px', '2xl': '16px' }} onClick={handleClick}>
          <Flex direction="column" gap="24px">
            <Links routes={routesFiltered} isCollapsed={props.isCollapsed} />
          </Flex>
        </Box>
      </Stack>

      <Flex
        p="0px 0px 0px 16px"
        marginBottom={'16px'}
        direction="column"
        alignItems={'center'}
        w={'calc(100% - 16px)'}
        gap="16px"
      >
        <Tooltip label={props.isCollapsed ? t('veriKnows') : ''}>
          <Button
            p="0"
            borderRadius="100px"
            pl={props.isCollapsed ? '8px' : '56px'}
            pr={props.isCollapsed ? '0px' : '56px'}
            bg={collapseBg}
            color={collapseColor}
            leftIcon={<Mortarboard w="20px" h="20px" />}
            title={t('veriKnows')}
            onClick={() => window.open(environment.veriKnowsUrl, '_blank')}
          >
            {!props.isCollapsed && t('veriKnows')}
          </Button>
        </Tooltip>

        {props.isCollapsable && (
          <Button
            p="0"
            borderRadius="100px"
            pl={props.isCollapsed ? '8px' : '56px'}
            pr={props.isCollapsed ? '0px' : '56px'}
            bg={collapseBg}
            color={collapseColor}
            leftIcon={<Collapse boxSize={4} />}
            title={t('sidebar.collapseMenu')}
            onClick={props.onCollapseClick}
          >
            {!props.isCollapsed && t('sidebar.collapseMenu')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
