import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WizardPageState {
  isChecked: boolean;
  step: number;
}

export interface WizardState {
  jobWizard: WizardPageState;
}

const initialState: WizardState = {
  jobWizard: {
    isChecked: false,
    step: 1,
  },
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    jobWizardChecked: (state, action: PayloadAction<{ wizardKey: keyof WizardState; isChecked: boolean }>) => {
      const { wizardKey, isChecked } = action.payload;
      state[wizardKey].isChecked = isChecked;
    },
    jobWizardStepChanged: (state, action: PayloadAction<{ wizardKey: keyof WizardState; step: number }>) => {
      const { wizardKey, step } = action.payload;
      state[wizardKey].step = step;
    },
  },
});

export const { jobWizardChecked, jobWizardStepChanged } = wizardSlice.actions;

export const { reducer: wizardReducer, reducerPath: wizardReducerPath } = wizardSlice;
