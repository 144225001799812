import { useTranslation } from 'react-i18next';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { JobDocumentsWidget } from '../JobDocumentsWidget/JobDocumentsWidget';
import { Job, JobDocument } from 'api';
import { DocumentsValidationErrorTypeEnum } from './DocumentValidationModals/ModalValidationError';
import { useTypedSelector } from 'store';

export interface JobDocumentsStepProps {
  job: Job;
  documentsValidationError: DocumentsValidationErrorTypeEnum | null;
  onDocumentsValidationError: React.Dispatch<React.SetStateAction<DocumentsValidationErrorTypeEnum | null>>;
  isShowUserSelectedUnsupportedTypeModal: boolean;
  onShowUserSelectedUnsupportedTypeModal: (isShowUserSelectedUnsupportedTypeModal: boolean) => void;
  addDocumentsToPolling: (documents: JobDocument[]) => void;
  uploadedDocs: JobDocument[];
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void;
}

export function JobDocumentsStep({
  job,
  documentsValidationError,
  onDocumentsValidationError,
  isShowUserSelectedUnsupportedTypeModal,
  onShowUserSelectedUnsupportedTypeModal,
  addDocumentsToPolling,
  uploadedDocs,
  onUploadedDocs,
}: JobDocumentsStepProps) {
  const { t } = useTranslation();

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);

  const textColor = useColorModeValue('gray.600', 'white');
  const grayTextColor = useColorModeValue('gray.500', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  return (
    <Flex direction="column" gap="20px" bg={widgetBackgroundColor}>
      {isJobWizardChecked && (
        <Flex direction={'column'}>
          <Flex direction={'row'} justify={'space-between'} gap="20px">
            <Text color={textColor} fontWeight="700" fontSize={'18px'} width="fit-content">
              {t('pages.wizard.step2.uploadFiles')}
            </Text>
          </Flex>

          <Flex width="fit-content">
            <Text color={grayTextColor} fontWeight="400" fontSize={'12px'}>
              {t('pages.wizard.step2.step2Description')}
            </Text>
          </Flex>
        </Flex>
      )}

      <JobDocumentsWidget
        jobId={job.id}
        documentsValidationError={documentsValidationError}
        onDocumentsValidationError={onDocumentsValidationError}
        isShowUserSelectedUnsupportedTypeModal={isShowUserSelectedUnsupportedTypeModal}
        onShowUserSelectedUnsupportedTypeModal={onShowUserSelectedUnsupportedTypeModal}
        addDocumentsToPolling={addDocumentsToPolling}
        uploadedDocs={uploadedDocs}
        onUploadedDocs={onUploadedDocs}
      />
    </Flex>
  );
}
