import { ImpicContractPeriod, ImpicDto, ImpicQualidadeDeclaranteNT, ImpicTransactionType } from 'api';
import { useCallback, useReducer } from 'react';

type SetInitialStateAction = { type: 'setInitialState'; value: ImpicFormState };
type SetQualidadeNaTransacaoAction = { type: 'setQualidadeNaTransacao'; value: ImpicQualidadeDeclaranteNT };
type SetContractYearAction = { type: 'setContractYear'; value: string };
type SetContractPeriodAction = { type: 'setContractPeriod'; value: ImpicContractPeriod };
type SetTransactionTypeAction = { type: 'setTransactionType'; value: ImpicTransactionType };
type SetTransactionDateAction = { type: 'setTransactionDate'; value: string };
type SetPropertyValue = { type: 'setPropertyValue'; value: string };
type SetPropertyNumber = { type: 'setPropertyNumber'; value: string };
type SetQuotaParteImoveis = { type: 'setQuotaParteImoveis'; value: string };
type SetValorRendaIncluiDespEnc = { type: 'setValorRendaIncluiDespEnc'; value: string };
type SetCodPostal1 = { type: 'setCodPostal1'; value: string };
type SetCodPostal2 = { type: 'setCodPostal2'; value: string };
type SetLocalidade = { type: 'setLocalidade'; value: string };

type ImpicFormAction =
  | SetInitialStateAction
  | SetQualidadeNaTransacaoAction
  | SetContractYearAction
  | SetContractPeriodAction
  | SetTransactionTypeAction
  | SetTransactionDateAction
  | SetPropertyValue
  | SetPropertyNumber
  | SetQuotaParteImoveis
  | SetValorRendaIncluiDespEnc
  | SetCodPostal1
  | SetCodPostal2
  | SetLocalidade;

export type ImpicFormState = Omit<Partial<ImpicDto>, 'jobId'> & {
  id?: string;
};

export function useImpicModalForm() {
  const [form, dispatch] = useReducer((state: ImpicFormState, action: ImpicFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return {
          ...state,
          ...action.value,
        };

      case 'setQualidadeNaTransacao': {
        return { ...state, qualidadeDeclarante: action.value };
      }

      case 'setContractYear': {
        return { ...state, contractYear: action.value };
      }

      case 'setContractPeriod': {
        return { ...state, contractPeriod: action.value };
      }

      case 'setTransactionType': {
        return { ...state, transactionType: action.value };
      }

      case 'setTransactionDate': {
        return { ...state, transactionDate: action.value };
      }

      case 'setPropertyValue': {
        return { ...state, propertyValue: action.value };
      }

      case 'setPropertyNumber': {
        return { ...state, propertyNumber: action.value };
      }

      case 'setQuotaParteImoveis': {
        return { ...state, quotaParteImoveis: action.value };
      }

      case 'setValorRendaIncluiDespEnc': {
        return { ...state, valorRendaIncluiDespEnc: action.value };
      }

      case 'setCodPostal1': {
        return { ...state, codPostal1: action.value };
      }

      case 'setCodPostal2': {
        return { ...state, codPostal2: action.value };
      }

      case 'setLocalidade': {
        return { ...state, localidade: action.value };
      }

      default:
        return state;
    }
  }, {});

  const validate = useCallback<() => string[]>(() => {
    const errorMsgs = [];
    if (!form.qualidadeDeclarante) {
      errorMsgs.push('qualidadeNaTransacaoIsRequired');
    }
    if (!form.contractYear) {
      errorMsgs.push('contractYearIsRequired');
    }
    if (!form.contractPeriod) {
      errorMsgs.push('contractPeriodIsRequired');
    }
    if (!form.transactionType) {
      errorMsgs.push('transactionTypeIsRequired');
    }
    if (!form.transactionDate) {
      errorMsgs.push('transactionDateIsRequired');
    }
    if (!form.propertyValue) {
      errorMsgs.push('propertyValueRequired');
    }
    if (!form.codPostal1) {
      errorMsgs.push('codPostal1Required');
    }
    if (!form.codPostal2) {
      errorMsgs.push('codPostal2Required');
    }
    if (!form.localidade) {
      errorMsgs.push('localidadeRequired');
    }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate };
}
