import { Flex, Spinner, Text } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from 'store';
import { selectJob } from 'store/job';
import { useCreateJobMutation, useSubmitJobMutation, useUpdateJobMutation, JobDocument } from '../../../api';
import { JobDetailsWidget } from './widgets/JobDetailsWidget/JobDetailsWidget';
import { jobWizardStepChanged } from 'store/jobWizard';

export function JobAddPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [createJob, { isLoading, error, data }] = useCreateJobMutation();

  React.useEffect(() => {
    dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: 1 }));
    createJob({});
  }, []);

  const job = useTypedSelector(state => (data?.id ? selectJob(state, data.id) : null));

  const [submitJob, { isLoading: isLoadingSubmit, error: errorSubmit }] = useSubmitJobMutation();
  const [updateJob] = useUpdateJobMutation();

  const [uploadedDocs, onUploadedDocs] = useState<JobDocument[]>([]);

  const navigateToJobDetails = () => {
    if (!job) {
      console.error('Invalid application state! Not found job to navigate.');
      return;
    }
    navigate(`/jobs/${job.id}`);
  };

  const handleSubmit = useCallback(
    async (isGenerateReportPT: boolean, isGenerateReportEN: boolean) => {
      console.debug('Handle submit');
      if (!job) {
        console.error('Invalid application state! Not found job to submit.');
        return;
      }

      await submitJob({
        jobId: job.id,
        isGenerateReportPT: isGenerateReportPT,
        isGenerateReportEN: isGenerateReportEN,
      });

      navigateToJobDetails();
    },
    [job, submitJob],
  );

  const handleUpdate = useCallback(
    async (name: string) => {
      console.debug('Handle update');
      if (!job) {
        console.error('Invalid application state! Not found job to update.');
        return;
      }
      await updateJob({ id: job.id, name });

      navigateToJobDetails();
    },
    [job, updateJob],
  );

  if (isLoading) {
    return (
      <Flex align="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (error || !job) {
    return (
      <Flex align="center" justifyContent="center">
        <Text>{error ? 'Something went wrong: ' + (error as any).data?.message : 'Not found job'}</Text>
      </Flex>
    );
  }

  return (
    <JobDetailsWidget
      job={job}
      onSubmit={handleSubmit}
      onUpdate={handleUpdate}
      isLoadingSubmit={isLoadingSubmit}
      hideExtraData
      uploadedDocs={uploadedDocs}
      onUploadedDocs={onUploadedDocs}
    />
  );
}
