import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EntityType,
  RelatedPartyDocumentExtractType,
  RelatedPartyEntityType,
  RelatedPartyRelationType,
  useCreateRelatedPartyMutation,
  useCreateUniversalDocumentMutation,
  useRelatedPartyClearSubmissionMutation,
  useRelatedPartySubmitMutation,
  useUploadUniversalDocumentMutation,
} from '../../../../../../api';
import { useAppToast } from '../../../../../../libs/ui/hooks';
import { getInvalidSizedFiles } from '../../../../../../utils';

export interface ExtractPartyProps {
  jobId: string;
  entityType: RelatedPartyEntityType;
  relatedPartyId?: string;
  parentRelatedPartyId?: string;

  isUpdateData?: boolean;

  onFinished: () => void;
  onFilesDropped?: () => void;
}

export const useExtractParty = ({
  jobId,
  entityType,
  relatedPartyId,
  parentRelatedPartyId,
  isUpdateData,
  onFinished,
  onFilesDropped,
}: ExtractPartyProps) => {
  const { showError, showWarning, showApiError } = useAppToast();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [documentsType, setDocumentsType] = useState<RelatedPartyDocumentExtractType>(
    RelatedPartyDocumentExtractType.ID_CARD,
  );

  const [createRelatedParty] = useCreateRelatedPartyMutation();
  const [createDocument] = useCreateUniversalDocumentMutation();
  const [uploadDocument] = useUploadUniversalDocumentMutation();
  const [submitRelatedParty] = useRelatedPartySubmitMutation();
  const [clearSubmission] = useRelatedPartyClearSubmissionMutation();

  const processNewItem = useCallback(
    async (filesToUpload: File[]) => {
      const relatedParty = await createRelatedParty({
        jobId,
        entityType,
        relatedPartyId: parentRelatedPartyId,
        relationType: RelatedPartyRelationType.PERSON,
      }).unwrap();

      await Promise.all(
        filesToUpload.map(async file => {
          const document = await createDocument({
            name: file.name,
            entityType: EntityType.RELATED_PARTY,
            entityId: relatedParty.id,
          }).unwrap();

          const formData = new FormData();
          formData.set('inputFile', file);

          return uploadDocument({ id: document.id, formData }).unwrap();
        }),
      );

      await submitRelatedParty({
        id: relatedParty.id,
        submitType: documentsType,
      }).unwrap();
    },
    [
      createDocument,
      createRelatedParty,
      documentsType,
      entityType,
      jobId,
      parentRelatedPartyId,
      submitRelatedParty,
      uploadDocument,
    ],
  );

  const onResetFiles = useCallback(() => setFiles([]), [setFiles]);

  const processUpdateItem = useCallback(
    async (filesToUpload: File[]) => {
      if (!relatedPartyId) {
        return;
      }

      await clearSubmission({ id: relatedPartyId }).unwrap();

      await Promise.all(
        filesToUpload.map(async file => {
          const document = await createDocument({
            name: file.name,
            entityType: EntityType.RELATED_PARTY,
            entityId: relatedPartyId,
          }).unwrap();

          const formData = new FormData();
          formData.set('inputFile', file);

          return uploadDocument({ id: document.id, formData }).unwrap();
        }),
      );

      await submitRelatedParty({
        id: relatedPartyId,
        submitType: documentsType,
      }).unwrap();
    },
    [clearSubmission, createDocument, documentsType, relatedPartyId, submitRelatedParty, uploadDocument],
  );

  const onSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      if (isUpdateData && documentsType === RelatedPartyDocumentExtractType.HERANCA) {
        return;
      }

      if (
        documentsType === RelatedPartyDocumentExtractType.HERANCA ||
        documentsType === RelatedPartyDocumentExtractType.ID_PASSPORT
      ) {
        if (isUpdateData) {
          await processUpdateItem(files);
        } else {
          await Promise.all(files.map(file => processNewItem([file])));
        }
      }

      if (
        documentsType === RelatedPartyDocumentExtractType.ID_CARD ||
        documentsType === RelatedPartyDocumentExtractType.ID_RESIDENCE_PERMIT
      ) {
        if (files.length > 2) {
          showWarning(t('relatedParties.extract.maxFilesError'), 3000);
          return;
        }
        if (isUpdateData) {
          await processUpdateItem(files);
        } else {
          await processNewItem(files);
        }
      }

      onFinished();
      onResetFiles();
    } catch (error) {
      console.error('Failed to submit', error);
      showApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    isUpdateData,
    documentsType,
    onFinished,
    onResetFiles,
    processUpdateItem,
    files,
    processNewItem,
    showWarning,
    t,
    showApiError,
  ]);

  const onUploadFiles = useCallback(
    async (uploadedFiles: File[]) => {
      const tooBigFiles = getInvalidSizedFiles(uploadedFiles);
      if (tooBigFiles.length) {
        showError(t(`error.filesTooBig`, { filenames: tooBigFiles.map(i => `"${i.name}"`).join(', ') }));
        return;
      }

      setFiles([...files, ...uploadedFiles]);
      onFilesDropped?.();
    },
    [files, onFilesDropped, showError, t],
  );

  const onDeleteFile = useCallback(async (file: File) => setFiles(files.filter(f => f !== file)), [files, setFiles]);

  const isDisabled =
    !files.length ||
    isLoading ||
    ((documentsType === RelatedPartyDocumentExtractType.ID_CARD ||
      documentsType === RelatedPartyDocumentExtractType.ID_RESIDENCE_PERMIT) &&
      files.length > 2);

  return {
    isLoading,
    isDisabled,
    files,
    documentsType,
    onDocumentTypeChange: setDocumentsType,
    onSubmit,
    onUploadFiles,
    onDeleteFile,
    onResetFiles,
  };
};
