import { ImpicFormState } from 'components/impic/useImpicModalForm';
import { api } from './api';
import { JobMetadataR01X03Item, RelatedParty, RelatedPartyRelationType } from 'api';
import { AuthState } from 'store/auth';
import { countryAlphaToNumeric, EUCountryCodes } from 'libs/ui/atoms/src/lib/input-field/countries';
import { formatDateToYYYYMMDD } from 'utils/date';

//Transação
export enum ImpicQualidadeDeclaranteNT {
  MEDIACAO_IMOBILIARIA = 'MI',
  COMPRA_VENDA_COMPRA_PARA_REVENDA_PERMUTA_IMOVEIS = 'CV',
  // PROMOCAO_IMOBILIARIA = 'PI',
  // ARRENDAMENTO = 'A',
  // OUTRA_ATIVIDADE_IMOBILIARIA = 'OAI',
}

export enum ImpicContractPeriod {
  SEMESTER_1 = '1',
  SEMESTER_2 = '2',
  TRIMESTER_1 = '3',
  TRIMESTER_2 = '4',
  TRIMESTER_3 = '5',
  TRIMESTER_4 = '6',
}

export enum ImpicTransactionType {
  COMPRA = 'C',
  VENDA = 'V',
  PERMUTA = 'P',
  ARRENDAMENTO = 'A',
}

//Declarante
export enum QualidadeDeclarantePessResp {
  AD = 'Advogado',
  FU = 'Funcionário',
  GE = 'Gerente',
  PR = 'Procurador',
  RL = 'Representante legal',
  RN = 'Responsável pelo cumprimento normativo',
  SO = 'Solicitador',
  OU = 'Outro',
}

//Imovel
export enum HabitacaoPropriaPermanente {
  S = 'Sim',
  N = 'Não',
  NS = 'Não Sei',
}

enum TipoDocumento {
  CC = 'Cartão de Cidadão',
  PA = 'Passaporte',
  TR = 'Título de Residência',
}
export interface ImpicData {
  id: string;

  qualidadeDeclarante: ImpicQualidadeDeclaranteNT;
  contractYear: string;
  contractPeriod: ImpicContractPeriod;
  transactionType: ImpicTransactionType;
  transactionDate: string;

  propertyValue: string;
  propertyNumber: string;
  quotaParteImoveis: string;
  valorRendaIncluiDespEnc: string;

  codPostal1?: string;
  codPostal2?: string;
  localidade?: string;

  simulateRequest?: boolean;
}

export interface ImpicDto {
  jobId: string;

  qualidadeDeclarante: ImpicQualidadeDeclaranteNT;
  contractYear: string;
  contractPeriod: ImpicContractPeriod;
  transactionType: ImpicTransactionType;
  transactionDate: string;

  propertyValue: string;
  propertyNumber: string;
  quotaParteImoveis: string;
  valorRendaIncluiDespEnc: string;

  codPostal1?: string;
  codPostal2?: string;
  localidade?: string;

  simulateRequest?: boolean;
}

export interface ImpicResult {
  success: boolean;
}

interface Representante {
  nifnipc: string;
  nomeRepresentante: string;
  documento: string;
  numero: string;
  tituloRepresentativo: string;
  tituloResidencia: string;
  cedulaProfissional: string;
  docQual: string;
  docDataValidade: string;
  docVitalicio: string;
}

interface TransactionParty {
  nifnipc: string;
  nome: string;
  nacionalidade: string;
  pais: string;
  TipoDocumento: string;
  tituloResidencia: string;
  numeroDocumento: string;
  dataDocumento: string;
  qualDocumento: string;
  docVitalicioDocumento: string;
  docQualDocumento: string;
  representante?: Representante[];
}

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-');

  return `${day}-${month}-${year}`;
};

function getTipoDocumentoID(tipoDocumento: string): string | undefined {
  return Object.entries(TipoDocumento).find(([key, value]) => value === tipoDocumento)?.[0];
}

export const createCompleteXmlObject = (
  form: ImpicFormState,
  buyers: RelatedParty[],
  sellers: RelatedParty[],
  heirs: RelatedParty[],
  metadata: JobMetadataR01X03Item,
  auth: AuthState,
) => ({
  declaracao: {
    compradorList: {
      comprador: buyers.map(buyer => {
        const baseBuyer: TransactionParty = {
          nome: buyer.name || '',
          nifnipc: buyer.nif || '',
          nacionalidade:
            (buyer.nacionalidade && EUCountryCodes.includes(buyer.nacionalidade)) ||
            buyer.relationType === RelatedPartyRelationType.COMPANY
              ? 'UE'
              : 'NUE',
          pais: buyer.nacionalidade
            ? countryAlphaToNumeric[buyer.nacionalidade]
            : buyer.relationType === RelatedPartyRelationType.COMPANY
              ? countryAlphaToNumeric['PRT']
              : '',
          TipoDocumento:
            buyer.relationType === RelatedPartyRelationType.COMPANY &&
            buyer.nestedRelatedParties &&
            buyer.nestedRelatedParties[0].idDocumentType
              ? getTipoDocumentoID(buyer.nestedRelatedParties[0].idDocumentType) || ''
              : (buyer.idDocumentType && getTipoDocumentoID(buyer.idDocumentType)) || '',
          tituloResidencia: '',
          numeroDocumento:
            buyer.relationType === RelatedPartyRelationType.COMPANY &&
            buyer.nestedRelatedParties &&
            buyer.nestedRelatedParties[0].idDocumentNumber
              ? buyer.nestedRelatedParties[0].idDocumentNumber
              : buyer.idDocumentNumber || '',
          dataDocumento:
            buyer.relationType === RelatedPartyRelationType.COMPANY &&
            buyer.nestedRelatedParties &&
            buyer.nestedRelatedParties[0].idDocumentExpiryDate
              ? formatDate(formatDateToYYYYMMDD(new Date(buyer.nestedRelatedParties[0].idDocumentExpiryDate)))
              : (buyer.idDocumentExpiryDate &&
                  formatDate(formatDateToYYYYMMDD(new Date(buyer.idDocumentExpiryDate)))) ||
                '',
          qualDocumento: '',
          docVitalicioDocumento: '',
          docQualDocumento: '',
        };

        let representantes: Representante[] = [];
        if (buyer.relationType === RelatedPartyRelationType.COMPANY && buyer?.nestedRelatedParties) {
          representantes = buyer.nestedRelatedParties.map(rep => ({
            nifnipc: rep.nif || '',
            nomeRepresentante: rep.name || '',
            documento: (rep.idDocumentType && getTipoDocumentoID(rep.idDocumentType)) || '',
            numero: rep.idDocumentNumber || '',
            tituloRepresentativo: 'G',
            tituloResidencia: (rep.idDocumentType as string) === 'TR' ? 'T' : '',
            cedulaProfissional: '',
            docQual: '',
            docDataValidade:
              (rep.idDocumentExpiryDate && formatDate(formatDateToYYYYMMDD(new Date(rep.idDocumentExpiryDate)))) || '',
            docVitalicio: '',
          }));
        }
        return {
          ...baseBuyer,
          representante: representantes.length > 0 ? representantes : undefined,
        };
      }),
    },
    vendedorList: {
      vendedor: [...sellers, ...heirs].map(seller => {
        const baseSeller: TransactionParty = {
          nome: seller.name || '',
          nifnipc: seller.nif || '',
          nacionalidade:
            (seller.nacionalidade && EUCountryCodes.includes(seller.nacionalidade)) ||
            seller.relationType === RelatedPartyRelationType.COMPANY
              ? 'UE'
              : 'NUE',
          pais: seller.nacionalidade
            ? countryAlphaToNumeric[seller.nacionalidade]
            : seller.relationType === RelatedPartyRelationType.COMPANY
              ? countryAlphaToNumeric['PRT']
              : '',
          TipoDocumento:
            seller.relationType === RelatedPartyRelationType.COMPANY &&
            seller.nestedRelatedParties &&
            seller.nestedRelatedParties[0].idDocumentType
              ? getTipoDocumentoID(seller.nestedRelatedParties[0].idDocumentType) || ''
              : (seller.idDocumentType && getTipoDocumentoID(seller.idDocumentType)) || '',
          tituloResidencia: '',
          numeroDocumento:
            seller.relationType === RelatedPartyRelationType.COMPANY &&
            seller.nestedRelatedParties &&
            seller.nestedRelatedParties[0].idDocumentNumber
              ? seller.nestedRelatedParties[0].idDocumentNumber
              : seller.idDocumentNumber || '',
          dataDocumento:
            seller.relationType === RelatedPartyRelationType.COMPANY &&
            seller.nestedRelatedParties &&
            seller.nestedRelatedParties[0].idDocumentExpiryDate
              ? formatDate(formatDateToYYYYMMDD(new Date(seller.nestedRelatedParties[0].idDocumentExpiryDate)))
              : (seller.idDocumentExpiryDate &&
                  formatDate(formatDateToYYYYMMDD(new Date(seller.idDocumentExpiryDate)))) ||
                '',
          qualDocumento: '',
          docVitalicioDocumento: '',
          docQualDocumento: '',
        };

        let representantes: Representante[] = [];
        if (seller.relationType === RelatedPartyRelationType.COMPANY && seller?.nestedRelatedParties) {
          representantes = seller.nestedRelatedParties.map(rep => ({
            nifnipc: rep.nif || '',
            nomeRepresentante: rep.name || '',
            documento: (rep.idDocumentType && getTipoDocumentoID(rep.idDocumentType)) || '',
            numero: rep.idDocumentNumber || '',
            tituloRepresentativo: 'G',
            tituloResidencia: (rep.idDocumentType as string) === 'TR' ? 'T' : '',
            cedulaProfissional: '',
            docQual: '',
            docDataValidade:
              (rep.idDocumentExpiryDate && formatDate(formatDateToYYYYMMDD(new Date(rep.idDocumentExpiryDate)))) || '',
            docVitalicio: '',
          }));
        }
        return {
          ...baseSeller,
          representante: representantes.length > 0 ? representantes : undefined,
        };
      }),
    },
    transaccao: {
      qualidadeDeclaranteNT: form.qualidadeDeclarante,
      ano: form.contractYear,
      semestre: form.contractPeriod,
      tipoTransaccao: form.transactionType,
      numeroImoveisJuridico: '1',
      numeroImoveis: '1',
      numeroQuotaParte: '0',
      dataTransaccao: form.transactionDate && formatDate(form.transactionDate),
      arrendamentoNumeroContrato: '',
      arrendamentoTipoContrato: '',
      arrendamentoPrazo: '',
      arrendamentoDataInicio: '',
      arrendamentoDataTermino: '',
      montante: form.propertyValue,
      quantidadeNumerario: '',
      valorTotalNumerarios: '',
      moedaUtilizada: '',
      quantidadeCheques: '',
      valorTotalCheques: '',
      entidadeSacadaCheque: '',
      numeroDosCheques: '',
      quantidadeLetras: '',
      valorTotalLetras: '',
      entidadeSacadaLetra: '',
      quantidadeTB: '',
      valorTotalTB: '',
      numeroContasPagamentoBeneficiariosTransferenciaBancaria: '',
      numeroContasPagamentoOrdenantesTransferenciaBancaria: '',
      qual: '',
      quantidadeOMP: '',
      valorTotalOMP: '',
      quantidadeCartaoPagamento: '',
      valorTotalCartaoPagamento: '',
      numeroContasPagamentoBeneficiariosCartaoPagamento: '',
      numeroContasPagamentoOrdenantesCartaoPagamento: '',
      valorTotalIP: '',
    },
    imovelList: {
      imovel: {
        valorImovel: form.propertyValue,
        numeroImovel: '1',
        quotaParteImoveis: '',
        parteImovelArrendado: '',
        valorRendaIncluiDespEnc: '',

        ...metadata,
        codPostal1: metadata.codPostal1 ? metadata.codPostal1 : form.codPostal1,
        codPostal2: metadata.codPostal1 ? metadata.codPostal2 : form.codPostal2,
        localidade: metadata.localidade ? metadata.localidade : form.localidade,
      },
    },
    declarante: {
      qualidadeDeclarantePessResp: 'FU',
      qualidadeDeclarantePessRespQual: '',
      NIF: auth?.user?.nifNips || '',
      nome: auth?.user?.name || '',
      numeroDocumento: '',
      dataValidade: '',
      vitalicio: '',
      documento: '',
      tituloResidencia: '',
      cedulaProfissional: '',
    },
  },
});
