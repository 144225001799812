import * as React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DocumentStatus, Job, JobDocument } from '../../../api';
import { JobMainInfoWidget } from './components/job-main-info';
import { JobDocumentsStep } from './widgets/JobDocumentsWidget/JobDocumentsStep';
import { JobCategorizeFiles } from './widgets/JobDocumentsWidget/JobCategorizeFiles';
import { useTranslation } from 'react-i18next';
import { DocumentsValidationErrorTypeEnum } from './widgets/JobDocumentsWidget/DocumentValidationModals';
import { useDocumentsPolling } from './hooks/use-documents-polling';
import { ReviewSettings } from './widgets/JobDocumentsWidget/ReviewSettings';
import { FieldState, useJobForm } from './widgets/JobDetailsWidget/useJobForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { DocumentCheckStatus } from './widgets/JobDetailsWidget/JobDetailsWidget';
import { useAppDispatch, useTypedSelector } from 'store';
import { jobWizardChecked, jobWizardStepChanged } from 'store/jobWizard';
import useJobProps from './useJobProps';
import { AppButton, AppButtonType, AppButtonColorScheme } from 'libs/ui/atoms/src/lib/appButton';
import { selectJobDocuments } from 'store/job';

export interface JobWizardProps {
  job: Job;
  hideExtraData: boolean;
  jobName: FieldState;
  onJobNameChanged: (name: string) => void;
  isGenerateReportPT: boolean;
  onGenerateReportPTToggle: (isGenerateReportPT: boolean) => void;
  isGenerateReportEN: boolean;
  onGenerateReportENToggle: (isGenerateReportEN: boolean) => void;
  validateSubmittedDocuments: () => Promise<boolean>;
  isLoadingSubmit: boolean;
  isAccordionExpanded: boolean;
  onAccordionExpanded: (isAccordionExpanded: boolean) => void;
  isProblemsNavigationModalToggle: boolean;
  onProblemsNavigationModalToggle: (isProblemsNavigationModalToggle: boolean) => void;
  uploadedDocs: JobDocument[];
  onUploadedDocs: (uploadedDocs: JobDocument[]) => void;
  onDocsSubmit: () => Promise<boolean>;
  checkRequiredDocumentsProvided: (documents: JobDocument[]) => DocumentCheckStatus;
}

export function JobWizard({
  job,
  hideExtraData,
  jobName,
  onJobNameChanged,
  isGenerateReportPT,
  onGenerateReportPTToggle,
  isGenerateReportEN,
  onGenerateReportENToggle,
  validateSubmittedDocuments,
  isLoadingSubmit,
  isAccordionExpanded,
  onAccordionExpanded,
  isProblemsNavigationModalToggle,
  onProblemsNavigationModalToggle,
  uploadedDocs,
  onUploadedDocs,
  onDocsSubmit,
  checkRequiredDocumentsProvided,
}: JobWizardProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isJobWizardChecked: boolean = useTypedSelector(state => state.wizard.jobWizard.isChecked);
  const jobStep: number = useTypedSelector(state => state.wizard.jobWizard.step);
  const jobDocuments = useTypedSelector(state => selectJobDocuments(state, job.id));

  useEffect(() => {
    setSearchParams({ wizard: jobStep.toString() });
  }, [jobStep]);

  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const { validateForm } = useJobForm(job.name);

  const [documentsValidationError, onDocumentsValidationError] =
    React.useState<DocumentsValidationErrorTypeEnum | null>(null);
  const [isShowUserSelectedUnsupportedTypeModal, onShowUserSelectedUnsupportedTypeModal] = useState(false);

  const onNewDocumentsFetched = React.useCallback((documents: JobDocument[]) => {
    if (documents.some(d => d.status === DocumentStatus.FATAL)) {
      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.FatalError);
    }

    if (documents.some(d => d.status === DocumentStatus.UNACCEPTABLE)) {
      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoIsUnacceptable);
    }

    if (documents.some(d => d.status === DocumentStatus.PROPERTY_ID_MISMATCH)) {
      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.CertidaoPropertyIdMismatch);
    }

    if (documents.some(d => d.status === DocumentStatus.NO_DATA_FOUND_ON_DOCUMENT)) {
      onDocumentsValidationError(DocumentsValidationErrorTypeEnum.NO_DATA_FOUND_ON_DOCUMENT);
    }

    const updatedDocs = [...uploadedDocs, ...documents];
    onUploadedDocs(updatedDocs);
  }, []);

  const { addDocumentsToPolling } = useDocumentsPolling({ jobId: job.id, onNewDocumentsFetched });

  const handleBack = async () => {
    const isFullyAutoCategorized = await checkDocumentStatus();
    if (jobStep == 1) {
      dispatch(jobWizardChecked({ wizardKey: 'jobWizard', isChecked: false }));
      navigate(`/jobs/${job.id}`);
    } else if (jobStep == 4 && isFullyAutoCategorized) {
      dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: jobStep - 2 }));
    } else {
      dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: jobStep - 1 }));
    }
  };

  const checkDocuments = (documents: JobDocument[]) => {
    for (const doc of documents) {
      const isFatalError = doc.status === DocumentStatus.FATAL;
      const isUnacceptable = doc.status === DocumentStatus.UNACCEPTABLE;
      const isChangeable = !doc.isInitialized && !isFatalError && !isUnacceptable;

      if (isChangeable) {
        return false;
      }

      return true;
    }
  };

  const checkDocumentStatus = () => {
    let checkStatus;
    if (uploadedDocs?.length) {
      checkStatus = checkDocuments(uploadedDocs);
    } else if (jobDocuments?.length) {
      checkStatus = checkDocuments(jobDocuments);
    }
    return checkStatus;
  };

  const handleNext = async () => {
    if (jobStep >= 5) {
      return;
    } else {
      switch (jobStep) {
        case 1:
          if (validateForm()) {
            dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: jobStep + 1 }));
          }
          break;
        case 2: {
          const isFullyAutoCategorized = await checkDocumentStatus();
          const step = isFullyAutoCategorized ? jobStep + 2 : jobStep + 1;
          dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step }));
          break;
        }
        case 3: {
          const submit = await validateSubmittedDocuments();
          if (submit) {
            dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: jobStep + 1 }));
          }
          break;
        }
        case 4:
          await onDocsSubmit();
          break;
        default:
          dispatch(jobWizardStepChanged({ wizardKey: 'jobWizard', step: jobStep + 1 }));
          break;
      }
    }
  };

  const { JobMainInfoProps, JobDocumentsStepProps, JobCategorizeFilesProps, ReviewSettingsProps } = useJobProps(
    job,
    hideExtraData,
    jobName,
    onJobNameChanged,
    isGenerateReportPT,
    onGenerateReportPTToggle,
    isGenerateReportEN,
    onGenerateReportENToggle,
    uploadedDocs,
    documentsValidationError,
    onUploadedDocs,
    checkRequiredDocumentsProvided,
    onDocumentsValidationError,
    isShowUserSelectedUnsupportedTypeModal,
    onShowUserSelectedUnsupportedTypeModal,
    addDocumentsToPolling,
    isAccordionExpanded,
    onAccordionExpanded,
    isProblemsNavigationModalToggle,
    onProblemsNavigationModalToggle,
  );

  return (
    <Flex direction="column" justify="space-between" p="24px" gap="24px" bg={widgetBackgroundColor}>
      {(() => {
        switch (jobStep) {
          case 1:
            return <JobMainInfoWidget {...JobMainInfoProps} />;
          case 2:
            return <JobDocumentsStep {...JobDocumentsStepProps} />;
          case 3:
            return <JobCategorizeFiles {...JobCategorizeFilesProps} />;
          case 4:
            return <ReviewSettings {...ReviewSettingsProps} />;
          default:
            return <></>;
        }
      })()}

      {isJobWizardChecked && <hr />}
      {isJobWizardChecked && (
        <Flex gap="24px" justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
          <AppButton
            buttonType={AppButtonType.SECONDARY}
            onClick={() => handleBack()}
            title={t('pages.details.cancel')}
            colorSchemes={[AppButtonColorScheme.DISABLED]}
            leftIcon={<ArrowBackIcon />}
          />

          <AppButton
            buttonType={AppButtonType.PRIMARY}
            title={jobStep !== 4 ? t('pages.wizard.next') : t('pages.details.submit')}
            onClick={() => handleNext()}
            isLoading={isLoadingSubmit}
            rightIcon={<ArrowForwardIcon />}
          />
        </Flex>
      )}
    </Flex>
  );
}
