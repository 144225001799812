import { ImpicContractPeriod, ImpicQualidadeDeclaranteNT, ImpicTransactionType } from 'api';

export const tByQualidadeDeclarante: Record<ImpicQualidadeDeclaranteNT, string> = {
  [ImpicQualidadeDeclaranteNT.MEDIACAO_IMOBILIARIA]: 'impic.qualidadeNaTransacao.mediacaoImobiliaria',
  [ImpicQualidadeDeclaranteNT.COMPRA_VENDA_COMPRA_PARA_REVENDA_PERMUTA_IMOVEIS]:
    'impic.qualidadeNaTransacao.compraVenda',
  // [ImpicQualidadeDeclaranteNT.PROMOCAO_IMOBILIARIA]: 'impic.qualidadeNaTransacao.promocaoImobiliaria',
  // [ImpicQualidadeDeclaranteNT.ARRENDAMENTO]: 'impic.qualidadeNaTransacao.arrendamento',
  // [ImpicQualidadeDeclaranteNT.OUTRA_ATIVIDADE_IMOBILIARIA]: 'impic.qualidadeNaTransacao.outraAtividadeImobiliaria',
};

export const tByContractPeriod: Record<ImpicContractPeriod, string> = {
  [ImpicContractPeriod.SEMESTER_1]: 'impic.contractPeriod.semester1',
  [ImpicContractPeriod.SEMESTER_2]: 'impic.contractPeriod.semester2',
  [ImpicContractPeriod.TRIMESTER_1]: 'impic.contractPeriod.trimester1',
  [ImpicContractPeriod.TRIMESTER_2]: 'impic.contractPeriod.trimester2',
  [ImpicContractPeriod.TRIMESTER_3]: 'impic.contractPeriod.trimester3',
  [ImpicContractPeriod.TRIMESTER_4]: 'impic.contractPeriod.trimester4',
};

export const tByTransactionType: Record<ImpicTransactionType, string> = {
  [ImpicTransactionType.COMPRA]: 'impic.transactionType.compra',
  [ImpicTransactionType.VENDA]: 'impic.transactionType.venda',
  [ImpicTransactionType.PERMUTA]: 'impic.transactionType.permuta',
  [ImpicTransactionType.ARRENDAMENTO]: 'impic.transactionType.arrendamento',
};
