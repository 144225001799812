import {
  RightOfFirstRefusalCurrency,
  RightOfFirstRefusalDensity,
  RightOfFirstRefusalDto,
  RightOfFirstRefusalItemsMeasure,
  RightOfFirstRefusalTypeOfBusiness,
} from 'api';
import { useCallback, useReducer } from 'react';
import { isDecimalValid, isEmailValid, isNifValid } from 'utils/validators';
import { useToast } from '@chakra-ui/react';

type SetInitialStateAction = { type: 'setInitialState'; value: RightOfFirstRefusalFormState };
type SetNameAction = { type: 'setName'; value: string };
type SetEmailAction = { type: 'setEmail'; value: string };
type SetNifNipsAction = { type: 'setNifNips'; value: string };
type SetAddressAction = { type: 'setAddress'; value: string };
type SetPhoneAction = { type: 'setPhone'; value: string };
type SetTypeOfBusinessAction = { type: 'setTypeOfBusiness'; value: RightOfFirstRefusalTypeOfBusiness };
type SetPriceAction = { type: 'setPrice'; value: string };
type SetCurrencyAction = { type: 'setCurrency'; value: RightOfFirstRefusalCurrency };
type SetExpectedDealDateAction = { type: 'setExpectedDealDate'; value: string };
type SetCommentsAction = { type: 'setComments'; value: string };
type SetPrivateGrossAreaAction = { type: 'setPrivateGrossArea'; value: string };
type SetPrivateGrossAreaItemsMeasureAction = {
  type: 'setPrivateGrossAreaItemsMeasure';
  value: RightOfFirstRefusalItemsMeasure;
};
type SetTotalAreaAction = { type: 'setTotalArea'; value: string };
type SetTotalAreaItemsMeasureAction = { type: 'setTotalAreaItemsMeasure'; value: RightOfFirstRefusalItemsMeasure };
type SetIsLeasedAction = { type: 'setIsLeased'; value: boolean };
type SetPropertyLocationAction = { type: 'setPropertyLocation'; value: string };
type SetDestinyAction = { type: 'setDestiny'; value: RightOfFirstRefusalDensity };
type SetShareShareAction = { type: 'setShareShare'; value: string };
type SetMatrixArticleAction = { type: 'setMatrixArticle'; value: string };

type RightOfFirstRefusalFormAction =
  | SetInitialStateAction
  | SetNameAction
  | SetEmailAction
  | SetNifNipsAction
  | SetAddressAction
  | SetPhoneAction
  | SetTypeOfBusinessAction
  | SetPriceAction
  | SetCurrencyAction
  | SetExpectedDealDateAction
  | SetCommentsAction
  | SetPrivateGrossAreaAction
  | SetPrivateGrossAreaItemsMeasureAction
  | SetTotalAreaAction
  | SetTotalAreaItemsMeasureAction
  | SetIsLeasedAction
  | SetPropertyLocationAction
  | SetDestinyAction
  | SetShareShareAction
  | SetMatrixArticleAction;

export type RightOfFirstRefusalFormState = Omit<Partial<RightOfFirstRefusalDto>, 'jobId'> & {
  identifier_conservatória?: string;
  identifier_parish?: string;
};

export function useRightOfFirstRefusalModalForm() {
  const [form, dispatch] = useReducer((state: RightOfFirstRefusalFormState, action: RightOfFirstRefusalFormAction) => {
    switch (action.type) {
      case 'setInitialState':
        return {
          ...state,
          ...action.value,
          privateGrossAreaItemsMeasure: action.value.privateGrossArea
            ? RightOfFirstRefusalItemsMeasure.METERS_SQUARE
            : undefined,
          typeOfBusiness: action.value.typeOfBusiness || RightOfFirstRefusalTypeOfBusiness.BUY_AND_SELL,
          descriptionOfSheet: action.value.certidaoIdentifier?.split('/')[0],
          autonomousFraction: action.value.certidaoIdentifier?.split('-')[1]?.trim(),
          currency: RightOfFirstRefusalCurrency.EUROS,
        };

      case 'setName': {
        return { ...state, name: action.value };
      }
      case 'setEmail': {
        return { ...state, email: action.value };
      }
      case 'setNifNips': {
        return { ...state, nif: action.value };
      }
      case 'setAddress': {
        return { ...state, address: action.value };
      }
      case 'setPhone': {
        return { ...state, phone: action.value };
      }
      case 'setTypeOfBusiness': {
        return { ...state, typeOfBusiness: action.value };
      }
      case 'setPrice': {
        return { ...state, price: action.value };
      }
      case 'setCurrency': {
        return { ...state, currency: action.value };
      }
      case 'setExpectedDealDate': {
        return { ...state, expectedDealDate: action.value };
      }
      case 'setComments': {
        return { ...state, comments: action.value };
      }
      case 'setPrivateGrossArea': {
        return { ...state, privateGrossArea: action.value };
      }
      case 'setPrivateGrossAreaItemsMeasure': {
        return { ...state, privateGrossAreaItemsMeasure: action.value };
      }
      case 'setTotalArea': {
        return { ...state, totalArea: action.value };
      }
      case 'setTotalAreaItemsMeasure': {
        return { ...state, totalAreaItemsMeasure: action.value };
      }
      case 'setIsLeased': {
        return { ...state, isLeased: action.value };
      }
      case 'setPropertyLocation': {
        return { ...state, propertyLocation: action.value };
      }
      case 'setDestiny': {
        return { ...state, destiny: action.value };
      }
      case 'setShareShare': {
        return { ...state, shareShare: action.value };
      }
      case 'setMatrixArticle': {
        return { ...state, matrixArticle: action.value };
      }

      default:
        return state;
    }
  }, {});

  const validate = useCallback<() => string[]>(() => {
    const errorMsgs = [];
    if (!form.name) {
      errorMsgs.push('nameIsRequired');
    }
    if (!form.email || !isEmailValid(form.email)) {
      errorMsgs.push('invalidEmail');
    }
    if (!form.nif) {
      errorMsgs.push('nifNipcIsRequired');
    }
    if (form.nif && !isNifValid(form.nif)) {
      errorMsgs.push('nifIsNotValid');
    }
    if (!form.address && !form.phone) {
      errorMsgs.push('addressOrPhoneIsRequired');
    }

    if (!form.privateGrossArea) {
      errorMsgs.push('privateGrossAreaIsRequired');
    }

    if (!isDecimalValid(form.privateGrossArea ?? '')) {
      errorMsgs.push('privateGrossAreaIsNotValid');
    }

    if (!form.privateGrossAreaItemsMeasure) {
      errorMsgs.push('unitIsRequired');
    }

    if (!form.destiny) {
      errorMsgs.push('useTypeIsRequired');
    }

    if (!form.propertyLocation) {
      errorMsgs.push('addressIsRequired');
    }

    if (!form.typeOfBusiness) {
      errorMsgs.push('typeOfBusinessIsRequired');
    }

    if (!form.expectedDealDate) {
      errorMsgs.push('expectedDealDateIsRequired');
    }

    if (!form.price) {
      errorMsgs.push('priceIsRequired');
    }

    if (!isDecimalValid(form.price ?? '')) {
      errorMsgs.push('priceIsNotValid');
    }

    // if (!form.currency) {
    //   errorMsgs.push('Currency is required');
    // }

    return errorMsgs;
  }, [form]);

  return { form, dispatch, validate };
}
