import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface UniversalSortableItemProps {
  id: string;
  children: React.ReactElement;
}

export const UniversalSortableItem = ({ id, children }: UniversalSortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'none',
      }}
    >
      {React.cloneElement(children, {
        draggableAttributes: attributes,
        draggableListeners: listeners,
        isDragging: isDragging,
      })}
    </div>
  );
};
