import React, { MouseEventHandler } from 'react';
import { Button as ChakraButton, ButtonProps, Tooltip } from '@chakra-ui/react';
import { AppButtonType } from './app-button-types';
import {
  appButtonBaseStyles,
  appButtonColorSchemeStyles,
  appButtonDisabledStyles,
  appButtonTypeStyles,
} from './app-button-styles';
import { AppButtonColorScheme } from './app-button-color-scheme';
import { useColorMode } from '@chakra-ui/system';

interface AppButtonProps extends Omit<ButtonProps, 'type'> {
  buttonType: AppButtonType;
  colorSchemes?: AppButtonColorScheme[];
  title?: string;
  children?: React.ReactNode;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  href?: string;
  download?: string;
  isWidePadding?: boolean;
}

export const AppButton: React.FC<AppButtonProps> = ({
  buttonType,
  colorSchemes = [AppButtonColorScheme.DEFAULT],
  title,
  children,
  leftIcon,
  rightIcon,
  isDisabled,
  onClick,
  tooltip,
  href,
  download,
  isWidePadding,
  ...rest
}) => {
  const { colorMode } = useColorMode();

  const combinedColorSchemeStyles = colorSchemes.reduce((acc, colorScheme) => {
    return { ...acc, ...(appButtonColorSchemeStyles(colorMode)[colorScheme] || {}) };
  }, {});

  const styles = {
    ...appButtonBaseStyles,
    ...(appButtonTypeStyles(colorMode, isWidePadding)[buttonType] || {}),
    ...combinedColorSchemeStyles,
    ...(isDisabled ? appButtonDisabledStyles : {}),
  };

  const buttonContent = (
    <ChakraButton
      onClick={onClick}
      isDisabled={isDisabled}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      {...styles}
      {...rest}
    >
      {title ? title : children}
    </ChakraButton>
  );

  const wrappedButton = tooltip ? (
    <Tooltip label={tooltip} shouldWrapChildren>
      {buttonContent}
    </Tooltip>
  ) : (
    buttonContent
  );

  return href ? (
    <a href={href} download={download} target="_blank" rel="noreferrer">
      {wrappedButton}
    </a>
  ) : (
    wrappedButton
  );
};
