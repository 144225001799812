export enum AppButtonColorScheme {
  DEFAULT = 'DEFAULT',
  DANGER = 'DANGER',
  TRANSPARENT = 'TRANSPARENT',
  WARNING = 'WARNING',
  SECONDARY = 'SECONDARY',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  DISABLED = 'DISABLED',
  DELETE = 'DELETE',
}
