import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@chakra-ui/icons';
import { Job } from 'api';
import { useTypedSelector } from 'store';

interface JobProgressProps {
  job: Job;
}

const TOTAL_JOB_STEPS = 4;

export function JobProgress({ job }: JobProgressProps) {
  const { t } = useTranslation();

  const jobStep: number = useTypedSelector(state => state.wizard.jobWizard.step);

  const textColor = useColorModeValue('gray.600', 'white');
  const progressButtonColor = useColorModeValue('gray.500', 'gray.500');
  const progressButtonCheckColor = useColorModeValue('green.400', 'green.400');
  const progressButtonSelectedColor = useColorModeValue('blue.500', 'blue.500');
  const progressTextColor = useColorModeValue('white', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');

  const isJobStep = (step: number) => {
    return jobStep === step;
  };

  const isJobStepConcluded = (step: number) => {
    return jobStep > step;
  };

  return (
    <Flex
      direction="row"
      gap="24px"
      p={'24px'}
      w="100%"
      h="fit-content"
      borderRadius={'20px'}
      bg={widgetBackgroundColor}
      alignItems={'center'}
      justify={'space-between'}
    >
      <Text color={textColor} fontWeight="700" fontSize={'18px'} float={'left'}>
        {t('pages.wizard.progress')}
      </Text>
      <Flex direction={'row'} gap="20px" justify={'space-evenly'} wrap="nowrap">
        {Array.from({ length: TOTAL_JOB_STEPS }, (_, idx) => (
          <Flex
            key={idx}
            p={'16px'}
            borderRadius="50%"
            bg={
              isJobStepConcluded(idx + 1)
                ? progressButtonCheckColor
                : isJobStep(idx + 1)
                  ? progressButtonSelectedColor
                  : progressButtonColor
            }
          >
            {isJobStepConcluded(idx + 1) ? (
              <CheckIcon h="18px" w="18px" color={'white'} />
            ) : (
              <Flex justify="center" align="center" h="18px" w="18px">
                <Text color={progressTextColor}>{idx + 1}</Text>
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
