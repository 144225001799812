import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface FieldState {
  value: string;
  error: string;
}

function isNotEmpty(value: string) {
  return value.trim().length > 0;
}

export function useJobForm(name: string) {
  const { t } = useTranslation();

  const [jobName, setJobName] = useState<FieldState>({
    value: name || '',
    error: '',
  });

  const onJobNameChanged = (value: string) => {
    setJobName({ value, error: isNotEmpty(value) ? '' : t('pages.details.nameIsRequired') });
  };

  const validateForm = () => {
    let isValid = true;

    if (!isNotEmpty(name)) {
      setJobName({ ...jobName, error: t('pages.details.nameIsRequired') });
      isValid = false;
    }

    return isValid;
  };

  return { jobName, onJobNameChanged, validateForm };
}
