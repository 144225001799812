import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortingState } from '@tanstack/react-table';
import { Signing, signingApi, SigningListResponse, SigningSingleResponse } from '../api/signing';
import { ItemUpsertManyAction, itemUpsertManyReducer } from './list';

export interface SigningState {
  signingPagesCount: number;
  signingTotalCount: number;
  signingSorting: SortingState;
  signings: SigningListResponse[];
  selectedSigning: SigningSingleResponse | null;
}

const initialSigningState: SigningState = {
  signingPagesCount: 0,
  signingTotalCount: 0,
  signingSorting: [],
  signings: [],
  selectedSigning: null,
};

const matchSignings = (
  state: SigningState,
  action: PayloadAction<{ data?: SigningListResponse[] | null; totalCount: number; pageCount: number }>,
) => {
  const signingState: SigningState = signingSlice.reducer(
    state,
    upsertSignings({ items: [...(action.payload.data ?? [])], changePositionForUpdated: true, addAtEnd: true }),
  );
  return { ...signingState, signingPagesCount: action.payload.pageCount, signingTotalCount: action.payload.totalCount };
};

const matchSigning = (state: SigningState, action: PayloadAction<SigningSingleResponse | null>) => {
  const signingState: SigningState = signingSlice.reducer(state, signingSelected(action.payload));
  return signingState;
};

const signingSlice = createSlice({
  name: 'signing',
  initialState: initialSigningState,
  reducers: {
    upsertSignings: (state, action: PayloadAction<ItemUpsertManyAction<SigningListResponse>>) => ({
      ...state,
      signings: itemUpsertManyReducer(state.signings, action.payload),
    }),
    tabChanged: state => ({ ...state, signings: [], signingPagesCount: 0, signingTotalCount: 0 }),
    sortChanged: state => ({ ...state, signings: [], signingPagesCount: 0, signingTotalCount: 0 }),
    signingSelected: (state, action: PayloadAction<SigningSingleResponse | null>) => ({
      ...state,
      selectedSigning: action.payload,
    }),
    signingDeleted: (state, { payload }: PayloadAction<{ signing: Signing }>) => ({
      ...state,
      signings: state.signings.filter(s => s.id !== payload.signing.id),
    }),
  },
  extraReducers: builder =>
    builder
      .addMatcher(signingEndpoints.getSignings.matchFulfilled, matchSignings)
      .addMatcher(signingEndpoints.getSigning.matchFulfilled, matchSigning)
      .addMatcher(signingEndpoints.updateSigning.matchFulfilled, matchSigning),
});

export const { upsertSignings, tabChanged, sortChanged, signingSelected, signingDeleted } = signingSlice.actions;

export const { reducer: signingReducer, reducerPath: signingReducerPath } = signingSlice;

export const { endpoints: signingEndpoints } = signingApi;
