import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { initCreateRelatedPartyData, useRelatedPartyForm } from './useRelatedPartyForm';
import {
  RelatedParty,
  RelatedPartyEntityType,
  RelatedPartyRelationType,
  RelatedPartyUpdateDto,
} from 'api/related-parties';
import { MutateRelatedPartyForm } from './MutateRelatedPartyForm';
import { AppButton, AppButtonType } from '../../../../../../libs/ui/atoms/src/lib/appButton';

export interface EditRelatedPartyModalProps {
  isOpen: boolean;
  relatedParty: RelatedParty;
  parentParty?: RelatedParty;
  onSubmit: (data: RelatedPartyUpdateDto) => Promise<void>;
  onClose: () => void;
}

export const EditRelatedPartyModal = ({
  isOpen,
  relatedParty,
  parentParty,
  onSubmit,
  onClose,
}: EditRelatedPartyModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { form, dispatch, validate } = useRelatedPartyForm();
  const [isLoading, setIsLoading] = useState(false);

  const close = useCallback(() => {
    dispatch({ type: 'setInitialState', value: { ...initCreateRelatedPartyData } });
    onClose();
  }, [onClose, dispatch]);

  useEffect(() => {
    if (!relatedParty) return;

    dispatch({
      type: 'setInitialState',
      value: {
        relationType: relatedParty.relationType,
        name: relatedParty.name,
        email: relatedParty.email,
        nif: relatedParty.nif,
        address: relatedParty.address,
        phone: relatedParty.phone,
        civilStatus: relatedParty.civilStatus,
        marriageRegime: relatedParty.marriageRegime,
        age: relatedParty.age,
        marriedTo: relatedParty.marriedTo,
        nacionalidade: relatedParty.nacionalidade,
        placeOfBirthFreguesia: relatedParty.placeOfBirthFreguesia,
        placeOfBirthConcelho: relatedParty.placeOfBirthConcelho,
        idDocumentType: relatedParty.idDocumentType,
        idDocumentNumber: relatedParty.idDocumentNumber,
        idDocumentIssuanceAuthority: relatedParty.idDocumentIssuanceAuthority,
        idDocumentIssueDate: relatedParty.idDocumentIssueDate ? new Date(relatedParty.idDocumentIssueDate) : undefined,
        idDocumentExpiryDate: relatedParty.idDocumentExpiryDate
          ? new Date(relatedParty.idDocumentExpiryDate)
          : undefined,
        corpRepAuthority: relatedParty.corpRepAuthority,
        corpAddress: relatedParty.corpAddress,
        corpAddressParish: relatedParty.corpAddressParish,
        corpAddressPostalCode: relatedParty.corpAddressPostalCode,
        corpAddressMunicipality: relatedParty.corpAddressMunicipality,
        corpAddressDistrict: relatedParty.corpAddressDistrict,
        corpCapital: relatedParty.corpCapital,
        isFiador: relatedParty.isFiador,
      },
    });
  }, [dispatch, relatedParty]);

  const onSubmitClick = useCallback(async () => {
    if (!relatedParty) return;

    const validationMessages = validate();
    if (validationMessages.length > 0) {
      toast({
        title: t('validationError'),
        description: validationMessages.map(i => t(i)).join(', \n'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit({
        ...(form as RelatedPartyUpdateDto),
        id: relatedParty.id,
      });

      close();
    } catch (error) {
      toast({
        title: t('relatedParties.unableToUpdateRelatedParty'),
        description: ((error as FetchBaseQueryError).data as Error)?.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  }, [validate, toast, t, onSubmit, form, relatedParty, close]);

  const textColor = useColorModeValue('navy.750', 'white');

  return (
    <Modal size="xl" isOpen={isOpen} onClose={close} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent color={textColor} maxH={'calc(100% - 0rem)'} maxW={'900px'} p="24px" borderRadius="16px">
        <ModalHeader p="0" fontSize="24px">
          {t(
            relatedParty?.entityType === RelatedPartyEntityType.BUYER
              ? 'relatedParties.updateBuyer'
              : 'relatedParties.updateSeller',
          )}
        </ModalHeader>
        <ModalCloseButton right="24px" top="none" />
        <ModalBody p="24px 1px">
          <MutateRelatedPartyForm
            entityType={relatedParty?.entityType}
            isHideRelationRadioButton
            isShowCorpAuthority={parentParty?.relationType === RelatedPartyRelationType.COMPANY}
            form={form}
            dispatch={dispatch}
          />
        </ModalBody>

        <ModalFooter justifyContent="center" gap="24px" p="0">
          <AppButton
            isLoading={isLoading}
            buttonType={AppButtonType.PRIMARY_MAIN}
            onClick={onSubmitClick}
            title={t('submit')}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
