import { Box, Flex, FormControl, FormLabel, Icon, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { useTranslation } from 'react-i18next';

import { DetailInputField } from 'libs/ui/atoms/src/lib/input-field/InputField';
import { Signing } from '../../../../api/signing';
import { formatDate } from '../../../../utils/date';
import {
  iconBySubmissionSigningStatus,
  iconColorBySigningStatus,
  iconColorSubmissionDraft,
  iconSubmissionDraft,
  signingStatusTranslateBySigningStatus,
  signingStatusTranslatedDraft,
} from '../variables/signing-status';
import { Link } from 'react-router-dom';
import { Copy, Cube } from '../../../../libs/ui/atoms/src';
import { AppButton, AppButtonColorScheme, AppButtonType } from '../../../../libs/ui/atoms/src/lib/appButton';
import { EntityType, SubmissionSigningStatus } from '../../../../api';

export interface SigningDetailsWidgetProps {
  isReadOnly?: boolean;
  signing: Signing;
  name?: string;
  onNameUpdate: (value: string) => void;
  onCloneRejectedSigning: () => void;
}

export function SigningMainInfoWidget({
  isReadOnly,
  name,
  signing,
  onNameUpdate,
  onCloneRejectedSigning,
}: SigningDetailsWidgetProps) {
  const { t } = useTranslation();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandStars = useColorModeValue('brand.500', 'white');
  const widgetBackgroundColor = useColorModeValue('white', 'navy.800');
  const dateTextColor = useColorModeValue('navy.750', 'white');

  return (
    <Flex direction="column" gap="24px" p={'24px'} bg={widgetBackgroundColor} borderRadius={'20px'}>
      <FormControl
        gap="24px"
        display="flex"
        justifyContent="space-between"
        position="static"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box flex={1}>
          <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
            {t('pages.details.description')}
            <Text color={brandStars}>*</Text>
          </FormLabel>
          <DetailInputField
            isReadOnly={!!signing?.entityId || isReadOnly}
            value={name ?? ''}
            onChange={event => onNameUpdate(event.target.value)}
            placeholder={t('pages.details.description')}
          />
        </Box>

        <Flex flexDirection={'column'} gap="8px" justifyContent={'center'} alignItems="flex-end">
          <Flex justify="space-between" gap="24px" alignItems={'center'}>
            <Flex align="center">
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={
                  signing.submission?.status
                    ? iconColorBySigningStatus[signing.submission?.status]
                    : iconColorSubmissionDraft
                }
                as={
                  signing.submission?.status
                    ? iconBySubmissionSigningStatus[signing.submission?.status]
                    : iconSubmissionDraft
                }
              />
              <Text color={textColor} fontSize="sm" fontWeight="700">
                {signing.submission?.status
                  ? t(signingStatusTranslateBySigningStatus[signing.submission?.status])
                  : t(signingStatusTranslatedDraft)}
              </Text>
            </Flex>

            {(signing.updatedAt || signing.createdAt) && (
              <Text color={dateTextColor}>{formatDate(signing.updatedAt || signing.createdAt)}</Text>
            )}
          </Flex>

          <Flex gap={'15px'}>
            {signing?.submission?.status === SubmissionSigningStatus.REJECT && (
              <AppButton
                onClick={onCloneRejectedSigning}
                buttonType={AppButtonType.SECONDARY}
                leftIcon={<Copy fontSize={'22px'} />}
                title={t('pages.signing.clone')}
                colorSchemes={[AppButtonColorScheme.TRANSPARENT]}
              />
            )}

            {signing?.entityId && signing?.entityType === EntityType.JOB && (
              <Link to={`/jobs/${signing.entityId}`}>
                <AppButton
                  buttonType={AppButtonType.PRIMARY}
                  leftIcon={<Cube fontSize={'22px'} />}
                  title={t('pages.signing.goToRelatedJob')}
                />
              </Link>
            )}
          </Flex>
        </Flex>
      </FormControl>
    </Flex>
  );
}
