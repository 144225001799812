const COUNTRIES_WITH_CODES = [
  { flag: '🇦🇼', value: 'AW', labelWithoutFlag: 'Aruba', label: '🇦🇼 Aruba', alpha3: 'ABW' },
  { flag: '🇦🇫', value: 'AF', labelWithoutFlag: 'Afghanistan', label: '🇦🇫 Afghanistan', alpha3: 'AFG' },
  { flag: '🇦🇴', value: 'AO', labelWithoutFlag: 'Angola', label: '🇦🇴 Angola', alpha3: 'AGO' },
  { flag: '🇦🇮', value: 'AI', labelWithoutFlag: 'Anguilla', label: '🇦🇮 Anguilla', alpha3: 'AIA' },
  { flag: '🇦🇽', value: 'AX', labelWithoutFlag: 'Åland Islands', label: '🇦🇽 Åland Islands', alpha3: 'ALA' },
  { flag: '🇦🇱', value: 'AL', labelWithoutFlag: 'Albania', label: '🇦🇱 Albania', alpha3: 'ALB' },
  { flag: '🇦🇩', value: 'AD', labelWithoutFlag: 'Andorra', label: '🇦🇩 Andorra', alpha3: 'AND' },
  {
    flag: '🇦🇪',
    value: 'AE',
    labelWithoutFlag: 'United Arab Emirates',
    label: '🇦🇪 United Arab Emirates',
    alpha3: 'ARE',
  },
  { flag: '🇦🇷', value: 'AR', labelWithoutFlag: 'Argentina', label: '🇦🇷 Argentina', alpha3: 'ARG' },
  { flag: '🇦🇲', value: 'AM', labelWithoutFlag: 'Armenia', label: '🇦🇲 Armenia', alpha3: 'ARM' },
  { flag: '🇦🇸', value: 'AS', labelWithoutFlag: 'American Samoa', label: '🇦🇸 American Samoa', alpha3: 'ASM' },
  { flag: '🇦🇶', value: 'AQ', labelWithoutFlag: 'Antarctica', label: '🇦🇶 Antarctica', alpha3: 'ATA' },
  {
    flag: '🇹🇫',
    value: 'TF',
    labelWithoutFlag: 'French Southern and Antarctic Lands',
    label: '🇹🇫 French Southern and Antarctic Lands',
    alpha3: 'ATF',
  },
  { flag: '🇦🇬', value: 'AG', labelWithoutFlag: 'Antigua and Barbuda', label: '🇦🇬 Antigua and Barbuda', alpha3: 'ATG' },
  { flag: '🇦🇺', value: 'AU', labelWithoutFlag: 'Australia', label: '🇦🇺 Australia', alpha3: 'AUS' },
  { flag: '🇦🇹', value: 'AT', labelWithoutFlag: 'Austria', label: '🇦🇹 Austria', alpha3: 'AUT' },
  { flag: '🇦🇿', value: 'AZ', labelWithoutFlag: 'Azerbaijan', label: '🇦🇿 Azerbaijan', alpha3: 'AZE' },
  { flag: '🇧🇮', value: 'BI', labelWithoutFlag: 'Burundi', label: '🇧🇮 Burundi', alpha3: 'BDI' },
  { flag: '🇧🇪', value: 'BE', labelWithoutFlag: 'Belgium', label: '🇧🇪 Belgium', alpha3: 'BEL' },
  { flag: '🇧🇯', value: 'BJ', labelWithoutFlag: 'Benin', label: '🇧🇯 Benin', alpha3: 'BEN' },
  { flag: '🇧🇫', value: 'BF', labelWithoutFlag: 'Burkina Faso', label: '🇧🇫 Burkina Faso', alpha3: 'BFA' },
  { flag: '🇧🇩', value: 'BD', labelWithoutFlag: 'Bangladesh', label: '🇧🇩 Bangladesh', alpha3: 'BGD' },
  { flag: '🇧🇬', value: 'BG', labelWithoutFlag: 'Bulgaria', label: '🇧🇬 Bulgaria', alpha3: 'BGR' },
  { flag: '🇧🇭', value: 'BH', labelWithoutFlag: 'Bahrain', label: '🇧🇭 Bahrain', alpha3: 'BHR' },
  { flag: '🇧🇸', value: 'BS', labelWithoutFlag: 'Bahamas', label: '🇧🇸 Bahamas', alpha3: 'BHS' },
  {
    flag: '🇧🇦',
    value: 'BA',
    labelWithoutFlag: 'Bosnia and Herzegovina',
    label: '🇧🇦 Bosnia and Herzegovina',
    alpha3: 'BIH',
  },
  { flag: '🇧🇱', value: 'BL', labelWithoutFlag: 'Saint Barthélemy', label: '🇧🇱 Saint Barthélemy', alpha3: 'BLM' },
  {
    flag: '🇸🇭',
    value: 'SH',
    labelWithoutFlag: 'Saint Helena, Ascension and Tristan da Cunha',
    label: '🇸🇭 Saint Helena, Ascension and Tristan da Cunha',
    alpha3: 'SHN',
  },
  { flag: '🇧🇾', value: 'BY', labelWithoutFlag: 'Belarus', label: '🇧🇾 Belarus', alpha3: 'BLR' },
  { flag: '🇧🇿', value: 'BZ', labelWithoutFlag: 'Belize', label: '🇧🇿 Belize', alpha3: 'BLZ' },
  { flag: '🇧🇲', value: 'BM', labelWithoutFlag: 'Bermuda', label: '🇧🇲 Bermuda', alpha3: 'BMU' },
  { flag: '🇧🇴', value: 'BO', labelWithoutFlag: 'Bolivia', label: '🇧🇴 Bolivia', alpha3: 'BOL' },
  { flag: '', value: 'BQ', labelWithoutFlag: 'Caribbean Netherlands', label: 'Caribbean Netherlands', alpha3: 'BES' },
  { flag: '🇧🇷', value: 'BR', labelWithoutFlag: 'Brazil', label: '🇧🇷 Brazil', alpha3: 'BRA' },
  { flag: '🇧🇧', value: 'BB', labelWithoutFlag: 'Barbados', label: '🇧🇧 Barbados', alpha3: 'BRB' },
  { flag: '🇧🇳', value: 'BN', labelWithoutFlag: 'Brunei', label: '🇧🇳 Brunei', alpha3: 'BRN' },
  { flag: '🇧🇹', value: 'BT', labelWithoutFlag: 'Bhutan', label: '🇧🇹 Bhutan', alpha3: 'BTN' },
  { flag: '🇧🇻', value: 'BV', labelWithoutFlag: 'Bouvet Island', label: '🇧🇻 Bouvet Island', alpha3: 'BVT' },
  { flag: '🇧🇼', value: 'BW', labelWithoutFlag: 'Botswana', label: '🇧🇼 Botswana', alpha3: 'BWA' },
  {
    flag: '🇨🇫',
    value: 'CF',
    labelWithoutFlag: 'Central African Republic',
    label: '🇨🇫 Central African Republic',
    alpha3: 'CAF',
  },
  { flag: '🇨🇦', value: 'CA', labelWithoutFlag: 'Canada', label: '🇨🇦 Canada', alpha3: 'CAN' },
  {
    flag: '🇨🇨',
    value: 'CC',
    labelWithoutFlag: 'Cocos (Keeling) Islands',
    label: '🇨🇨 Cocos (Keeling) Islands',
    alpha3: 'CCK',
  },
  { flag: '🇨🇭', value: 'CH', labelWithoutFlag: 'Switzerland', label: '🇨🇭 Switzerland', alpha3: 'CHE' },
  { flag: '🇨🇱', value: 'CL', labelWithoutFlag: 'Chile', label: '🇨🇱 Chile', alpha3: 'CHL' },
  { flag: '🇨🇳', value: 'CN', labelWithoutFlag: 'China', label: '🇨🇳 China', alpha3: 'CHN' },
  { flag: '🇨🇮', value: 'CI', labelWithoutFlag: 'Ivory Coast', label: '🇨🇮 Ivory Coast', alpha3: 'CIV' },
  { flag: '🇨🇲', value: 'CM', labelWithoutFlag: 'Cameroon', label: '🇨🇲 Cameroon', alpha3: 'CMR' },
  { flag: '🇨🇩', value: 'CD', labelWithoutFlag: 'DR Congo', label: '🇨🇩 DR Congo', alpha3: 'COD' },
  {
    flag: '🇨🇬',
    value: 'CG',
    labelWithoutFlag: 'Republic of the Congo',
    label: '🇨🇬 Republic of the Congo',
    alpha3: 'COG',
  },
  { flag: '🇨🇰', value: 'CK', labelWithoutFlag: 'Cook Islands', label: '🇨🇰 Cook Islands', alpha3: 'COK' },
  { flag: '🇨🇴', value: 'CO', labelWithoutFlag: 'Colombia', label: '🇨🇴 Colombia', alpha3: 'COL' },
  { flag: '🇰🇲', value: 'KM', labelWithoutFlag: 'Comoros', label: '🇰🇲 Comoros', alpha3: 'COM' },
  { flag: '🇨🇻', value: 'CV', labelWithoutFlag: 'Cape Verde', label: '🇨🇻 Cape Verde', alpha3: 'CPV' },
  { flag: '🇨🇷', value: 'CR', labelWithoutFlag: 'Costa Rica', label: '🇨🇷 Costa Rica', alpha3: 'CRI' },
  { flag: '🇨🇺', value: 'CU', labelWithoutFlag: 'Cuba', label: '🇨🇺 Cuba', alpha3: 'CUB' },
  { flag: '🇨🇼', value: 'CW', labelWithoutFlag: 'Curaçao', label: '🇨🇼 Curaçao', alpha3: 'CUW' },
  { flag: '🇨🇽', value: 'CX', labelWithoutFlag: 'Christmas Island', label: '🇨🇽 Christmas Island', alpha3: 'CXR' },
  { flag: '🇰🇾', value: 'KY', labelWithoutFlag: 'Cayman Islands', label: '🇰🇾 Cayman Islands', alpha3: 'CYM' },
  { flag: '🇨🇾', value: 'CY', labelWithoutFlag: 'Cyprus', label: '🇨🇾 Cyprus', alpha3: 'CYP' },
  { flag: '🇨🇿', value: 'CZ', labelWithoutFlag: 'Czechia', label: '🇨🇿 Czechia', alpha3: 'CZE' },
  { flag: '🇩🇪', value: 'DE', labelWithoutFlag: 'Germany', label: '🇩🇪 Germany', alpha3: 'DEU' },
  { flag: '🇩🇯', value: 'DJ', labelWithoutFlag: 'Djibouti', label: '🇩🇯 Djibouti', alpha3: 'DJI' },
  { flag: '🇩🇲', value: 'DM', labelWithoutFlag: 'Dominica', label: '🇩🇲 Dominica', alpha3: 'DMA' },
  { flag: '🇩🇰', value: 'DK', labelWithoutFlag: 'Denmark', label: '🇩🇰 Denmark', alpha3: 'DNK' },
  { flag: '🇩🇴', value: 'DO', labelWithoutFlag: 'Dominican Republic', label: '🇩🇴 Dominican Republic', alpha3: 'DOM' },
  { flag: '🇩🇿', value: 'DZ', labelWithoutFlag: 'Algeria', label: '🇩🇿 Algeria', alpha3: 'DZA' },
  { flag: '🇪🇨', value: 'EC', labelWithoutFlag: 'Ecuador', label: '🇪🇨 Ecuador', alpha3: 'ECU' },
  { flag: '🇪🇬', value: 'EG', labelWithoutFlag: 'Egypt', label: '🇪🇬 Egypt', alpha3: 'EGY' },
  { flag: '🇪🇷', value: 'ER', labelWithoutFlag: 'Eritrea', label: '🇪🇷 Eritrea', alpha3: 'ERI' },
  { flag: '🇪🇭', value: 'EH', labelWithoutFlag: 'Western Sahara', label: '🇪🇭 Western Sahara', alpha3: 'ESH' },
  { flag: '🇪🇸', value: 'ES', labelWithoutFlag: 'Spain', label: '🇪🇸 Spain', alpha3: 'ESP' },
  { flag: '🇪🇪', value: 'EE', labelWithoutFlag: 'Estonia', label: '🇪🇪 Estonia', alpha3: 'EST' },
  { flag: '🇪🇹', value: 'ET', labelWithoutFlag: 'Ethiopia', label: '🇪🇹 Ethiopia', alpha3: 'ETH' },
  { flag: '🇫🇮', value: 'FI', labelWithoutFlag: 'Finland', label: '🇫🇮 Finland', alpha3: 'FIN' },
  { flag: '🇫🇯', value: 'FJ', labelWithoutFlag: 'Fiji', label: '🇫🇯 Fiji', alpha3: 'FJI' },
  { flag: '🇫🇰', value: 'FK', labelWithoutFlag: 'Falkland Islands', label: '🇫🇰 Falkland Islands', alpha3: 'FLK' },
  { flag: '🇫🇷', value: 'FR', labelWithoutFlag: 'France', label: '🇫🇷 France', alpha3: 'FRA' },
  { flag: '🇫🇴', value: 'FO', labelWithoutFlag: 'Faroe Islands', label: '🇫🇴 Faroe Islands', alpha3: 'FRO' },
  { flag: '🇫🇲', value: 'FM', labelWithoutFlag: 'Micronesia', label: '🇫🇲 Micronesia', alpha3: 'FSM' },
  { flag: '🇬🇦', value: 'GA', labelWithoutFlag: 'Gabon', label: '🇬🇦 Gabon', alpha3: 'GAB' },
  { flag: '🇬🇧', value: 'GB', labelWithoutFlag: 'United Kingdom', label: '🇬🇧 United Kingdom', alpha3: 'GBR' },
  { flag: '🇬🇪', value: 'GE', labelWithoutFlag: 'Georgia', label: '🇬🇪 Georgia', alpha3: 'GEO' },
  { flag: '🇬🇬', value: 'GG', labelWithoutFlag: 'Guernsey', label: '🇬🇬 Guernsey', alpha3: 'GGY' },
  { flag: '🇬🇭', value: 'GH', labelWithoutFlag: 'Ghana', label: '🇬🇭 Ghana', alpha3: 'GHA' },
  { flag: '🇬🇮', value: 'GI', labelWithoutFlag: 'Gibraltar', label: '🇬🇮 Gibraltar', alpha3: 'GIB' },
  { flag: '🇬🇳', value: 'GN', labelWithoutFlag: 'Guinea', label: '🇬🇳 Guinea', alpha3: 'GIN' },
  { flag: '🇬🇵', value: 'GP', labelWithoutFlag: 'Guadeloupe', label: '🇬🇵 Guadeloupe', alpha3: 'GLP' },
  { flag: '🇬🇲', value: 'GM', labelWithoutFlag: 'Gambia', label: '🇬🇲 Gambia', alpha3: 'GMB' },
  { flag: '🇬🇼', value: 'GW', labelWithoutFlag: 'Guinea-Bissau', label: '🇬🇼 Guinea-Bissau', alpha3: 'GNB' },
  { flag: '🇬🇶', value: 'GQ', labelWithoutFlag: 'Equatorial Guinea', label: '🇬🇶 Equatorial Guinea', alpha3: 'GNQ' },
  { flag: '🇬🇷', value: 'GR', labelWithoutFlag: 'Greece', label: '🇬🇷 Greece', alpha3: 'GRC' },
  { flag: '🇬🇩', value: 'GD', labelWithoutFlag: 'Grenada', label: '🇬🇩 Grenada', alpha3: 'GRD' },
  { flag: '🇬🇱', value: 'GL', labelWithoutFlag: 'Greenland', label: '🇬🇱 Greenland', alpha3: 'GRL' },
  { flag: '🇬🇹', value: 'GT', labelWithoutFlag: 'Guatemala', label: '🇬🇹 Guatemala', alpha3: 'GTM' },
  { flag: '🇬🇫', value: 'GF', labelWithoutFlag: 'French Guiana', label: '🇬🇫 French Guiana', alpha3: 'GUF' },
  { flag: '🇬🇺', value: 'GU', labelWithoutFlag: 'Guam', label: '🇬🇺 Guam', alpha3: 'GUM' },
  { flag: '🇬🇾', value: 'GY', labelWithoutFlag: 'Guyana', label: '🇬🇾 Guyana', alpha3: 'GUY' },
  { flag: '🇭🇰', value: 'HK', labelWithoutFlag: 'Hong Kong', label: '🇭🇰 Hong Kong', alpha3: 'HKG' },
  {
    flag: '🇭🇲',
    value: 'HM',
    labelWithoutFlag: 'Heard Island and McDonald Islands',
    label: '🇭🇲 Heard Island and McDonald Islands',
    alpha3: 'HMD',
  },
  { flag: '🇭🇳', value: 'HN', labelWithoutFlag: 'Honduras', label: '🇭🇳 Honduras', alpha3: 'HND' },
  { flag: '🇭🇷', value: 'HR', labelWithoutFlag: 'Croatia', label: '🇭🇷 Croatia', alpha3: 'HRV' },
  { flag: '🇭🇹', value: 'HT', labelWithoutFlag: 'Haiti', label: '🇭🇹 Haiti', alpha3: 'HTI' },
  { flag: '🇭🇺', value: 'HU', labelWithoutFlag: 'Hungary', label: '🇭🇺 Hungary', alpha3: 'HUN' },
  { flag: '🇮🇩', value: 'ID', labelWithoutFlag: 'Indonesia', label: '🇮🇩 Indonesia', alpha3: 'IDN' },
  { flag: '🇮🇲', value: 'IM', labelWithoutFlag: 'Isle of Man', label: '🇮🇲 Isle of Man', alpha3: 'IMN' },
  { flag: '🇮🇳', value: 'IN', labelWithoutFlag: 'India', label: '🇮🇳 India', alpha3: 'IND' },
  {
    flag: '🇮🇴',
    value: 'IO',
    labelWithoutFlag: 'British Indian Ocean Territory',
    label: '🇮🇴 British Indian Ocean Territory',
    alpha3: 'IOT',
  },
  { flag: '🇮🇪', value: 'IE', labelWithoutFlag: 'Ireland', label: '🇮🇪 Ireland', alpha3: 'IRL' },
  { flag: '🇮🇷', value: 'IR', labelWithoutFlag: 'Iran', label: '🇮🇷 Iran', alpha3: 'IRN' },
  { flag: '🇮🇶', value: 'IQ', labelWithoutFlag: 'Iraq', label: '🇮🇶 Iraq', alpha3: 'IRQ' },
  { flag: '🇮🇸', value: 'IS', labelWithoutFlag: 'Iceland', label: '🇮🇸 Iceland', alpha3: 'ISL' },
  { flag: '🇮🇱', value: 'IL', labelWithoutFlag: 'Israel', label: '🇮🇱 Israel', alpha3: 'ISR' },
  { flag: '🇮🇹', value: 'IT', labelWithoutFlag: 'Italy', label: '🇮🇹 Italy', alpha3: 'ITA' },
  { flag: '🇯🇲', value: 'JM', labelWithoutFlag: 'Jamaica', label: '🇯🇲 Jamaica', alpha3: 'JAM' },
  { flag: '🇯🇪', value: 'JE', labelWithoutFlag: 'Jersey', label: '🇯🇪 Jersey', alpha3: 'JEY' },
  { flag: '🇯🇴', value: 'JO', labelWithoutFlag: 'Jordan', label: '🇯🇴 Jordan', alpha3: 'JOR' },
  { flag: '🇯🇵', value: 'JP', labelWithoutFlag: 'Japan', label: '🇯🇵 Japan', alpha3: 'JPN' },
  { flag: '🇰🇿', value: 'KZ', labelWithoutFlag: 'Kazakhstan', label: '🇰🇿 Kazakhstan', alpha3: 'KAZ' },
  { flag: '🇰🇪', value: 'KE', labelWithoutFlag: 'Kenya', label: '🇰🇪 Kenya', alpha3: 'KEN' },
  { flag: '🇰🇬', value: 'KG', labelWithoutFlag: 'Kyrgyzstan', label: '🇰🇬 Kyrgyzstan', alpha3: 'KGZ' },
  { flag: '🇰🇭', value: 'KH', labelWithoutFlag: 'Cambodia', label: '🇰🇭 Cambodia', alpha3: 'KHM' },
  { flag: '🇰🇮', value: 'KI', labelWithoutFlag: 'Kiribati', label: '🇰🇮 Kiribati', alpha3: 'KIR' },
  {
    flag: '🇰🇳',
    value: 'KN',
    labelWithoutFlag: 'Saint Kitts and Nevis',
    label: '🇰🇳 Saint Kitts and Nevis',
    alpha3: 'KNA',
  },
  { flag: '🇰🇷', value: 'KR', labelWithoutFlag: 'South Korea', label: '🇰🇷 South Korea', alpha3: 'KOR' },
  { flag: '🇽🇰', value: 'XK', labelWithoutFlag: 'Kosovo', label: '🇽🇰 Kosovo', alpha3: 'XKX' },
  { flag: '🇰🇼', value: 'KW', labelWithoutFlag: 'Kuwait', label: '🇰🇼 Kuwait', alpha3: 'KWT' },
  { flag: '🇱🇦', value: 'LA', labelWithoutFlag: 'Laos', label: '🇱🇦 Laos', alpha3: 'LAO' },
  { flag: '🇱🇧', value: 'LB', labelWithoutFlag: 'Lebanon', label: '🇱🇧 Lebanon', alpha3: 'LBN' },
  { flag: '🇱🇷', value: 'LR', labelWithoutFlag: 'Liberia', label: '🇱🇷 Liberia', alpha3: 'LBR' },
  { flag: '🇱🇾', value: 'LY', labelWithoutFlag: 'Libya', label: '🇱🇾 Libya', alpha3: 'LBY' },
  { flag: '🇱🇨', value: 'LC', labelWithoutFlag: 'Saint Lucia', label: '🇱🇨 Saint Lucia', alpha3: 'LCA' },
  { flag: '🇱🇮', value: 'LI', labelWithoutFlag: 'Liechtenstein', label: '🇱🇮 Liechtenstein', alpha3: 'LIE' },
  { flag: '🇱🇰', value: 'LK', labelWithoutFlag: 'Sri Lanka', label: '🇱🇰 Sri Lanka', alpha3: 'LKA' },
  { flag: '🇱🇸', value: 'LS', labelWithoutFlag: 'Lesotho', label: '🇱🇸 Lesotho', alpha3: 'LSO' },
  { flag: '🇱🇹', value: 'LT', labelWithoutFlag: 'Lithuania', label: '🇱🇹 Lithuania', alpha3: 'LTU' },
  { flag: '🇱🇺', value: 'LU', labelWithoutFlag: 'Luxembourg', label: '🇱🇺 Luxembourg', alpha3: 'LUX' },
  { flag: '🇱🇻', value: 'LV', labelWithoutFlag: 'Latvia', label: '🇱🇻 Latvia', alpha3: 'LVA' },
  { flag: '🇲🇴', value: 'MO', labelWithoutFlag: 'Macau', label: '🇲🇴 Macau', alpha3: 'MAC' },
  { flag: '🇲🇫', value: 'MF', labelWithoutFlag: 'Saint Martin', label: '🇲🇫 Saint Martin', alpha3: 'MAF' },
  { flag: '🇲🇦', value: 'MA', labelWithoutFlag: 'Morocco', label: '🇲🇦 Morocco', alpha3: 'MAR' },
  { flag: '🇲🇨', value: 'MC', labelWithoutFlag: 'Monaco', label: '🇲🇨 Monaco', alpha3: 'MCO' },
  { flag: '🇲🇩', value: 'MD', labelWithoutFlag: 'Moldova', label: '🇲🇩 Moldova', alpha3: 'MDA' },
  { flag: '🇲🇬', value: 'MG', labelWithoutFlag: 'Madagascar', label: '🇲🇬 Madagascar', alpha3: 'MDG' },
  { flag: '🇲🇻', value: 'MV', labelWithoutFlag: 'Maldives', label: '🇲🇻 Maldives', alpha3: 'MDV' },
  { flag: '🇲🇽', value: 'MX', labelWithoutFlag: 'Mexico', label: '🇲🇽 Mexico', alpha3: 'MEX' },
  { flag: '🇲🇭', value: 'MH', labelWithoutFlag: 'Marshall Islands', label: '🇲🇭 Marshall Islands', alpha3: 'MHL' },
  { flag: '🇲🇰', value: 'MK', labelWithoutFlag: 'North Macedonia', label: '🇲🇰 North Macedonia', alpha3: 'MKD' },
  { flag: '🇲🇱', value: 'ML', labelWithoutFlag: 'Mali', label: '🇲🇱 Mali', alpha3: 'MLI' },
  { flag: '🇲🇹', value: 'MT', labelWithoutFlag: 'Malta', label: '🇲🇹 Malta', alpha3: 'MLT' },
  { flag: '🇲🇲', value: 'MM', labelWithoutFlag: 'Myanmar', label: '🇲🇲 Myanmar', alpha3: 'MMR' },
  { flag: '🇲🇪', value: 'ME', labelWithoutFlag: 'Montenegro', label: '🇲🇪 Montenegro', alpha3: 'MNE' },
  { flag: '🇲🇳', value: 'MN', labelWithoutFlag: 'Mongolia', label: '🇲🇳 Mongolia', alpha3: 'MNG' },
  {
    flag: '🇲🇵',
    value: 'MP',
    labelWithoutFlag: 'Northern Mariana Islands',
    label: '🇲🇵 Northern Mariana Islands',
    alpha3: 'MNP',
  },
  { flag: '🇲🇿', value: 'MZ', labelWithoutFlag: 'Mozambique', label: '🇲🇿 Mozambique', alpha3: 'MOZ' },
  { flag: '🇲🇷', value: 'MR', labelWithoutFlag: 'Mauritania', label: '🇲🇷 Mauritania', alpha3: 'MRT' },
  { flag: '🇲🇸', value: 'MS', labelWithoutFlag: 'Montserrat', label: '🇲🇸 Montserrat', alpha3: 'MSR' },
  { flag: '🇲🇶', value: 'MQ', labelWithoutFlag: 'Martinique', label: '🇲🇶 Martinique', alpha3: 'MTQ' },
  { flag: '🇲🇺', value: 'MU', labelWithoutFlag: 'Mauritius', label: '🇲🇺 Mauritius', alpha3: 'MUS' },
  { flag: '🇲🇼', value: 'MW', labelWithoutFlag: 'Malawi', label: '🇲🇼 Malawi', alpha3: 'MWI' },
  { flag: '🇲🇾', value: 'MY', labelWithoutFlag: 'Malaysia', label: '🇲🇾 Malaysia', alpha3: 'MYS' },
  { flag: '🇾🇹', value: 'YT', labelWithoutFlag: 'Mayotte', label: '🇾🇹 Mayotte', alpha3: 'MYT' },
  { flag: '🇳🇦', value: 'NA', labelWithoutFlag: 'Namibia', label: '🇳🇦 Namibia', alpha3: 'NAM' },
  { flag: '🇳🇨', value: 'NC', labelWithoutFlag: 'New Caledonia', label: '🇳🇨 New Caledonia', alpha3: 'NCL' },
  { flag: '🇳🇪', value: 'NE', labelWithoutFlag: 'Niger', label: '🇳🇪 Niger', alpha3: 'NER' },
  { flag: '🇳🇫', value: 'NF', labelWithoutFlag: 'Norfolk Island', label: '🇳🇫 Norfolk Island', alpha3: 'NFK' },
  { flag: '🇳🇬', value: 'NG', labelWithoutFlag: 'Nigeria', label: '🇳🇬 Nigeria', alpha3: 'NGA' },
  { flag: '🇳🇮', value: 'NI', labelWithoutFlag: 'Nicaragua', label: '🇳🇮 Nicaragua', alpha3: 'NIC' },
  { flag: '🇳🇺', value: 'NU', labelWithoutFlag: 'Niue', label: '🇳🇺 Niue', alpha3: 'NIU' },
  { flag: '🇳🇱', value: 'NL', labelWithoutFlag: 'Netherlands', label: '🇳🇱 Netherlands', alpha3: 'NLD' },
  { flag: '🇳🇴', value: 'NO', labelWithoutFlag: 'Norway', label: '🇳🇴 Norway', alpha3: 'NOR' },
  { flag: '🇳🇵', value: 'NP', labelWithoutFlag: 'Nepal', label: '🇳🇵 Nepal', alpha3: 'NPL' },
  { flag: '🇳🇷', value: 'NR', labelWithoutFlag: 'Nauru', label: '🇳🇷 Nauru', alpha3: 'NRU' },
  { flag: '🇳🇿', value: 'NZ', labelWithoutFlag: 'New Zealand', label: '🇳🇿 New Zealand', alpha3: 'NZL' },
  { flag: '🇴🇲', value: 'OM', labelWithoutFlag: 'Oman', label: '🇴🇲 Oman', alpha3: 'OMN' },
  { flag: '🇵🇰', value: 'PK', labelWithoutFlag: 'Pakistan', label: '🇵🇰 Pakistan', alpha3: 'PAK' },
  { flag: '🇵🇦', value: 'PA', labelWithoutFlag: 'Panama', label: '🇵🇦 Panama', alpha3: 'PAN' },
  { flag: '🇵🇳', value: 'PN', labelWithoutFlag: 'Pitcairn Islands', label: '🇵🇳 Pitcairn Islands', alpha3: 'PCN' },
  { flag: '🇵🇪', value: 'PE', labelWithoutFlag: 'Peru', label: '🇵🇪 Peru', alpha3: 'PER' },
  { flag: '🇵🇭', value: 'PH', labelWithoutFlag: 'Philippines', label: '🇵🇭 Philippines', alpha3: 'PHL' },
  { flag: '🇵🇼', value: 'PW', labelWithoutFlag: 'Palau', label: '🇵🇼 Palau', alpha3: 'PLW' },
  { flag: '🇵🇬', value: 'PG', labelWithoutFlag: 'Papua New Guinea', label: '🇵🇬 Papua New Guinea', alpha3: 'PNG' },
  { flag: '🇵🇱', value: 'PL', labelWithoutFlag: 'Poland', label: '🇵🇱 Poland', alpha3: 'POL' },
  { flag: '🇵🇷', value: 'PR', labelWithoutFlag: 'Puerto Rico', label: '🇵🇷 Puerto Rico', alpha3: 'PRI' },
  { flag: '🇰🇵', value: 'KP', labelWithoutFlag: 'North Korea', label: '🇰🇵 North Korea', alpha3: 'PRK' },
  { flag: '🇵🇹', value: 'PT', labelWithoutFlag: 'Portugal', label: '🇵🇹 Portugal', alpha3: 'PRT' },
  { flag: '🇵🇾', value: 'PY', labelWithoutFlag: 'Paraguay', label: '🇵🇾 Paraguay', alpha3: 'PRY' },
  { flag: '🇵🇸', value: 'PS', labelWithoutFlag: 'Palestine', label: '🇵🇸 Palestine', alpha3: 'PSE' },
  { flag: '🇵🇫', value: 'PF', labelWithoutFlag: 'French Polynesia', label: '🇵🇫 French Polynesia', alpha3: 'PYF' },
  { flag: '🇶🇦', value: 'QA', labelWithoutFlag: 'Qatar', label: '🇶🇦 Qatar', alpha3: 'QAT' },
  { flag: '🇷🇪', value: 'RE', labelWithoutFlag: 'Réunion', label: '🇷🇪 Réunion', alpha3: 'REU' },
  { flag: '🇷🇴', value: 'RO', labelWithoutFlag: 'Romania', label: '🇷🇴 Romania', alpha3: 'ROU' },
  { flag: '🇷🇺', value: 'RU', labelWithoutFlag: 'Russia', label: '🇷🇺 Russia', alpha3: 'RUS' },
  { flag: '🇷🇼', value: 'RW', labelWithoutFlag: 'Rwanda', label: '🇷🇼 Rwanda', alpha3: 'RWA' },
  { flag: '🇸🇦', value: 'SA', labelWithoutFlag: 'Saudi Arabia', label: '🇸🇦 Saudi Arabia', alpha3: 'SAU' },
  { flag: '🇸🇩', value: 'SD', labelWithoutFlag: 'Sudan', label: '🇸🇩 Sudan', alpha3: 'SDN' },
  { flag: '🇸🇳', value: 'SN', labelWithoutFlag: 'Senegal', label: '🇸🇳 Senegal', alpha3: 'SEN' },
  { flag: '🇸🇬', value: 'SG', labelWithoutFlag: 'Singapore', label: '🇸🇬 Singapore', alpha3: 'SGP' },
  { flag: '🇬🇸', value: 'GS', labelWithoutFlag: 'South Georgia', label: '🇬🇸 South Georgia', alpha3: 'SGS' },
  {
    flag: '🇸🇯',
    value: 'SJ',
    labelWithoutFlag: 'Svalbard and Jan Mayen',
    label: '🇸🇯 Svalbard and Jan Mayen',
    alpha3: 'SJM',
  },
  { flag: '🇸🇧', value: 'SB', labelWithoutFlag: 'Solomon Islands', label: '🇸🇧 Solomon Islands', alpha3: 'SLB' },
  { flag: '🇸🇱', value: 'SL', labelWithoutFlag: 'Sierra Leone', label: '🇸🇱 Sierra Leone', alpha3: 'SLE' },
  { flag: '🇸🇻', value: 'SV', labelWithoutFlag: 'El Salvador', label: '🇸🇻 El Salvador', alpha3: 'SLV' },
  { flag: '🇸🇲', value: 'SM', labelWithoutFlag: 'San Marino', label: '🇸🇲 San Marino', alpha3: 'SMR' },
  { flag: '🇸🇴', value: 'SO', labelWithoutFlag: 'Somalia', label: '🇸🇴 Somalia', alpha3: 'SOM' },
  {
    flag: '🇵🇲',
    value: 'PM',
    labelWithoutFlag: 'Saint Pierre and Miquelon',
    label: '🇵🇲 Saint Pierre and Miquelon',
    alpha3: 'SPM',
  },
  { flag: '🇷🇸', value: 'RS', labelWithoutFlag: 'Serbia', label: '🇷🇸 Serbia', alpha3: 'SRB' },
  { flag: '🇸🇸', value: 'SS', labelWithoutFlag: 'South Sudan', label: '🇸🇸 South Sudan', alpha3: 'SSD' },
  {
    flag: '🇸🇹',
    value: 'ST',
    labelWithoutFlag: 'São Tomé and Príncipe',
    label: '🇸🇹 São Tomé and Príncipe',
    alpha3: 'STP',
  },
  { flag: '🇸🇷', value: 'SR', labelWithoutFlag: 'Suriname', label: '🇸🇷 Suriname', alpha3: 'SUR' },
  { flag: '🇸🇰', value: 'SK', labelWithoutFlag: 'Slovakia', label: '🇸🇰 Slovakia', alpha3: 'SVK' },
  { flag: '🇸🇮', value: 'SI', labelWithoutFlag: 'Slovenia', label: '🇸🇮 Slovenia', alpha3: 'SVN' },
  { flag: '🇸🇪', value: 'SE', labelWithoutFlag: 'Sweden', label: '🇸🇪 Sweden', alpha3: 'SWE' },
  { flag: '🇸🇿', value: 'SZ', labelWithoutFlag: 'Eswatini', label: '🇸🇿 Eswatini', alpha3: 'SWZ' },
  { flag: '🇸🇽', value: 'SX', labelWithoutFlag: 'Sint Maarten', label: '🇸🇽 Sint Maarten', alpha3: 'SXM' },
  { flag: '🇸🇨', value: 'SC', labelWithoutFlag: 'Seychelles', label: '🇸🇨 Seychelles', alpha3: 'SYC' },
  { flag: '🇸🇾', value: 'SY', labelWithoutFlag: 'Syria', label: '🇸🇾 Syria', alpha3: 'SYR' },
  {
    flag: '🇹🇨',
    value: 'TC',
    labelWithoutFlag: 'Turks and Caicos Islands',
    label: '🇹🇨 Turks and Caicos Islands',
    alpha3: 'TCA',
  },
  { flag: '🇹🇩', value: 'TD', labelWithoutFlag: 'Chad', label: '🇹🇩 Chad', alpha3: 'TCD' },
  { flag: '🇹🇬', value: 'TG', labelWithoutFlag: 'Togo', label: '🇹🇬 Togo', alpha3: 'TGO' },
  { flag: '🇹🇭', value: 'TH', labelWithoutFlag: 'Thailand', label: '🇹🇭 Thailand', alpha3: 'THA' },
  { flag: '🇹🇯', value: 'TJ', labelWithoutFlag: 'Tajikistan', label: '🇹🇯 Tajikistan', alpha3: 'TJK' },
  { flag: '🇹🇰', value: 'TK', labelWithoutFlag: 'Tokelau', label: '🇹🇰 Tokelau', alpha3: 'TKL' },
  { flag: '🇹🇲', value: 'TM', labelWithoutFlag: 'Turkmenistan', label: '🇹🇲 Turkmenistan', alpha3: 'TKM' },
  { flag: '🇹🇱', value: 'TL', labelWithoutFlag: 'Timor-Leste', label: '🇹🇱 Timor-Leste', alpha3: 'TLS' },
  { flag: '🇹🇴', value: 'TO', labelWithoutFlag: 'Tonga', label: '🇹🇴 Tonga', alpha3: 'TON' },
  { flag: '🇹🇹', value: 'TT', labelWithoutFlag: 'Trinidad and Tobago', label: '🇹🇹 Trinidad and Tobago', alpha3: 'TTO' },
  { flag: '🇹🇳', value: 'TN', labelWithoutFlag: 'Tunisia', label: '🇹🇳 Tunisia', alpha3: 'TUN' },
  { flag: '🇹🇷', value: 'TR', labelWithoutFlag: 'Turkey', label: '🇹🇷 Turkey', alpha3: 'TUR' },
  { flag: '🇹🇻', value: 'TV', labelWithoutFlag: 'Tuvalu', label: '🇹🇻 Tuvalu', alpha3: 'TUV' },
  { flag: '🇹🇼', value: 'TW', labelWithoutFlag: 'Taiwan', label: '🇹🇼 Taiwan', alpha3: 'TWN' },
  { flag: '🇹🇿', value: 'TZ', labelWithoutFlag: 'Tanzania', label: '🇹🇿 Tanzania', alpha3: 'TZA' },
  { flag: '🇺🇬', value: 'UG', labelWithoutFlag: 'Uganda', label: '🇺🇬 Uganda', alpha3: 'UGA' },
  { flag: '🇺🇦', value: 'UA', labelWithoutFlag: 'Ukraine', label: '🇺🇦 Ukraine', alpha3: 'UKR' },
  {
    flag: '🇺🇲',
    value: 'UM',
    labelWithoutFlag: 'United States Minor Outlying Islands',
    label: '🇺🇲 United States Minor Outlying Islands',
    alpha3: 'UMI',
  },
  { flag: '🇺🇾', value: 'UY', labelWithoutFlag: 'Uruguay', label: '🇺🇾 Uruguay', alpha3: 'URY' },
  { flag: '🇺🇸', value: 'US', labelWithoutFlag: 'United States', label: '🇺🇸 United States', alpha3: 'USA' },
  { flag: '🇺🇿', value: 'UZ', labelWithoutFlag: 'Uzbekistan', label: '🇺🇿 Uzbekistan', alpha3: 'UZB' },
  { flag: '🇻🇦', value: 'VA', labelWithoutFlag: 'Vatican City', label: '🇻🇦 Vatican City', alpha3: 'VAT' },
  {
    flag: '🇻🇨',
    value: 'VC',
    labelWithoutFlag: 'Saint Vincent and the Grenadines',
    label: '🇻🇨 Saint Vincent and the Grenadines',
    alpha3: 'VCT',
  },
  { flag: '🇻🇪', value: 'VE', labelWithoutFlag: 'Venezuela', label: '🇻🇪 Venezuela', alpha3: 'VEN' },
  {
    flag: '🇻🇬',
    value: 'VG',
    labelWithoutFlag: 'British Virgin Islands',
    label: '🇻🇬 British Virgin Islands',
    alpha3: 'VGB',
  },
  {
    flag: '🇻🇮',
    value: 'VI',
    labelWithoutFlag: 'United States Virgin Islands',
    label: '🇻🇮 United States Virgin Islands',
    alpha3: 'VIR',
  },
  { flag: '🇻🇳', value: 'VN', labelWithoutFlag: 'Vietnam', label: '🇻🇳 Vietnam', alpha3: 'VNM' },
  { flag: '🇻🇺', value: 'VU', labelWithoutFlag: 'Vanuatu', label: '🇻🇺 Vanuatu', alpha3: 'VUT' },
  { flag: '🇼🇫', value: 'WF', labelWithoutFlag: 'Wallis and Futuna', label: '🇼🇫 Wallis and Futuna', alpha3: 'WLF' },
  { flag: '🇼🇸', value: 'WS', labelWithoutFlag: 'Samoa', label: '🇼🇸 Samoa', alpha3: 'WSM' },
  { flag: '🇾🇪', value: 'YE', labelWithoutFlag: 'Yemen', label: '🇾🇪 Yemen', alpha3: 'YEM' },
  { flag: '🇿🇦', value: 'ZA', labelWithoutFlag: 'South Africa', label: '🇿🇦 South Africa', alpha3: 'ZAF' },
  { flag: '🇿🇲', value: 'ZM', labelWithoutFlag: 'Zambia', label: '🇿🇲 Zambia', alpha3: 'ZMB' },
  { flag: '🇿🇼', value: 'ZW', labelWithoutFlag: 'Zimbabwe', label: '🇿🇼 Zimbabwe', alpha3: 'ZWE' },
];

export const countryAlphaToNumeric: { [alpha3: string]: string } = {
  AFG: '135', // Afeganistão
  ZAF: '46', // África do Sul
  ALB: '20', // Albânia
  DEU: '3', // Alemanha
  AND: '39', // Andorra
  AGO: '48', // Angola
  AIA: '202', // Anguila
  MKD: '21', // Antiga República Jugoslávia da Macedónia
  ANT: '209', // Antilhas Holandesas
  ATG: '100', // Antígua e Barbuda
  ARG: '101', // Argentina
  DZA: '47', // Argélia
  ARM: '137', // Arménia
  ABW: '208', // Aruba
  SAU: '136', // Arábia Saudita
  AUT: '11', // Áustria
  AUS: '182', // Austrália
  AZE: '138', // Azerbaijão
  BGD: '140', // Bangladesh
  BRB: '103', // Barbados
  BHR: '139', // Barém
  BLZ: '104', // Belize
  BEN: '49', // Benim
  BMU: '201', // Bermudas
  BLR: '22', // Bielorrússia
  BOL: '105', // Bolívia
  BIH: '23', // Bósnia-Herzegovina
  BWA: '50', // Botswana
  BRA: '106', // Brasil
  BRN: '142', // Brunei
  BGR: '24', // Bulgária
  BFA: '51', // Burkina Faso
  BDJ: '52', // Burundi
  BTN: '141', // Butão
  BEL: '1', // Bélgica
  CPV: '54', // Cabo Verde
  CMR: '53', // Camarões
  KHM: '143', // Camboja (Kampuchea)
  CAN: '107', // Canadá
  QAT: '171', // Catar
  KAZ: '158', // Cazaquistão
  TCD: '95', // Chade
  CHL: '108', // Chile
  CHN: '144', // China
  CYP: '40', // Chipre
  VAT: '44', // Cidade do Vaticano
  PSE: '145', // Cisjordânia / Banda de Gaza
  COL: '109', // Colômbia
  COM: '55', // Comores
  COG: '56', // Congo
  PRK: '146', // Coreia do Norte
  KOR: '147', // Coreia do Sul
  CRI: '110', // Costa Rica
  CIV: '58', // Costa do Marfim
  HRV: '25', // Croácia
  CUB: '111', // Cuba
  UNKNOWN: '0', // Desconhecido
  DNK: '2', // Dinamarca
  DMA: '112', // Dominica
  EGY: '60', // Egipto
  ARE: '148', // Emiratos Árabes Unidos
  ECU: '113', // Equador
  ERI: '61', // Eritreia
  SVN: '36', // Eslovénia
  ESP: '5', // Espanha
  USA: '115', // Estados Unidos da América
  EST: '26', // Estónia
  ETH: '62', // Etiópia
  FSM: '187', // Federação dos Estados da Micronésia
  FJI: '183', // Fidji
  PHL: '170', // Filipinas
  FIN: '13', // Finlândia
  FRA: '6', // França
  GAB: '63', // Gabão
  GHA: '65', // Gana
  GEO: '149', // Geórgia
  GIB: '224', // Gilbraltar
  GRD: '116', // Granada
  GRL: '199', // Gronelândia
  GRC: '4', // Grécia
  GTM: '117', // Guatemala
  GUY: '118', // Guiana
  GIN: '66', // Guiné
  GNQ: '68', // Guiné Equatorial
  GNB: '67', // Guiné-Bissau
  GMB: '64', // Gâmbia
  HTI: '119', // Haiti
  HND: '120', // Honduras
  HKG: '150', // Hong Kong
  HUN: '28', // Hungria
  BHS: '102', // Bahamas
  CYM: '205', // Ilhas Caimão
  FLK: '210', // Ilhas Falkland
  FRO: '223', // Ilhas Faroe
  MNP: '219', // Ilhas Marianas do Norte
  MHL: '184', // Ilhas Marshall
  SLB: '185', // Ilhas Salomão
  TCA: '203', // Ilhas Turcas e Caicos
  VGB: '206', // Ilhas Virgens Britânicas
  VUS: '204', // Ilhas Virgens dos Estados Unidos
  WLF: '216', // Ilhas Wallis e Futuna
  IND: '151', // Índia
  IDN: '152', // Indonésia
  IRQ: '154', // Iraque
  IRL: '7', // Irlanda
  IRN: '153', // Irão
  ISL: '16', // Islândia
  ISR: '155', // Israel
  ITA: '8', // Itália
  YEM: '181', // Iémen
  JAM: '121', // Jamaica
  JPN: '156', // Japão
  DJI: '59', // Jibouti
  JOR: '157', // Jordânia
  KIR: '186', // Kiribati
  KWT: '159', // Kuwait
  LAO: '175', // Laos
  LSO: '70', // Lesoto
  LVA: '29', // Letónia
  LBR: '71', // Libéria
  LIE: '17', // Liechtenstein
  LTU: '30', // Lituânia
  LUX: '9', // Luxemburgo
  LBN: '161', // Líbano
  LBY: '72', // Líbia
  MAC: '212', // Macau
  MDG: '73', // Madagascar
  MWN: '74', // Malawi
  MDV: '163', // Maldivas
  MLI: '75', // Mali
  MLT: '41', // Malta
  MYS: '162', // Malásia
  MAR: '76', // Marrocos
  MRT: '78', // Mauritânia
  MUS: '77', // Maurícia
  MYT: '198', // Mayotte
  MYA: '222', // Melilha e Ceuta
  MDA: '31', // Moldávia
  MNG: '164', // Mongólia
  MSR: '207', // Monserrate
  MOZ: '79', // Moçambique
  MMR: '165', // Myanmar
  MEX: '122', // México
  MCO: '42', // Mónaco
  NAM: '80', // Namíbia
  NRU: '188', // Nauru
  NPL: '166', // Nepal
  NIC: '123', // Nicarágua
  NGA: '82', // Nigéria
  NOR: '18', // Noruega
  NCL: '214', // Nova Caledónia e Depend.
  NZL: '189', // Nova Zelândia
  NER: '81', // Níger
  UMI: '215', // Oceânia Americana
  OCE: '213', // Oceânia Australiana
  NZZ: '218', // Oceânia Neo-Zelandesa
  OMN: '167', // Omã
  PLW: '190', // Palau
  PAN: '125', // Panamá
  PNG: '191', // Papuásia-Nova Guiné
  PAK: '169', // Paquistão
  PRY: '126', // Paraguai
  NLD: '10', // Países Baixos
  PER: '127', // Peru
  PCN: '217', // Pitcairn
  PYF: '220', // Polinésia Francesa
  POL: '32', // Polônia
  PRT: '12', // Portugal
  KGZ: '160', // Quirziquistão
  KEN: '69', // Quénia
  ATA: '221', // Regiões Polares
  GBR: '15', // Reino Unido
  YUG: '38', // República Federativa Jugoslávia
  CAF: '84', // República Centro-Africana
  CZE: '34', // República Checa
  DOM: '124', // República Dominicana
  SVK: '33', // República Eslovaca
  ROU: '35', // Roménia
  RWA: '85', // Ruanda
  RUS: '27', // Rússia
  WSA: '86', // Saara Ocidental
  SLV: '114', // El Salvador
  WSM: '192', // Samoa Ocidental
  SHN: '196', // Santa Helena e Dependências
  LCA: '129', // Santa Lúcia
  SYC: '89', // Seichelles e Dependências
  SEN: '88', // Senegal
  SLE: '90', // Serra Leoa
  SGP: '172', // Singapura
  SOM: '91', // Somália
  LKA: '173', // Sri Lanka
  SWZ: '93', // Suazilândia
  SDN: '92', // Sudão
  SUR: '131', // Suriname
  SWE: '14', // Suécia
  CHE: '19', // Suíça
  KNA: '128', // São Cristóvão (Saint Kitts) e Nevis
  SMR: '43', // São Marinho (San Marino)
  SPM: '200', // São Pedro e Miquelon
  STP: '87', // São Tomé e Príncipe
  VCT: '130', // São Vicente
  SRB: '225', // Sérvia
  SYR: '174', // Síria
  THA: '177', // Tailândia
  TWN: '211', // Taiwan
  TJK: '176', // Tajiquistão
  TZA: '94', // Tanzânia
  IOT: '197', // Território Britânico do Oceano Índico
  TMP: '178', // Timor
  TGO: '96', // Togo
  TON: '193', // Tonga
  TTO: '132', // Trindade e Tobago
  TUN: '97', // Tunísia
  TKM: '179', // Turcomenistão
  TUR: '45', // Turquia
  TUV: '194', // Tuvalu
  UKR: '37', // Ucrânia
  UGA: '83', // Uganda
  URY: '133', // Uruguai
  UZB: '168', // Usbequistão
  VUT: '195', // Vanuatu
  VEN: '134', // Venezuela
  VNM: '180', // Vietnã
  ZAR: '57', // Zaire
  ZWE: '99', // Zimbabwe
  ZMB: '98', // Zâmbia
};

export const EUCountryCodes: string[] = [
  'AUT',
  'BEL',
  'BGR',
  'HRV',
  'CYP',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'DEU',
  'GRC',
  'HUN',
  'IRL',
  'ITA',
  'LVA',
  'LTU',
  'LUX',
  'MLT',
  'NLD',
  'POL',
  'PRT',
  'ROU',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
];

export const COUNTRIES_WITH_CODES_PREPARED = [
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  COUNTRIES_WITH_CODES.find(i => i.alpha3 === 'PRT')!,
  ...COUNTRIES_WITH_CODES.sort((a, b) =>
    a.labelWithoutFlag.localeCompare(b.labelWithoutFlag, 'en', { sensitivity: 'base' }),
  ).filter(i => i.alpha3 !== 'PRT'),
];

export const getByCountryCode = (code: string) => COUNTRIES_WITH_CODES_PREPARED.find(c => c.alpha3 === code)?.label;
